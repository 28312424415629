<template>
  <div v-if="isOpen" class="mobile-menu-overlay">
    <img class="logo" alt="logo" @click="goTo('home')" src="./../assets/knittable_logo_white.svg" />

    <button class="app-btn-icon close-button" @click="closeMenu">
      <v-icon icon="mdi-close" />
    </button>

    <div class="mobile-menu">
      <div class="menu-item">
        <div class="menu-item-title list-header" @click="toggleTools">
          {{ $t("navbar.Tools") }}
          <v-icon
            :icon="'mdi-chevron-up'"
            :class="['dropdown-icon', { 'rotate-icon': toolsOpen }]"
            size="small"
          ></v-icon>
        </div>
        <ul class="sub-menu" :class="{ 'sub-menu-open': toolsOpen }">
          <li class="sub-menu-item" v-for="(tool, i) in props.toolList" :key="i">
            <button @click="goTo(tool.routeName)">{{ tool.title }}</button>
          </li>
        </ul>
      </div>

      <div class="menu-item">
        <div class="menu-item-title list-header" @click="togglePatterns">
          {{ $t("navbar.Patterns") }}
          <v-icon
            :icon="'mdi-chevron-up'"
            :class="['dropdown-icon', { 'rotate-icon': patternsOpen }]"
            size="small"
          ></v-icon>
        </div>
        <ul class="sub-menu" :class="{ 'sub-menu-open': patternsOpen }">
          <li class="sub-menu-item" v-for="(pattern, i) in props.patternList" :key="i">
            <button @click="goTo(pattern.routeName)">{{ pattern.title }}</button>
          </li>
        </ul>
      </div>
      <div class="menu-item menu-item-link" @click="goTo('blog')">
        <span>{{ $t("common.Blog") }}</span>
      </div>
      <div v-if="authToken" class="menu-item menu-item-link" @click="goTo('projects')">
        <span>{{ $t("navbar.My_projects") }}</span>
      </div>
      <div class="menu-item" v-if="authToken && !authStore.isPartnerOrSubscribed">
        <main-button @click="goTo('subscription')" color="red" :compact="true">{{
          $t("navbar.Subscribe")
        }}</main-button>
      </div>
      <div v-if="authToken" class="menu-item sign-in-out" @click="signOut">
        {{ $t("navbar.Sign_out") }}
      </div>
      <div v-if="!authToken" class="menu-actions">
        <div class="menu-item sign-in-out" @click="goTo('signin')">
          <span>{{ $t("navbar.Sign_in") }}</span>
        </div>

        <main-button @click="goTo('signup')" color="yellow" :compact="true" class="menu-item">{{
          $t("navbar.Get_started")
        }}</main-button>
      </div>
    </div>
    <div class="line-1"></div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";

import { composeRoute } from "../lib/navigationUtils";
import { useLangStore } from "./../stores/lang";
import { useAuthStore } from "../stores/auth";
import MainButton from "./general/MainButton.vue";

const langStore = useLangStore();
const locale = langStore.getLocale();

const authStore = useAuthStore();
const { authToken } = storeToRefs(authStore);

const router = useRouter();

const props = defineProps({
  modelValue: {
    type: Boolean,
  },
  patternList: {
    type: Array,
  },
  toolList: {
    type: Array,
  },
});
const emit = defineEmits(["update:modelValue"]);

const isOpen = ref(props.modelValue);
const toolsOpen = ref(false);
const patternsOpen = ref(true);
watch(
  () => props.modelValue,
  () => {
    isOpen.value = props.modelValue;
  },
  { deep: true }
);

const closeMenu = () => {
  emit("update:modelValue", false);
};

const goTo = (routeName) => {
  closeMenu();
  router.push(composeRoute(locale.value, routeName));
};

async function signOut() {
  closeMenu();
  authStore.signOut();
  router.push(composeRoute(locale.value, "signin"));
}

const togglePatterns = () => {
  patternsOpen.value = !patternsOpen.value;
  toolsOpen.value = false;
};

const toggleTools = () => {
  toolsOpen.value = !toolsOpen.value;
  patternsOpen.value = false;
};
</script>

<style scoped lang="scss">
@import "./../../scss/styles.scss";

.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--knittable-lav-darker);
  display: flex;
  z-index: 10000;
  color: var(--knittable-isa);

  justify-content: center;
  align-items: center;
}

.close-button {
  cursor: pointer;
  background-color: var(--knittable-lav-darker);
  color: var(--knittable-isa);

  position: fixed;
  top: 1.75rem;
  right: 2.5rem;
}

.mobile-menu {
  height: 100%;
  padding-top: 6rem;
  padding-bottom: 8rem;
  list-style: none;
  display: grid;
  max-width: 80%;
}

.sub-menu {
  list-style: none;
}

.menu-item {
  height: min-content;
}

.menu-item-title {
  @include subtitleLarge;
  color: var(--knittable-lav-medium);
}

.menu-item-link {
  @include subtitleLarge;
  color: var(--knittable-isa);
}

.sign-in-out {
  @include buttonText;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}

.sub-menu-item button {
  @include paragraphLargeBold;
  color: var(--knittable-isa);
  margin-top: 1rem;
}

.logo {
  position: fixed;
  top: 2.25rem;
  left: 2rem;
  width: 170px;
}

.menu-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;
}

.rotate-icon {
  transform: rotate(180deg);
}

.dropdown-icon {
  color: var(--knittable-isa);
}

.sub-menu-open {
  display: block;
}

.list-header {
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 0;
}

.sub-menu {
  display: none;
  padding-left: 1rem;
  margin: 0.5rem 0;
}

.sub-menu-open {
  display: block;
}

.dropdown-icon {
  color: var(--knittable-isa);
}
</style>
