<template>
  <tooltip position="left">
    <template v-slot:content>
      <v-icon @click="emitGoToSubscription" icon="mdi-lock" />
    </template>
    <template v-slot:tooltip>
      <main-button color="purple" @click="emitGoToSubscription" class="subscribe-button">
        {{ $t("common7.Subscribe_to_enable") }}
      </main-button>
    </template>
  </tooltip>
</template>

<script setup>
import { defineEmits } from "vue";
import Tooltip from "./Tooltip.vue";
import MainButton from "./general/MainButton.vue";

const emit = defineEmits(["goToSubscription"]);

const emitGoToSubscription = () => {
  emit("goToSubscription");
};
</script>
