<template>
  <div class="projects-page">
    <h3 class="app-page-title">{{ $t("navbar.My_projects") }}</h3>

    <div v-if="loading" class="app-loading-overlay">
      <i class="mdi mdi-loading mdi-spin"></i>
    </div>

    <div class="pattern-sections">
      <div class="pattern-section">
        <h4 class="pattern-title">{{ $t("pattern_library.Recent_projects") }}</h4>
        <div class="recent-projects">
          <projects-table :projects="getRecentProjects()" @project-deleted="handleProjectDeleted" />
        </div>
      </div>

      <div v-for="patternType in patternTypes" :key="patternType.type" class="pattern-section">
        <h4 class="pattern-title">{{ $t(patternType.name) }}</h4>
        <projects-table
          :projects="getProjectsByType(patternType.type)"
          @project-deleted="handleProjectDeleted"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { getProjects } from "../services/project";
import ProjectsTable from "../components/pattern_utils/ProjectsTable.vue";

const { t } = useI18n();

const loading = ref(true);
const patternTypes = [
  { name: "common.Socks", type: "sock" },
  { name: "common.Mittens", type: "mitten" },
  { name: "common.Beanie", type: "beanie" },
  { name: "common.Sweater", type: "sweater" },
];
const patterns = ref([]);

const loadProjects = async () => {
  loading.value = true;
  try {
    const allProjects = await getProjects();

    patterns.value = Object.entries(
      allProjects.reduce((acc, project) => {
        const type = project.patternType;
        if (!acc[type]) {
          const patternType = patternTypes.find((p) => p.type === type) || { name: type, type };
          acc[type] = {
            type,
            title: t(patternType.name),
            projects: [],
          };
        }
        acc[type].projects.push(project);
        return acc;
      }, {})
    ).map(([_, value]) => value);
  } catch (error) {
    console.error("Error loading projects:", error);
  } finally {
    loading.value = false;
  }
};

const getProjectsByType = (type) => {
  return (
    patterns.value
      ?.find((pattern) => pattern.type === type)
      ?.projects?.sort((a, b) => new Date(b.lastActivityAt) - new Date(a.lastActivityAt)) ?? []
  );
};

const getRecentProjects = () => {
  return patterns.value
    .map((pattern) => pattern.projects)
    .flat()
    .sort((a, b) => new Date(b.lastActivityAt) - new Date(a.lastActivityAt))
    .slice(0, 4);
};

onMounted(() => {
  loadProjects();
});

const handleProjectDeleted = () => {
  loadProjects();
};
</script>

<style scoped lang="scss">
@import "./../../scss/styles.scss";

.projects-page {
  padding: 2rem;
}

.app-page-title {
  color: var(--knittable-lav-darker);
  margin-bottom: 2rem;
}

.pattern-sections {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.pattern-section {
  background: white;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.pattern-title {
  @include subtitleLarge;
  color: var(--knittable-lav-darker);
  margin-bottom: 1.5rem;
}

@media (max-width: 600px) {
  .projects-page {
    padding: 1rem;
  }

  .pattern-section {
    padding: 1rem;
  }
}
</style>
