<template>
  <v-app id="app">
    <navbar />
    <v-main
      :class="[
        'app-main-container',
        'pt-2 pt-md-0',
        { 'app-md-and-up': mdAndUp, 'app-sm-and-down': !mdAndUp },
      ]"
    >
      <router-view />
    </v-main>
    <app-footer :class="{ 'app-sm-and-down': !mdAndUp }" />
    <behavior-tracking-dialog></behavior-tracking-dialog>
  </v-app>
</template>

<script setup>
import { onMounted } from "vue";
import mp from "./userTracking/mixpanel";

import BehaviorTrackingDialog from "./components/BehaviorTrackingDialog.vue";
import { useAuthStore } from "./stores/auth";
import Navbar from "./components/Navbar.vue";
import AppFooter from "./components/AppFooter.vue";
import { useDisplay } from "vuetify";

const authStore = useAuthStore();

const { mdAndUp } = useDisplay();

const loadUserAndIdentify = async () => {
  if (authStore.authToken) {
    try {
      const user = await authStore.getUser();
      if (user) {
        mp.identify(user.id);
      }
    } catch (error) {
      console.log("Error identifying user for tracking");
    }
  }
};

onMounted(() => {
  loadUserAndIdentify();
});
</script>

<style lang="scss">
@import "../scss/styles.scss";

:root {
  --knittable-lav: #9872d7;
  --knittable-lav-medium: #b99ee6;
  --knittable-lav-light: #e0d5eb;
  --knittable-lav-lighter: #ede6f3;
  --knittable-lav-darker: #8160b7;
  --knittable-yama-light: #edd48f;
  --knittable-yama-medium: #ffd06c;
  --knittable-yama: #eeae04;
  --knittable-yama-darker: #d09e02;
  --knittable-mocha: #9e7f71;
  --knittable-macchiato: #755442;
  --knittable-isa: #f8f6f2;
  --knittable-ama: #e4404f;
  --knittable-opal-light: #d9efe7ff;
  --knittable-opal: #96b9ae;
  --knittable-opal-dark: #7ea498;
  --knittable-grass: #92be9a;
  --knittable-begonia: #f36f7a;
  --knittable-begonia-medium: #fa9ea6;
  --knittable-begonia-light: #fed1d5;
  --knittable-black: #292929;
  --knittable-grey-light: #efeeee;
  --knittable-grey: #cac8c5;
  --knittable-grey-dark: #9f9d9a;
  --knittable-grey-darker: #7a7875;
  --knittable-verdigris: #53b8b2;
  --knittable-latte: #c7a9a0;
  --knittable-jade: #3ca56a;
  --knittable-sapgreen: #497645;
  --knittable-dustymiller: #8ca3be;
  --knittable-facebook-blue: #1877f2;
  --knittable-mint: #A6D2AE;
}

h1 {
  @include heading1;
}

.app-sm-and-down h1 {
  @include heading5;
}

h2 {
  @include heading2;
}

.app-sm-and-down h2 {
  @include heading6;
}

h3 {
  @include heading3;
}

.app-sm-and-down h3 {
  @include heading6;
}

h4 {
  @include heading4;
}

.app-sm-and-down h4 {
  @include heading7;
}

h5 {
  @include heading5;
}

.app-sm-and-down h5 {
  @include heading8;
}

h6 {
  @include heading6;
}

.app-sm-and-down h6 {
  font-family: Philosopher;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 1.65rem */
}

.app-subtitle-small {
  font-family: "Neue Haas Grotesk Display Pro", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 1.5rem */
}

.app-page-title {
  margin: 8.5rem 2rem 1.5rem;

  color: var(--knittable-lav-darker);
}

.app-page-top-spacer {
  margin: 8.5rem 2rem 1.5rem;
}

.app-sm-and-down .app-page-top-spacer {
  margin: 7rem 2rem 1.5rem;
}

.app-page-bottom-spacer {
  margin-bottom: 2rem;
}

.app-page-subtitle {
  @include subtitleSmall;

  margin: 0 1.5rem 3rem;
  color: var(--knittable-mocha);
}

.app-error-plain-text {
  color: var(--knittable-ama);
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  justify-content: center;
}

.app-central-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.app-central-card {
  width: 100%;
}

.app-sm-and-down .app-central-card {
  width: 90%;
}

.app-loading-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.mdi-spin {
  animation: mdi-spin 2s infinite linear;
  font-size: 2em;
  color: white;
}

@keyframes mdi-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.v-main.app-main-container {
  background-color: var(--knittable-isa);
}

button.app-btn-icon {
  min-width: auto;
  font-size: 1.25rem;
}

.app-notification {
  border: 2px solid var(--knittable-lav-light);
  color: var(--knittable-lav-darker);
}

.app-warning {
  border: 2px solid var(--knittable-yama-light);
  color: var(--knittable-yama-darker);
}

.app-error {
  border: 2px solid var(--knittable-begonia-medium);
  color: var(--knittable-ama);
}
</style>
