<template>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <v-dialog v-model="isOpen" width="1300">
    <v-card class="app-modal-card">
      <div>
        <button class="close-button" @click="isOpen = false">
          <v-icon>mdi-close</v-icon>
        </button>
        <h3 class="app-modal-title">{{ $t("pattern_library.Load_project") }}</h3>
        <div class="table-container">
          <h4 class="table-title">{{ $t("pattern_library.Recent_projects") }}</h4>
          <projects-table
            :projects="getProjectsByType()"
            @select-project="selectProject"
            @project-deleted="handleProjectDeleted"
          />
        </div>
        <div class="table-container">
          <h4 class="table-title">{{ $t("pattern_library.All_projects") }}</h4>
          <projects-table
            :projects="projects"
            @select-project="selectProject"
            @project-deleted="handleProjectDeleted"
          />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from "vue";
import { useRoute } from "vue-router";
import ProjectsTable from "./ProjectsTable.vue";
import { getProjects } from "../../services/project";

const props = defineProps(["modelValue"]);
const emit = defineEmits(["update:modelValue"]);

const route = useRoute();
const isOpen = ref(props.modelValue);
const loading = ref(false);
const projects = ref([]);
const selectedProject = ref(null);
const patternType = ref(route.meta.patternType);

const selectProject = (project) => {
  isOpen.value = false;
  emit("update:modelValue", false);
};

const populateProjects = async () => {
  try {
    loading.value = true;
    const fetchedProjects = await getProjects();
    projects.value = fetchedProjects.sort(
      (a, b) => new Date(b.lastActivityAt) - new Date(a.lastActivityAt)
    );
    selectedProject.value = null;
  } catch (error) {
    alert("Error getting projects:", error);
  } finally {
    loading.value = false;
  }
};

const getProjectsByType = () => {
  return projects.value.filter((project) => project.patternType === patternType.value).slice(0, 4);
};

watch(isOpen, async (newValue) => {
  emit("update:modelValue", newValue);
});

watch(
  () => props.modelValue,
  async (newValue) => {
    isOpen.value = newValue;
    if (isOpen.value) {
      await populateProjects();
    }
  }
);

const handleProjectDeleted = (projectId) => {
  projects.value = projects.value.filter((project) => project.id !== projectId);
};
</script>

<style scoped lang="scss">
@import "./../../../scss/styles.scss";
@import "./../../../scss/modal.scss";

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  color: var(--knittable-lav-darker);
}

.app-modal-title {
  @include heading6;
}

.app-modal-content-divider {
  margin: 10px 0;
  border-top: 1px solid var(--knittable-lav-darker);
}

.table-title {
  @include subtitleLarge;
  color: var(--knittable-lav-darker);
  margin-bottom: 1.5rem;
  text-align: center;
}

.table-container {
  background: white;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}
</style>
