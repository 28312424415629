<template>
  <div class="pattern-information" :class="{ disabled: props.disabled }" ref="pdfHeader">
    <h6 @click="toggleInformation">
      {{ $t("common.General") }}
      <v-icon
        :icon="props.showInformation ? 'mdi-chevron-up' : 'mdi-chevron-down'"
        class="toggle-information-icon"
      ></v-icon>
    </h6>

    <div class="app-pattern-emphasized pattern-information-content" v-if="props.showInformation">
      <div class="pattern-description" v-if="props.description">{{ props.description }}</div>
      <v-row>
        <v-col v-for="(property, index) in props.properties" :key="index" cols="6">
          <div>
            <strong>{{ property.label }}</strong
            >: {{ property.value }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <strong>{{ $t("common.Knitting_gauge") }}:</strong> {{ props.stitchGauge }}
          {{ $t("common.stitches") }} x {{ props.rowGauge }} {{ $t("common.rows") }} = 10 x 10 cm
        </v-col>
      </v-row>

      <div class="subtitle">{{ $t("common.Measurements") }}</div>
      <v-row>
        <v-col v-for="(measurement, index) in props.measurements" :key="index" cols="6">
          <div>
            <strong>{{ measurement.label }}: </strong>
            <span>
              {{ measurement.value }} cm
              <span v-if="measurement.differsFromBaseline" class="is-modified-dot">●</span>
            </span>
          </div>
        </v-col>
      </v-row>

      <div class="subtitle">{{ $t("common.Design_details") }}</div>
      <v-row>
        <v-col v-for="(designDetail, index) in props.designDetails" :key="index" cols="6">
          <div>
            <strong>{{ designDetail.label }}</strong
            >: {{ designDetail.value }}
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script setup>
import { defineProps, watch, ref } from "vue";

const props = defineProps({
  showInformation: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  description: {
    type: String,
    required: false,
  },
  stitchGauge: {
    type: Number,
    default: 0,
  },
  rowGauge: {
    type: Number,
    default: 0,
  },
  properties: {
    type: Array,
    required: true,
    default: () => [],
  },
  measurements: {
    type: Array,
    required: true,
    default: () => [],
  },
  designDetails: {
    type: Array,
    required: true,
    default: () => [],
  },
});

const showInformation = ref(false);
const emit = defineEmits();

function toggleInformation() {
  showInformation.value = !showInformation.value;
  emit("update:showInformation", showInformation.value);
}

watch(
  () => props.showInformation,
  (newVal) => {
    showInformation.value = newVal;
  }
);
</script>

<style scoped lang="scss">
@import "./../../../scss/styles.scss";
@import "./../../../scss/pattern.scss";
.pattern-information {
  text-align: left;
  padding-bottom: 2.5em;
}

.pattern-information-content {
  background-color: var(--knittable-grey-light);
}

.pattern-information h4,
.pattern-information h6 {
  color: var(--knittable-lav-darker);
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}

.subtitle {
  @include eyebrowsLarge;

  margin-top: 2rem;
  margin-bottom: 1rem;
  color: var(--knittable-lav);
}

.pattern-description {
  margin-bottom: 1rem;
}

.is-modified-dot {
  color: var(--knittable-opal);
  font-size: 1.2rem;
}

.pattern-information.disabled {
  opacity: 0.5;
}

.toggle-information-icon {
  font-size: 1.9rem;
}

.app-sm-and-down .toggle-information-icon {
  font-size: 1.4rem;
}
</style>
