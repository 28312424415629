<template>
  <v-footer class="app-footer text-center d-flex flex-column">
    <v-row class="footer-row">
      <v-col cols="12" md="8" class="slogan-container">
        <div>
          <v-btn
            v-for="icon in icons"
            :key="icon.icon"
            class="mx-2"
            :icon="icon.icon"
            variant="text"
            :href="icon.url"
          ></v-btn>
        </div>
        <h5 class="py-5">
          {{ $t("footer.Slogan") }}
        </h5>
      </v-col>
      <v-col cols="12" md="4" class="footer-links-container">
        <a href="/en/termsandprivacy" class="text-white">{{
          $t("footer.terms_and_privacy")
        }}</a>
        <div>
          {{ $t("footer.Supported_by_") }}
        </div>
        <div>
          <a href="https://www.rannis.is/sjodir/rannsoknir/taeknithrounarsjodur/">
            <img
              class="app-supporter-logo"
              src="./../assets/taeknithrounarsjodur_logo_fitted.png"
            />
          </a>
        </div>
        <div>{{ new Date().getFullYear() }} — <strong>Knittable</strong></div>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <GoogleAuth v-if="authStore.authToken === null && isGoogleAuthEnabled" :useOneTap="true" />
  </v-footer>
</template>

<script setup>
import GoogleAuth from "../components/auth/GoogleAuth.vue";
import { useAuthStore } from "../stores/auth";

const authStore = useAuthStore();
const isGoogleAuthEnabled = process.env.VUE_APP_GOOGLE_AUTH_ENABLED === 'true';

const icons = [
  { icon: "mdi-linkedin", url: "https://www.linkedin.com/company/knittable" },
  { icon: "mdi-facebook", url: "https://www.facebook.com/profile.php?id=61566610493740" },
  { icon: "mdi-instagram", url: "https://www.instagram.com/itsknittable/" },
  { icon: "mdi-email", url: "mailto:hello@knittable.com" },
];
</script>

<style>
.app-supporter-logo {
  width: 120px;
}

.v-application footer.app-footer {
  border-top-left-radius: 2.5rem;
  border-top-right-radius: 2.5rem;
  background-color: var(--knittable-lav);
  color: white;
  z-index: 1000;
}

.footer-row {
  padding: 3rem;
  width: 100%;
}

.app-sm-and-down .footer-row {
  padding: 0.5rem;
}

.slogan-container {
  text-align: left;
}

.app-sm-and-down .slogan-container {
  text-align: center;
}

.footer-links-container {
  text-align: right;
}

.app-sm-and-down .footer-links-container {
  text-align: center;
}
</style>
