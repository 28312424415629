<template>
  <v-dialog v-model="isOpen" max-width="500">
    <v-card class="app-modal-card">
      <button class="close-button" @click="handleCancel">
        <v-icon>mdi-close</v-icon>
      </button>
      <h6 class="app-modal-title">{{ $t("pattern_library.Delete_project") }}</h6>
      <v-card-text class="app-modal-content">
        {{ $t("pattern_library.Are_you_sure_you_want_to_delete_this_project") }}
      </v-card-text>
      <div class="app-modal-button-container">
        <main-button color="grey" @click="handleCancel" compact>
          {{ $t("common.Cancel") }}
        </main-button>
        <main-button color="red" @click="handleConfirm" compact>
          {{ $t("common.Delete") }}
        </main-button>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, watch } from "vue";
import MainButton from "./../general/MainButton.vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "confirm"]);

const isOpen = ref(props.modelValue);

watch(isOpen, (newValue) => {
  emit("update:modelValue", newValue);
});

watch(
  () => props.modelValue,
  (newValue) => {
    isOpen.value = newValue;
  }
);

const handleCancel = () => {
  isOpen.value = false;
};

const handleConfirm = () => {
  emit("confirm");
  isOpen.value = false;
};
</script>

<style scoped lang="scss">
@import "./../../../scss/modal.scss";

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  color: var(--knittable-lav-darker);
}
</style>
