<template>
  <pattern-and-tool-placeholder
    :title="$t('common7.Knit_calculator')"
    :description="$t('knit_calculator.tool_description')"
    :first-bubble-text="$t('knit_calculator_placeholder.First_bubble')"
    :second-bubble-text="$t('knit_calculator_placeholder.Second_bubble')"
    :screenshot-path="getLocalizedWebpScreenshotPath"
    :screenshot-fallback-path="getLocalizedPngScreenshotPath"
    :detail-screenshot-path="getLocalizedDetailScreenshotPath"
    :screenshot-alt="getLocalizedPngScreenshotPath"
    :detail-screenshot-alt="getLocalizedDetailScreenshotPath"
    color="green"
    bubble-color="yellow"
    :sign-in-text="$t('placeholders.tool_sign_in_title')"
  />
</template>

<script setup>
import PatternAndToolPlaceholder from "../../components/PatternAndToolPlaceholder.vue";
import { useI18n } from 'vue-i18n';
import { getLocalizedImagePath } from '@/lib/imageUtils';
import knitCalculatorMockupEnWebp from '@/assets/knit-calculator-mockup-en.webp';
import knitCalculatorMockupIsWebp from '@/assets/knit-calculator-mockup-is.webp';
import knitCalculatorMockupEnPng from '@/assets/knit-calculator-mockup-en.png';
import knitCalculatorMockupIsPng from '@/assets/knit-calculator-mockup-is.png';
import knitCalculatorMockup2En from '@/assets/knit-calculator-mockup-2-en.png';
import knitCalculatorMockup2Is from '@/assets/knit-calculator-mockup-2-is.png';
import knitCalculatorMockupFiWebp from '@/assets/knit-calculator-mockup-fi.webp';
import knitCalculatorMockupFiPng from '@/assets/knit-calculator-mockup-fi.png';
import knitCalculatorMockup2Fi from '@/assets/knit-calculator-mockup-2-fi.png';

const { locale } = useI18n();

const imageMapWebp = {
  en: knitCalculatorMockupEnWebp,
  is: knitCalculatorMockupIsWebp,
  fi: knitCalculatorMockupFiWebp,
};

const imageMapPng = {
  en: knitCalculatorMockupEnPng,
  is: knitCalculatorMockupIsPng,
  fi: knitCalculatorMockupFiPng,
};

const detailImageMap = {  
  en: knitCalculatorMockup2En,
  is: knitCalculatorMockup2Is,
  fi: knitCalculatorMockup2Fi,
};

const getLocalizedWebpScreenshotPath = getLocalizedImagePath(imageMapWebp, locale);
const getLocalizedPngScreenshotPath = getLocalizedImagePath(imageMapPng, locale);
const getLocalizedDetailScreenshotPath = getLocalizedImagePath(detailImageMap, locale);
</script>
