<template>
  <div class="app-page-top-spacer"></div>
  <div class="placeholder-container">
    <h3 class="placeholder-title">{{ title }}</h3>
    <p class="placeholder-description">{{ description }}</p>

    <ImageCarousel v-if="props.carouselImages" :images="props.carouselImages" class="carousel-images"/>
    <v-row class="ma-0">
      <v-col cols="12" md="6" class="left-column">
        <div class="placeholder-sign-in-container">
          <sign-in-content :custom-title="props.signInText" />
        </div>
      </v-col>
      <v-col cols="12" md="6" :class="colorClass">
        <div class="showcase-container">
          <div v-if="firstBubbleText" class="feature-bubble bubble-1" :class="bubbleColorClass">
            {{ firstBubbleText }}
          </div>
          <div v-if="secondBubbleText" class="feature-bubble bubble-2" :class="bubbleColorClass">
            {{ secondBubbleText }}
          </div>
          <div v-if="thirdBubbleText" class="feature-bubble bubble-3" :class="bubbleColorClass">
            {{ thirdBubbleText }}
          </div>

          <div class="screenshot-container">
            <picture v-if="screenshotFallbackPath">
              <source :srcset="screenshotPath" type="image/webp">
              <img :src="screenshotFallbackPath" :alt="screenshotAlt" class="screenshot main-screenshot" />
            </picture>
            <img 
              v-else
              :src="screenshotPath" 
              :alt="screenshotAlt" 
              class="screenshot main-screenshot" 
            />
            <img
              v-if="detailScreenshotPath"
              :src="detailScreenshotPath"
              :alt="detailScreenshotAlt"
              class="screenshot detail-screenshot"
            />
          </div>
        </div>
      </v-col>
    </v-row>

  </div>
</template>

<script setup>
import { computed } from "vue";
import SignInContent from "./auth/SignInContent.vue";
import ImageCarousel from "./blog/ImageCarousel.vue";

const props = defineProps({
  title: String,
  description: String,
  signInText: String,
  firstBubbleText: String,
  secondBubbleText: String,
  thirdBubbleText: String,
  screenshotPath: String,
  screenshotFallbackPath: String,
  detailScreenshotPath: String,
  screenshotAlt: String,
  detailScreenshotAlt: String,
  color: {
    type: String,
    required: true,
    validator: (value) => ["yellow", "red", "green", "purple"].includes(value),
  },
  bubbleColor: {
    type: String,
    required: true,
    validator: (value) => ["yellow", "green"].includes(value),
  },
  carouselImages: {
    type: Array,
    default: null,
  },
});

const colorClass = computed(() => {
  return `showcase-${props.color}`;
});

const bubbleColorClass = computed(() => {
  return `showcase-${props.bubbleColor}`;
});
</script>

<style scoped lang="scss">
@import "./../../scss/styles.scss";

.carousel-images {
  margin-top: 0rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: white;
}

.placeholder-title {
  color: var(--knittable-lav-darker);
  margin-bottom: 2rem;
}

.app-sm-and-down .placeholder-title {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.placeholder-description {
  @include paragraphMediumRegular;
  margin-bottom: 2rem;
  margin-left: 15rem;
  margin-right: 15rem;
}

.app-sm-and-down .placeholder-description {
  margin-bottom: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.left-column {
  background-color: white;
  height:auto;
}

.placeholder-sign-in-container {
  margin: 4rem ;
  background: white;
  padding: 2rem;
  text-align: center;
  box-shadow: 0px 0px 40px #00000029;
  border-radius: 38px;
}

.app-sm-and-down .placeholder-sign-in-container {
  margin: 2rem 0rem 0rem;
  padding: 1rem;
}

.screenshot-container {
  position: relative;
  max-width: 50%;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  margin-bottom: 8.5rem;
}

.app-sm-and-down .screenshot-container {
  margin-top: 2rem;
  margin-bottom: 7rem;
}

.placeholder-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.placeholder-content {
  flex: 1;
  display: flex;
  gap: 2rem;
  padding: 2rem;
}

.showcase-container {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.screenshot {
  &.main-screenshot {
    width: 100%;
    z-index: 1;
  }

  &.detail-screenshot {
    position: absolute;
    width: 100%;
    right: -30%;
    bottom: -9%;
    z-index: 2;
  }
}

.feature-bubble {
  @include bubbleText;
  position: absolute;
  color: white;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  z-index: 3;

  width: 205px;
  height: 205px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1rem;

  &.bubble-1 {
    top: 13%;
    left: 5%;
  }

  &.bubble-2 {
    top: 36%;
    right: 2%;
  }

  &.bubble-3 {
    bottom: 15%;
    left: 5%;
  }
}

.app-sm-and-down .feature-bubble {
  @include bubbleTextSmall;
  width: 120px;
  height: 120px;
}

.showcase-red {
  background-color: var(--knittable-begonia);
}

.showcase-green {
  background-color: var(--knittable-mint);
}

.showcase-yellow {
  background-color: var(--knittable-yama);
}

.showcase-purple {
  background-color: var(--knittable-lav-light);
}
</style>
