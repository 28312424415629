<template>
  <article class="blog-template app-central-card">
    <header class="template-header">
      <h1 class="template-title">{{ title }}</h1>
      <p v-for="(desc, index) in description" :key="index" class="template-description">
        {{ desc }}
      </p>
    </header>
    <Intro v-if="intro" :intro="intro" />
    <ContentGrid :items="gridItems" />
    <ContentTables v-if="tableSections" :tableSections="tableSections" />
    <ImageCarousel v-if="carouselImages" :images="carouselImages" />
    <ProjectSpecifics v-if="projectSpecifics" :projectSpecifics="projectSpecifics" />
    <ContentScreenshots v-bind="screenshotsConfig" />
    <Pricing v-if="showPricing" :showPromoCode="promoCode != null" :promoCode="promoCode" />
  </article>
</template>

<script setup>
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";
import ContentGrid from "./ContentGrid.vue";
import ContentTables from "./ContentTables.vue";
import ContentScreenshots from "./ContentScreenshots.vue";
import Pricing from "../Pricing.vue";
import ImageCarousel from "./ImageCarousel.vue";
import Intro from "./Intro.vue";
import ProjectSpecifics from "./ProjectSpecifics.vue";

const route = useRoute();
const promoCode = ref(null);

onMounted(() => {
  // Check if there's a promo parameter in the URL
  if (route.query.promo) {
    promoCode.value = route.query.promo;
  }
});

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  intro: {
    type: Object,
    default: null,
  },
  description: {
    type: Array,
    default: null,
  },
  gridItems: {
    type: Array,
    default: null,
  },
  tableSections: {
    type: Array,
    default: null,
  },
  screenshotsConfig: {
    type: Object,
    default: null,
  },
  carouselImages: {
    type: Array,
    default: null,
  },
  projectSpecifics: {
    type: Object,
    default: null,
  },
  showPricing: {
    type: Boolean,
    default: true,
  },
});
</script>

<style scoped lang="scss">
@import "../../../scss/styles.scss";

.blog-template {
  text-align: center;
  padding: 2rem 0rem;
  margin-top: 8.5rem;
}

.app-sm-and-down .blog-template {
  padding: 1.5rem 0rem;
  margin-top: 6rem;
}

.template-header {
  padding-bottom: 3.5rem;
  .template-title {
    color: var(--knittable-lav-darker);
    @include heading3;
    margin-bottom: 1.5rem;
  }

  .template-description {
    @include paragraphMediumRegular;
    color: var(--knittable-black);
    max-width: 700px;
    margin: 0 auto;
    line-height: 1.6;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.app-sm-and-down .template-header {
  padding: 0rem 1rem 3rem 1rem;

  .template-title {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  .template-description {
    @include paragraphSmallRegular;
    max-width: 100%;
  }
}
</style>
