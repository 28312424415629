<template>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <pattern-header
    v-model:show-information="showPatternInformation"
    v-model:show-settings="showSettings"
    :disabled="!showPattern"
    :default-title="$t('common.Beanie')"
  >
  </pattern-header>
  <v-container class="app-pattern-form-container" fluid>
    <v-row justify="center">
      <v-col v-if="showSettings" cols="12" sm="12" md="6" class="app-pattern-form-col">
        <!-- <p class="app-alert" v-if="form.decreaseType == 'cross' && crossDecreaseCount < 4">
                {{ $t('beanie.There_are_too_few_cast_on_stitches')}}
              </p> -->
        <div class="app-form-description">{{ $t("beanie.pattern_description") }}</div>
        <v-form class="app-pattern-form" @submit.prevent="calculatePattern(false, true)">
          <div class="app-pattern-form-section">
            <header class="app-pattern-form-header">
              <div>
                {{ $t("common.Measurements") }}
              </div>
            </header>
            <persons-select-field
              v-model="projectStateStore.currentSettings.chosenPerson"
              @item-selected="(item, oldItem) => onPersonSelected(item.value, oldItem?.value)"
            >
            </persons-select-field>
            <select-field
              v-if="!projectStateStore.startingFromScratch | (currentStep >= 1)"
              v-model="projectStateStore.currentSettings.chosenSize"
              ref="selectSizesField"
              :label="t('common3.Size_')"
              :placeholder="t('common3.Choose_a_size')"
              :items="sizes"
              :small="true"
              @item-selected="(item) => onSizeSelected(item.value)"
            ></select-field>

            <advanced-settings
              v-if="!projectStateStore.startingFromScratch | (currentStep >= 2)"
              :title="t('common.Advanced_settings')"
              :is-modified="hasCustomAdvancedSettings"
            >
              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="projectStateStore.currentSettings.headCircumf"
                  :label="t('beanie.Head_circumference_in_cm_')"
                  :description="t('beanie.Head_circumference_description')"
                  :placeholder="t('beanie.Type_in_head_circumference')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="
                    projectStateStore.currentSettings.chosenSize?.value.sizeHeadCircumf
                  "
                  v-model:differs-from-baseline="differsFromBaseline.headCircumf"
                ></input-field>
                <v-spacer></v-spacer>
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="projectStateStore.currentSettings.foldLength"
                  :label="t('beanie.Brim_height_in_cm_')"
                  :description="t('beanie.Brim_height_description')"
                  :placeholder="t('beanie.Type_in_brim_height')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="
                    projectStateStore.currentSettings.chosenSize?.value.sizeFoldLength
                  "
                  v-model:differs-from-baseline="differsFromBaseline.foldLength"
                ></input-field>
              </div>
            </advanced-settings>
          </div>

          <div class="app-pattern-form-section" v-if="!startingFromScratch | (currentStep >= 2)">
            <header class="app-pattern-form-header">
              <div>
                {{ $t("common4.Design") }}
              </div>
            </header>
            <ribbing-toggle-field v-model="projectStateStore.currentSettings.cuffPatternRepCount">
            </ribbing-toggle-field>
            <toggle-field
              :label="t('beanie.Ribbing_coverage')"
              :options="stockinetteTopOptions"
              v-model="projectStateStore.currentSettings.stockinetteTop"
            ></toggle-field>
            <toggle-field
              :label="t('beanie.Decrease_type')"
              :options="decreaseTypeOptions"
              v-model="projectStateStore.currentSettings.decreaseType"
            ></toggle-field>
          </div>

          <div class="app-pattern-form-section" v-if="!startingFromScratch | (currentStep >= 3)">
            <knitting-gauge
              v-model:row-gauge="projectStateStore.currentSettings.rowGauge"
              v-model:stitch-gauge="projectStateStore.currentSettings.stitchGauge"
              v-model:estimate-row-gauge-enabled="
                projectStateStore.currentSettings.estimateRowGaugeEnabled
              "
            ></knitting-gauge>
          </div>
          <div v-if="isSubscribedOrOnTrial">
            <div v-if="projectStateStore.newVersionAvailable(latestVersion)">
              <div class="new-version-available-notification">
                {{ $t("pattern_form.A_new_version_is_available_") }}
              </div>
              <pattern-form-button
                :label="$t('pattern_form.Upgrade_and_calculate')"
                :disabled="!buttonIsEnabled"
                @click="calculatePattern(true, true)"
              ></pattern-form-button>
            </div>
            <pattern-form-button
              :label="$t('common.Calculate_pattern')"
              :disabled="!buttonIsEnabled"
            >
            </pattern-form-button>
          </div>
        </v-form>
        <pattern-form-button
          v-if="!isSubscribedOrOnTrial"
          :label="$t('common7.Subscribe')"
          @click="goToSubscription"
          color="red"
        >
        </pattern-form-button>
        <pattern-form-button
          v-if="!projectStateStore.startingFromScratch"
          :label="$t('common.Cancel')"
          color="grey"
          @click="cancelEdit"
        >
        </pattern-form-button>
      </v-col>
      <v-col id="pattern-container" cols="12" sm="12" md="6" v-if="showPattern">
        <pattern-information
          v-model:show-information="showPatternInformation"
          :disabled="buttonIsEnabled"
          :pattern-type="patternType"
          :description="$t('beanie.pattern_description')"
          :stitch-gauge="projectStateStore.currentSettings.stitchGauge"
          :row-gauge="projectStateStore.currentSettings.rowGauge"
          :properties="[
            {
              label: $t('common.Size_category'),
              value: projectStateStore.currentSettings.chosenPerson?.key,
            },
            { label: $t('common.Size'), value: projectStateStore.currentSettings.chosenSize?.key },
          ]"
          :measurements="[
            {
              label: $t('beanie.Head_circumference_in_cm_'),
              value: projectStateStore.currentSettings.headCircumf,
              differsFromBaseline: differsFromBaseline.headCircumf,
            },
            {
              label: $t('beanie.Brim_height_in_cm_'),
              value: projectStateStore.currentSettings.foldLength,
              differsFromBaseline: differsFromBaseline.foldLength,
            },
          ]"
          :design-details="[
            {
              label: $t('common.Ribbing_type'),
              value: projectStateStore.currentSettings.cuffPatternRepCount?.key,
            },
            {
              label: $t('beanie.Ribbing_coverage'),
              value: projectStateStore.currentSettings.stockinetteTop?.key,
            },
            {
              label: $t('beanie.Decrease_type'),
              value: projectStateStore.currentSettings.decreaseType?.key,
            },
          ]"
        ></pattern-information>
        <pattern-renderer
          :versions="versions"
          :disabled="buttonIsEnabled"
          :data="beanieData"
        ></pattern-renderer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";

import AdvancedSettings from "../../components/AdvancedSettings";
import InputField from "../../components/general/InputField.vue";
import KnittingGauge from "../../components/KnittingGauge.vue";
import { composeRoute } from "../../lib/navigationUtils";
import PatternFormButton from "../../components/patterns/PatternFormButton.vue";
import PatternRenderer from "./../../components/patterns/PatternRenderer";
import PersonsSelectField from "./../../components/patterns/PersonsSelectField";
import RibbingToggleField from "./../../components/patterns/RibbingToggleField.vue";
import { calculateBeanie } from "./../../services/pattern";
import SelectField from "../../components/general/SelectField.vue";
import { measurementFieldsDisabled, hasEmptyValues } from "../../lib/patternFormUtils";
import ToggleField from "../../components/general/ToggleField.vue";
import { useLangStore } from "./../../stores/lang";
import PatternHeader from "../../components/pattern_utils/PatternHeader.vue";
import PatternInformation from "../../components/pattern_utils/PatternInformation.vue";

import { useProjectStateStore } from "../../stores/projectState";

const defaultSettings = {
  chosenPerson: null,
  chosenSize: null,
  stitchGauge: null,
  rowGauge: null,
  estimateRowGaugeEnabled: true,
  headCircumf: null,
  foldLength: null,
  cuffPatternRepCount: null,
  stockinetteTop: null,
  decreaseType: null,
};

const loading = ref(false);

const langStore = useLangStore();
const locale = langStore.getLocale();
const router = useRouter();

const route = useRoute();
const patternType = route.meta.patternType;
const projectStateStore = useProjectStateStore(patternType, defaultSettings);

const latestVersion = "1.0.0";
const versions = {
  "1.0.0": "BeaniePattern",
};

const startingFromScratch = ref(true);

const { t } = useI18n();

const differsFromBaseline = ref({
  headCircumf: false,
  foldLength: false,
});

const hasCustomAdvancedSettings = ref(false);

watch(
  differsFromBaseline,
  (newValue) => {
    hasCustomAdvancedSettings.value = Object.values(newValue).some((value) => value);
  },
  { deep: true }
);

watch(
  () => projectStateStore.currentSettings,
  () => {
    projectStateStore.checkIfHasChanges();
  },
  { deep: true }
);

watch(
  () => projectStateStore.startingFromScratch,
  () => {
    if (projectStateStore.startingFromScratch) {
      showPattern.value = false;
      showSettings.value = true;
      currentStep.value = 0;
    }
  },
  { deep: true }
);

const selectSizesField = ref(null);
const sizes = ref(null);
let beanieData = ref(null);

const valuesDisabled = computed(() => measurementFieldsDisabled(projectStateStore.currentSettings));

const showPattern = ref(false);
const showSettings = ref(false);
const showPatternInformation = ref(false);

const currentStep = ref(0);

const isSubscribedOrOnTrial = ref(true);

const initializePattern = async (projectId = null) => {
  loading.value = true;
  try {
    await projectStateStore.initializeProjectState(latestVersion, projectId);
    if (projectId != null && projectId != route.params.project_id) {
      router.replace({
        ...route,
        params: { ...route.params, project_id: projectId },
      });
    }

    if (projectStateStore.storedSettings == null || !formIsValid.value) {
      showSettings.value = true;
      showPattern.value = false;
    } else {
      await calculatePattern(false, false);
    }
  } catch (error) {
    if (error.response?.status === 404 && route.params.project_id) {
      router.push(composeRoute(locale.value, "not-found"));
    } else {
      console.error("Error loading project:", error);
    }
  } finally {
    loading.value = false;
  }
};

watch(
  () => route.params.project_id,
  async (newProjectId) => {
    if (route.meta.patternType !== patternType || newProjectId === projectStateStore.projectId) {
      return;
    }

    await initializePattern(newProjectId || null);

    if (route.params.edit) {
      showSettings.value = true;
    }

    if (route.params.project_id && route.params.project_id != projectStateStore.projectId) {
      router.replace({
        ...route,
        params: { ...route.params, project_id: projectStateStore.projectId },
      });
    }
  },
  { immediate: true }
);

const cancelEdit = () => {
  projectStateStore.resetToStoredSettings();
  showSettings.value = false;
};

watch(
  () => projectStateStore.currentSettings.chosenPerson,
  () => {
    if (projectStateStore.currentSettings.chosenPerson == null) {
      return;
    }

    switch (projectStateStore.currentSettings.chosenPerson.value) {
      case "child":
        sizes.value = sizeBabyChildYouth.value;
        break;
      case "grown_up":
        sizes.value = sizeGrownUp.value;
        break;
      case "custom":
        sizes.value = null;
    }
  }
);

function onPersonSelected(newChosenPerson, oldChosenPerson) {
  if (newChosenPerson == oldChosenPerson) {
    return;
  }
  if (newChosenPerson == "custom") {
    projectStateStore.currentSettings.chosenSize = "custom";
  } else {
    projectStateStore.currentSettings.chosenSize = null;
  }
  projectStateStore.currentSettings.headCircumf = null;
  projectStateStore.currentSettings.foldLength = null;

  currentStep.value = Math.max(currentStep.value, 1);
}

function onSizeSelected(newChosenSize) {
  if (newChosenSize != null) {
    projectStateStore.currentSettings.headCircumf = newChosenSize.sizeHeadCircumf;
    projectStateStore.currentSettings.foldLength = newChosenSize.sizeFoldLength;
  }

  currentStep.value = Math.max(currentStep.value, 2);
}

const allDesignFieldSet = computed(() => {
  return (
    projectStateStore.currentSettings.cuffPatternRepCount &&
    projectStateStore.currentSettings.stockinetteTop &&
    projectStateStore.currentSettings.decreaseType
  );
});

watch(allDesignFieldSet, (newValue) => {
  if (newValue) {
    currentStep.value = Math.max(currentStep.value, 3);
  }
});

const buttonIsEnabled = computed(() => {
  return formIsValid.value && projectStateStore.hasChanges;
});

const formIsValid = computed(() => {
  return !hasEmptyValues(projectStateStore.currentSettings);
});

const calculatePattern = async (upgrade, saveProject) => {
  try {
    loading.value = true;
    if (formIsValid.value) {
      if (upgrade) {
        projectStateStore.version = latestVersion;
      }
      await getBeanieParameters();
      if (saveProject) {
        await projectStateStore.addOrUpdateProject();
      }
      showPattern.value = true;
      showSettings.value = false;
    } else {
      showSettings.value = true;
      showPattern.value = false;
    }
    window.scrollTo(0, 0);
  } catch (error) {
    if (error.response && error.response.status === 403) {
      isSubscribedOrOnTrial.value = false;
    } else {
      throw error;
    }
  } finally {
    loading.value = false;
  }
};

async function getBeanieParameters() {
  const beanieParameters = await calculateBeanie(
    projectStateStore.currentSettings.stitchGauge / 10,
    projectStateStore.currentSettings.rowGauge / 10,
    projectStateStore.currentSettings.headCircumf,
    projectStateStore.currentSettings.foldLength,
    projectStateStore.currentSettings.cuffPatternRepCount.value,
    projectStateStore.currentSettings.stockinetteTop.value,
    projectStateStore.currentSettings.decreaseType.value
  );

  beanieData.value = beanieParameters;
  projectStateStore.startingFromScratch = false;
  return beanieParameters;
}

const goToSubscription = async () => {
  await projectStateStore.addOrUpdateProject();
  router.push(composeRoute(locale.value, "subscription", "beanie"));
};

const stockinetteTopOptions = computed(() => {
  return [
    { key: t("beanie.Whole_beanie_ribbed_option"), value: false },
    { key: t("beanie.Only_brim_ribbed_option"), value: true },
  ];
});

const decreaseTypeOptions = computed(() => {
  return [
    { key: t("beanie.Even_decrease_option"), value: "even" },
    { key: t("beanie.Cross_decrease_option"), value: "cross" },
  ];
});

const sizeBabyChildYouth = computed(() => {
  return [
    { key: "0-3 " + t("common.months"), value: { sizeFoldLength: 5.5, sizeHeadCircumf: 39.5 } },
    { key: "3-6 " + t("common.months"), value: { sizeFoldLength: 5.5, sizeHeadCircumf: 42.5 } },
    { key: "6-9 " + t("common.months"), value: { sizeFoldLength: 6.0, sizeHeadCircumf: 45.0 } },
    { key: "9-12 " + t("common.months"), value: { sizeFoldLength: 6.0, sizeHeadCircumf: 46.5 } },
    { key: "1-2 " + t("common.years"), value: { sizeFoldLength: 6.5, sizeHeadCircumf: 48.0 } },
    { key: "2-4 " + t("common.years"), value: { sizeFoldLength: 6.5, sizeHeadCircumf: 49.0 } },
    { key: "4-6 " + t("common.years"), value: { sizeFoldLength: 7.0, sizeHeadCircumf: 50.0 } },
    { key: "6-8 " + t("common.years"), value: { sizeFoldLength: 7.0, sizeHeadCircumf: 51.0 } },
    { key: "8-10 " + t("common.years"), value: { sizeFoldLength: 7.0, sizeHeadCircumf: 52.0 } },
    { key: "10-12 " + t("common.years"), value: { sizeFoldLength: 7.0, sizeHeadCircumf: 52.5 } },
  ];
});
const sizeGrownUp = computed(() => {
  return [
    { key: "XS", value: { sizeFoldLength: 7.0, sizeHeadCircumf: 53.0 } },
    { key: "S", value: { sizeFoldLength: 7.5, sizeHeadCircumf: 55.0 } },
    { key: "M", value: { sizeFoldLength: 7.5, sizeHeadCircumf: 56.5 } },
    { key: "L", value: { sizeFoldLength: 8.0, sizeHeadCircumf: 59.0 } },
    { key: "XL", value: { sizeFoldLength: 8.5, sizeHeadCircumf: 61.0 } },
  ];
});
</script>

<style lang="scss" scoped>
@import "./../../../scss/form.scss";
</style>
