<template>
  <div class="chart-section">
    <h2 v-if="chartSection.title" class="section-title">{{ chartSection.title }}</h2>
    <div class="content-wrapper">
      <div class="charts-container">
        <div v-for="(chart, index) in chartSection.charts" :key="index" class="chart-item">
          <h3 v-if="chart.title" class="chart-title">{{ chart.title }}</h3>
          <div class="chart-image-container" @click="openZoom(chart)">
            <img :src="require(`@/assets/${chart.source}`)" :alt="chart.alt" class="chart-image" />
            <div class="zoom-hint">
              <v-icon>mdi-magnify</v-icon>
            </div>
          </div>
        </div>
      </div>
      <div v-if="chartSection.legend" class="legend-container">
        <img
          :src="require(`@/assets/${chartSection.legend.source}`)"
          :alt="chartSection.legend.alt"
          class="legend-image"
        />
      </div>
    </div>

    <!-- Zoom Dialog -->
    <v-dialog v-model="showZoom" fullscreen class="chart-zoom-dialog">
      <div class="zoom-overlay" @click="showZoom = false">
        <button class="close-button" @click="showZoom = false">
          <v-icon size="24">mdi-close</v-icon>
        </button>
        <div class="zoom-content" @click.stop>
          <img
            v-if="selectedChart"
            :src="require(`@/assets/${selectedChart.source}`)"
            :alt="selectedChart.alt"
            class="zoomed-image"
          />
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref } from "vue";

defineProps({
  chartSection: {
    type: Object,
    required: true,
  },
});

const showZoom = ref(false);
const selectedChart = ref(null);

const openZoom = (chart) => {
  selectedChart.value = chart;
  showZoom.value = true;
};
</script>

<style scoped lang="scss">
@import "../../../scss/styles.scss";

.chart-section {
  padding: 2rem 2rem;
  background: #ede6f3;
}

.section-title {
  @include heading5;
  color: var(--knittable-lav-darker);
  text-align: center;
  margin-bottom: 2rem;
}

.charts-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  justify-content: center;
}

.content-wrapper {
  display: grid;
  grid-template-columns: 5fr 1fr;
  gap: 2rem;
  align-items: start;
}

.chart-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.chart-image {
  max-width: 100%;
  height: auto;
  border-radius: 0.5rem;
}

.chart-title {
  @include paragraphMediumBold;
  color: var(--knittable-lav-darker);
  text-align: center;
}

.legend-container {
  padding-top: 2.1rem;
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
}

.legend-image {
  max-width: 100%;
  height: auto;
  border-radius: 0.5rem;
}

.chart-image-container {
  position: relative;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.9;

    .zoom-hint {
      opacity: 1;
    }
  }
}

.zoom-hint {
  position: absolute;
  right: 6px;
  bottom: 12px;
  background: rgba(129, 95, 183, 0.8);
  color: white;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.chart-zoom-dialog {
  :deep(.v-overlay__content) {
    width: 100%;
    height: 100%;
    max-width: none !important;
    max-height: none !important;
  }
}

.zoom-overlay {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.zoom-content {
  margin: 32px 0;
  max-height: calc(100vh - 64px);
  overflow-x: auto;
  overflow-y: hidden;
  cursor: default;
  display: flex;
  align-items: center;
}

.zoomed-image {
  height: calc(100vh - 64px);
  width: auto;
  object-fit: contain;
}

.app-sm-and-down {
  .chart-section {
    padding: 2rem 1rem;
  }

  .content-wrapper {
    grid-template-columns: 1fr;
  }

  .charts-container {
    grid-template-columns: 1fr;
  }

  .legend-container {
    max-width: 240px;
    margin: 0 auto 0;
    padding-top: 0;
  }

  .zoom-hint {
    opacity: 1;
  }

  .chart-image-container:active {
    opacity: 0.8;
  }
}

.close-button {
  position: fixed;
  top: 16px;
  right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--knittable-lav-lighter);
  border: none;
  color: var(--knittable-lav-darker);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  z-index: 1;

  &:hover {
    background: var(--knittable-lav-light);
    transform: scale(1.1);
  }
}
</style>
