<template>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <pattern-header
    v-model:show-information="showPatternInformation"
    v-model:show-settings="showSettings"
    :disabled="!showPattern"
    :default-title="$t('common.Mittens')"
  >
  </pattern-header>
  <v-container class="app-pattern-form-container" fluid>
    <v-row justify="center">
      <v-col v-if="showSettings" cols="12" sm="12" md="6" class="app-pattern-form-col">
        <div class="app-form-description">{{ $t("mittens.pattern_description") }}</div>
        <v-form class="app-pattern-form" @submit.prevent="calculatePattern(false, true)">
          <div class="app-pattern-form-section">
            <header class="app-pattern-form-header">
              <div>
                {{ $t("common.Measurements") }}
              </div>
            </header>
            <persons-select-field
              v-model="projectStateStore.currentSettings.chosenPerson"
              @item-selected="(item, oldItem) => onPersonSelected(item.value, oldItem?.value)"
            >
            </persons-select-field>
            <select-field
              v-if="!projectStateStore.startingFromScratch | (currentStep >= 1)"
              v-model="projectStateStore.currentSettings.chosenSize"
              ref="selectSizesField"
              :label="$t('common3.Size_')"
              :placeholder="$t('common3.Choose_a_size')"
              :items="sizes"
              :small="true"
              @item-selected="(item) => onSizeSelected(item.value)"
            ></select-field>

            <advanced-settings
              v-if="!projectStateStore.startingFromScratch | (currentStep >= 2)"
              :title="t('common.Advanced_settings')"
              :is-modified="hasCustomAdvancedSettings"
            >
              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="projectStateStore.currentSettings.wristCircumf"
                  :label="$t('mittens.Cuff_circumference_in_cm_')"
                  :description="$t('mittens.Cuff_circumference_description')"
                  :placeholder="$t('mittens.Type_in_cuff_circumference')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="
                    projectStateStore.currentSettings.chosenSize?.value.sizeWristCircumf
                  "
                  v-model:differs-from-baseline="differsFromBaseline.wristCircumf"
                ></input-field>
                <v-spacer></v-spacer>
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="projectStateStore.currentSettings.cuffLength"
                  :label="$t('mittens.Cuff_length_in_cm_')"
                  :description="$t('mittens.Cuff_length_description')"
                  :placeholder="$t('mittens.Type_in_cuff_length')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="
                    projectStateStore.currentSettings.chosenSize?.value.sizeCuffLength
                  "
                  v-model:differs-from-baseline="differsFromBaseline.cuffLength"
                ></input-field>
              </div>
              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="projectStateStore.currentSettings.handCircumf"
                  :label="$t('mittens.Hand_circumference_in_cm_')"
                  :description="$t('mittens.Hand_circumference_description')"
                  :placeholder="$t('mittens.Type_in_hand_circumference')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="
                    projectStateStore.currentSettings.chosenSize?.value.sizeHandCircumf
                  "
                  v-model:differs-from-baseline="differsFromBaseline.handCircumf"
                ></input-field>
                <v-spacer></v-spacer>
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="projectStateStore.currentSettings.handLength"
                  :label="$t('mittens.Hand_length_in_cm_')"
                  :description="$t('mittens.Hand_length_description')"
                  :placeholder="$t('mittens.Type_in_hand_length')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="
                    projectStateStore.currentSettings.chosenSize?.value.sizeHandLength
                  "
                  v-model:differs-from-baseline="differsFromBaseline.handLength"
                ></input-field>
              </div>
            </advanced-settings>
          </div>

          <div
            class="app-pattern-form-section"
            v-if="!projectStateStore.startingFromScratch | (currentStep >= 2)"
          >
            <header class="app-pattern-form-header">
              <div>
                {{ $t("common4.Design") }}
              </div>
            </header>
            <ribbing-toggle-field v-model="projectStateStore.currentSettings.cuffPatternRepCount">
            </ribbing-toggle-field>
          </div>

          <div
            class="app-pattern-form-section"
            v-if="!projectStateStore.startingFromScratch | (currentStep >= 2)"
          >
            <header class="app-pattern-form-header">
              <div>
                {{ $t("mittens.Felting") }}
              </div>
              <information-bubble v-bind:colorsInverted="true" position="bottom" width="medium">
                <p>{{ $t("mittens.Felting_information_bubble_0") }}</p>
                <p>{{ $t("mittens.Felting_information_bubble_1") }}</p>
              </information-bubble>
            </header>
            <select-field
              v-model="projectStateStore.currentSettings.feltRatio"
              :label="$t('mittens.Felting_density_')"
              :placeholder="$t('mittens.Choose_how_much_to_felt')"
              :items="feltRatioOptions"
              :disabled="valuesDisabled"
              :small="true"
            ></select-field>
          </div>

          <div
            class="app-pattern-form-section"
            v-if="!projectStateStore.startingFromScratch | (currentStep >= 3)"
          >
            <knitting-gauge
              v-model:row-gauge="projectStateStore.currentSettings.rowGauge"
              v-model:stitch-gauge="projectStateStore.currentSettings.stitchGauge"
              v-model:estimate-row-gauge-enabled="
                projectStateStore.currentSettings.estimateRowGaugeEnabled
              "
            ></knitting-gauge>
          </div>
          <div v-if="isSubscribedOrOnTrial">
            <div v-if="projectStateStore.newVersionAvailable(latestVersion)">
              <div class="new-version-available-notification">
                {{ $t("pattern_form.A_new_version_is_available_") }}
              </div>
              <pattern-form-button
                :label="$t('pattern_form.Upgrade_and_calculate')"
                :disabled="!buttonIsEnabled"
                @click="calculatePattern(true, true)"
              ></pattern-form-button>
            </div>
            <pattern-form-button
              :label="$t('common.Calculate_pattern')"
              :disabled="!buttonIsEnabled"
            >
            </pattern-form-button>
          </div>
        </v-form>
        <pattern-form-button
          v-if="!isSubscribedOrOnTrial"
          :label="$t('common7.Subscribe')"
          @click="goToSubscription"
          color="red"
        >
        </pattern-form-button>
        <pattern-form-button
          v-if="!projectStateStore.startingFromScratch"
          :label="$t('common.Cancel')"
          color="grey"
          @click="cancelEdit"
        >
        </pattern-form-button>
      </v-col>
      <v-col id="pattern-container" cols="12" sm="12" md="6" v-if="showPattern">
        <pattern-information
          v-model:show-information="showPatternInformation"
          :disabled="buttonIsEnabled"
          :pattern-type="patternType"
          :description="$t('mittens.pattern_description')"
          :stitch-gauge="projectStateStore.currentSettings.stitchGauge"
          :row-gauge="projectStateStore.currentSettings.rowGauge"
          :properties="[
            {
              label: $t('common.Size_category'),
              value: projectStateStore.currentSettings.chosenPerson?.key,
            },
            { label: $t('common.Size'), value: projectStateStore.currentSettings.chosenSize?.key },
          ]"
          :measurements="[
            {
              label: $t('mittens.Cuff_circumference_in_cm_'),
              value: projectStateStore.currentSettings.wristCircumf,
              differsFromBaseline: differsFromBaseline.wristCircumf,
            },
            {
              label: $t('mittens.Cuff_length_in_cm_'),
              value: projectStateStore.currentSettings.cuffLength,
              differsFromBaseline: differsFromBaseline.cuffLength,
            },
            {
              label: $t('mittens.Hand_circumference_in_cm_'),
              value: projectStateStore.currentSettings.handCircumf,
              differsFromBaseline: differsFromBaseline.handCircumf,
            },
            {
              label: $t('mittens.Hand_length_in_cm_'),
              value: projectStateStore.currentSettings.handLength,
              differsFromBaseline: differsFromBaseline.handLength,
            },
          ]"
          :design-details="[
            {
              label: $t('common.Ribbing_type'),
              value: projectStateStore.currentSettings.cuffPatternRepCount?.key,
            },
            {
              label: $t('mittens.Felting_density_'),
              value: projectStateStore.currentSettings.feltRatio?.key,
            },
          ]"
        ></pattern-information>
        <pattern-renderer
          :versions="versions"
          :disabled="buttonIsEnabled"
          :data="mittenData"
        ></pattern-renderer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";

import AdvancedSettings from "../../components/AdvancedSettings";
import InformationBubble from "./../../components/InformationBubble.vue";
import InputField from "../../components/general/InputField.vue";
import KnittingGauge from "../../components/KnittingGauge.vue";
import { composeRoute } from "../../lib/navigationUtils";
import PatternFormButton from "../../components/patterns/PatternFormButton.vue";
import PatternRenderer from "./../../components/patterns/PatternRenderer";
import PersonsSelectField from "./../../components/patterns/PersonsSelectField";
import { calculateMittens } from "./../../services/pattern";
import SelectField from "../../components/general/SelectField.vue";
import { measurementFieldsDisabled, hasEmptyValues } from "../../lib/patternFormUtils";
import { useLangStore } from "./../../stores/lang";
import RibbingToggleField from "../../components/patterns/RibbingToggleField.vue";
import PatternHeader from "../../components/pattern_utils/PatternHeader.vue";
import PatternInformation from "../../components/pattern_utils/PatternInformation.vue";

import { useProjectStateStore } from "../../stores/projectState";

const defaultSettings = {
  chosenPerson: null,
  chosenSize: null,
  stitchGauge: null,
  rowGauge: null,
  estimateRowGaugeEnabled: true,
  wristCircumf: null,
  cuffLength: null,
  handCircumf: null,
  handLength: null,
  feltRatio: null,
  cuffPatternRepCount: { value: 2 }, // setting the default to single ribbing for backwards compatability reasons
};

const loading = ref(false);

const langStore = useLangStore();
const locale = langStore.getLocale();
const router = useRouter();

const route = useRoute();
const patternType = route.meta.patternType;
const projectStateStore = useProjectStateStore(patternType, defaultSettings);

const latestVersion = "1.1.0";
const versions = {
  "1.0.0": "MittenPattern",
  "1.1.0": "MittenPattern_v1_1_0",
};

const { t } = useI18n();

const differsFromBaseline = ref({
  wristCircumf: false,
  cuffLength: false,
  handCircumf: false,
  handLength: false,
});

const hasCustomAdvancedSettings = ref(false);

watch(
  differsFromBaseline,
  (newValue) => {
    hasCustomAdvancedSettings.value = Object.values(newValue).some((value) => value);
  },
  { deep: true }
);

watch(
  () => projectStateStore.currentSettings,
  () => {
    projectStateStore.checkIfHasChanges();
  },
  { deep: true }
);

watch(
  () => projectStateStore.startingFromScratch,
  () => {
    if (projectStateStore.startingFromScratch) {
      showPattern.value = false;
      showSettings.value = true;
      currentStep.value = 0;
    }
  },
  { deep: true }
);

const selectSizesField = ref(null);
const sizes = ref(null);
let mittenData = ref(null);

const valuesDisabled = computed(() => measurementFieldsDisabled(projectStateStore.currentSettings));

const showPattern = ref(false);
const showSettings = ref(false);
const showPatternInformation = ref(false);

const currentStep = ref(0);

const isSubscribedOrOnTrial = ref(true);

const initializePattern = async (projectId = null) => {
  loading.value = true;
  try {
    await projectStateStore.initializeProjectState(latestVersion, projectId);
    if (projectId != null && projectId != route.params.project_id) {
      router.replace({
        ...route,
        params: { ...route.params, project_id: projectId },
      });
    }

    if (projectStateStore.storedSettings == null || !formIsValid.value) {
      showSettings.value = true;
      showPattern.value = false;
    } else {
      await calculatePattern(false, false);
    }
  } catch (error) {
    if (error.response?.status === 404 && route.params.project_id) {
      router.push(composeRoute(locale.value, "not-found"));
    } else {
      console.error("Error loading project:", error);
    }
  } finally {
    loading.value = false;
  }
};

watch(
  () => route.params.project_id,
  async (newProjectId) => {
    if (route.meta.patternType !== patternType || newProjectId === projectStateStore.projectId) {
      return;
    }

    await initializePattern(newProjectId || null);

    if (route.params.edit) {
      showSettings.value = true;
    }

    if (route.params.project_id && route.params.project_id != projectStateStore.projectId) {
      router.replace({
        ...route,
        params: { ...route.params, project_id: projectStateStore.projectId },
      });
    }
  },
  { immediate: true }
);

const cancelEdit = () => {
  projectStateStore.resetToStoredSettings();
  showSettings.value = false;
};

watch(
  () => projectStateStore.currentSettings.chosenPerson,
  () => {
    if (projectStateStore.currentSettings.chosenPerson == null) {
      return;
    }

    switch (projectStateStore.currentSettings.chosenPerson.value) {
      case "child":
        sizes.value = sizeBabyChildYouth.value;
        break;
      case "grown_up":
        sizes.value = sizeGrownUp.value;
        break;
      case "custom":
        sizes.value = null;
    }
  }
);

function onPersonSelected(newChosenPerson, oldChosenPerson) {
  if (newChosenPerson == oldChosenPerson) {
    return;
  }
  if (newChosenPerson == "custom") {
    projectStateStore.currentSettings.chosenSize = "custom";
  } else {
    projectStateStore.currentSettings.chosenSize = null;
  }
  projectStateStore.currentSettings.wristCircumf = null;
  projectStateStore.currentSettings.cuffLength = null;
  projectStateStore.currentSettings.handCircumf = null;
  projectStateStore.currentSettings.handLength = null;

  currentStep.value = Math.max(currentStep.value, 1);
}

function onSizeSelected(newChosenSize) {
  if (newChosenSize != null) {
    projectStateStore.currentSettings.wristCircumf = newChosenSize.sizeWristCircumf;
    projectStateStore.currentSettings.cuffLength = newChosenSize.sizeCuffLength;
    projectStateStore.currentSettings.handCircumf = newChosenSize.sizeHandCircumf;
    projectStateStore.currentSettings.handLength = newChosenSize.sizeHandLength;
  }

  currentStep.value = Math.max(currentStep.value, 2);
}

watch(
  () => projectStateStore.currentSettings.feltRatio,
  () => {
    currentStep.value = Math.max(currentStep.value, 3);
  }
);

const buttonIsEnabled = computed(() => {
  return formIsValid.value && projectStateStore.hasChanges;
});

const formIsValid = computed(() => {
  return !hasEmptyValues(projectStateStore.currentSettings);
});

const calculatePattern = async (upgrade, saveProject) => {
  try {
    loading.value = true;
    if (formIsValid.value) {
      if (upgrade) {
        projectStateStore.version = latestVersion;
      }
      await getMittenParameters();
      if (saveProject) {
        await projectStateStore.addOrUpdateProject();
      }
      showPattern.value = true;
      showSettings.value = false;
    } else {
      showSettings.value = true;
      showPattern.value = false;
    }
    window.scrollTo(0, 0);
  } catch (error) {
    if (error.response && error.response.status === 403) {
      isSubscribedOrOnTrial.value = false;
    } else {
      throw error;
    }
  } finally {
    loading.value = false;
  }
};

async function getMittenParameters() {
  const mittenParameters = await calculateMittens(
    projectStateStore.currentSettings.stitchGauge / 10,
    projectStateStore.currentSettings.rowGauge / 10,
    projectStateStore.currentSettings.wristCircumf,
    projectStateStore.currentSettings.cuffLength,
    projectStateStore.currentSettings.handCircumf,
    projectStateStore.currentSettings.handLength,
    projectStateStore.currentSettings.feltRatio.value,
    projectStateStore.currentSettings.cuffPatternRepCount.value,
    projectStateStore.version
  );

  mittenData.value = mittenParameters;
  projectStateStore.startingFromScratch = false;
  return mittenParameters;
}

const goToSubscription = async () => {
  await projectStateStore.addOrUpdateProject();
  router.push(composeRoute(locale.value, "subscription", "mittens"));
};

const sizeBabyChildYouth = computed(() => {
  return [
    {
      key: "0-3 " + t("common.months"),
      value: {
        sizeCuffLength: 3.5,
        sizeWristCircumf: 10.0,
        sizeHandCircumf: 12.0,
        sizeHandLength: 7.5,
      },
    },
    {
      key: "3-6 " + t("common.months"),
      value: {
        sizeCuffLength: 3.5,
        sizeWristCircumf: 10.5,
        sizeHandCircumf: 12.5,
        sizeHandLength: 8.0,
      },
    },
    {
      key: "6-9 " + t("common.months"),
      value: {
        sizeCuffLength: 3.5,
        sizeWristCircumf: 10.5,
        sizeHandCircumf: 12.5,
        sizeHandLength: 8.5,
      },
    },
    {
      key: "9-12 " + t("common.months"),
      value: {
        sizeCuffLength: 3.5,
        sizeWristCircumf: 11,
        sizeHandCircumf: 13,
        sizeHandLength: 9.0,
      },
    },
    {
      key: "1-2 " + t("common.years"),
      value: {
        sizeCuffLength: 4.0,
        sizeWristCircumf: 11.5,
        sizeHandCircumf: 13.5,
        sizeHandLength: 9.5,
      },
    },
    {
      key: "2-4 " + t("common.years"),
      value: {
        sizeCuffLength: 4.0,
        sizeWristCircumf: 12.0,
        sizeHandCircumf: 14.5,
        sizeHandLength: 10.5,
      },
    },
    {
      key: "4-6 " + t("common.years"),
      value: {
        sizeCuffLength: 4.0,
        sizeWristCircumf: 12.5,
        sizeHandCircumf: 15.0,
        sizeHandLength: 12.0,
      },
    },
    {
      key: "6-8 " + t("common.years"),
      value: {
        sizeCuffLength: 4.5,
        sizeWristCircumf: 13.0,
        sizeHandCircumf: 15.5,
        sizeHandLength: 12.5,
      },
    },
    {
      key: "8-10 " + t("common.years"),
      value: {
        sizeCuffLength: 4.5,
        sizeWristCircumf: 13.5,
        sizeHandCircumf: 16.0,
        sizeHandLength: 14.5,
      },
    },
    {
      key: "10-12 " + t("common.years"),
      value: {
        sizeCuffLength: 4.5,
        sizeWristCircumf: 14.5,
        sizeHandCircumf: 17.0,
        sizeHandLength: 16.0,
      },
    },
  ];
});

const sizeGrownUp = computed(() => {
  return [
    {
      key: "XS",
      value: {
        sizeCuffLength: 6.0,
        sizeWristCircumf: 14.5,
        sizeHandCircumf: 17.0,
        sizeHandLength: 17.0,
      },
    },
    {
      key: "S",
      value: {
        sizeCuffLength: 6.5,
        sizeWristCircumf: 15.0,
        sizeHandCircumf: 18.0,
        sizeHandLength: 18.0,
      },
    },
    {
      key: "M",
      value: {
        sizeCuffLength: 6.5,
        sizeWristCircumf: 16.5,
        sizeHandCircumf: 19.5,
        sizeHandLength: 19.0,
      },
    },
    {
      key: "L",
      value: {
        sizeCuffLength: 7.0,
        sizeWristCircumf: 18.0,
        sizeHandCircumf: 21.0,
        sizeHandLength: 20.0,
      },
    },
    {
      key: "XL",
      value: {
        sizeCuffLength: 7.0,
        sizeWristCircumf: 20.5,
        sizeHandCircumf: 24.0,
        sizeHandLength: 21.5,
      },
    },
  ];
});
const feltRatioOptions = computed(() => {
  return [
    { key: t("mittens.No"), value: 1 },
    { key: t("mittens.Felt_a_little"), value: 1.1 },
    { key: t("mittens.Felt_somewhat"), value: 1.2 },
    { key: t("mittens.Felt_a_lot"), value: 1.3 },
  ];
});
</script>

<style lang="scss" scoped>
@import "./../../../scss/form.scss";
</style>
