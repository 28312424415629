<template>
  <pattern-and-tool-placeholder
    :title="$t('common.Socks')"
    :description="$t('socks.pattern_description')"
    :first-bubble-text="$t('socks_placeholder.First_bubble')"
    :second-bubble-text="$t('socks_placeholder.Second_bubble')"
    :screenshot-path="getLocalizedWebpScreenshotPath"
    :screenshot-fallback-path="getLocalizedPngScreenshotPath"
    screenshot-alt="screenshot-socks"
    color="purple"
    bubble-color="yellow"
    :sign-in-text="$t('placeholders.pattern_sign_in_title')"
    :carousel-images="carouselImages"
  />
</template>

<script setup>
import PatternAndToolPlaceholder from "../../components/PatternAndToolPlaceholder.vue";
import { useI18n } from "vue-i18n";
import { getLocalizedImagePath } from "@/lib/imageUtils";
import socksMockupEnWebp from "@/assets/screenshot-socks-en.webp";
import socksMockupIsWebp from "@/assets/screenshot-socks-is.webp";
import socksMockupEnPng from "@/assets/screenshot-socks-en.png";
import socksMockupIsPng from "@/assets/screenshot-socks-is.png";
import socksMockupFiWebp from "@/assets/screenshot-socks-fi.webp";
import socksMockupFiPng from "@/assets/screenshot-socks-fi.png";

const { locale } = useI18n();

const imageMapWebp = {
  en: socksMockupEnWebp,
  is: socksMockupIsWebp,
  fi: socksMockupFiWebp,
};

const imageMapPng = {
  en: socksMockupEnPng,
  is: socksMockupIsPng,
  fi: socksMockupFiPng,
};

const getLocalizedWebpScreenshotPath = getLocalizedImagePath(imageMapWebp, locale);
const getLocalizedPngScreenshotPath = getLocalizedImagePath(imageMapPng, locale);

const carouselImages = [
  {
    source: "socks_baby_pink.jpg",
    alt: "pink baby sock",
  },
  {
    source: "set_baby_baige.jpg",
    alt: "set of baige socks and mittens",
  },
  {
    source: "set_baby_blue.jpg",
    alt: "set of blue socks and mittens",
  },
  {
    source: "set_baby_green.jpg",
    alt: "set of olive socks, white mittens and an olive beanie",
  },
];
</script>
