import mem from "mem";

import mp from "./../userTracking/mixpanel";
import axios from "../axios";
import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";
import { googleLogout } from "vue3-google-login";

import { getPartner, getSubscription } from "../services/access";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    authToken: useLocalStorage("access_token", null),
    refreshToken: useLocalStorage("refresh_token", null),
    termsVersion: "1.0.0",
    isSubscribed: false,
    isPartner: false,
  }),

  getters: {
    isPartnerOrSubscribed: (state) => state.isPartner || state.isSubscribed,
  },

  actions: {
    updateAuthToken(token) {
      this.authToken = token;
      localStorage.setItem("access_token", this.authToken);
    },
    updateRefreshToken(token) {
      this.refreshToken = token;
      localStorage.setItem("refresh_token", this.refreshToken);
    },
    setTokens(accessToken, refreshToken) {
      this.updateAuthToken(accessToken);
      this.updateRefreshToken(refreshToken);
    },
    async signIn(email, password) {
      const data = { username: email, password: password };

      const response = await axios.post("/auth/signin", new URLSearchParams(data));
      this.setTokens(response.data.access_token, response.data.refresh_token);

      const user = await this.getUser();
      mp.identify(user.id);

      return;
    },
    signOut() {
      this.authToken = null;
      this.refreshToken = null;
      mp.unidentify();
      googleLogout();
    },
    async signUp(
      email,
      password,
      passwordConfirm,
      locale,
      canSendEmails,
      acceptsTerms,
      canTrack = null
    ) {
      await axios.post("/auth/signup", {
        email: email,
        password: password,
        password_confirm: passwordConfirm,
        language: locale,
        can_send_emails: canSendEmails,
        accepts_terms: acceptsTerms,
        can_track: canTrack,
        terms_version: this.termsVersion,
      });
    },
    async googleAuth(credential) {
      const response = await axios.post("auth/google", {
        id_token: credential,
        terms_version: this.termsVersion,
      });

      this.setTokens(response.data.access_token, response.data.refresh_token);

      const user = await this.getUser();
      mp.identify(user.id);

      return;
    },
    async facebookAuth(firstName, lastName, email, signedRequest) {
      const response = await axios.post("auth/facebook", {
        first_name: firstName,
        last_name: lastName,
        email: email,
        signed_request: signedRequest,
        terms_version: this.termsVersion,
      });

      this.setTokens(response.data.access_token, response.data.refresh_token);

      const user = await this.getUser();
      mp.identify(user.id);

      return;
    },
    async refresh() {
      const response = await axios.get("/auth/refresh", {
        headers: { Authorization: `Bearer ${this.refreshToken}` },
      });
      this.updateAuthToken(response.data.access_token);
      return;
    },
    memoizedRefresh: mem(
      function () {
        return this.refresh();
      },
      { maxAge: 10000 }
    ),
    async getUser() {
      const response = await axios.get("/auth/me", {
        headers: { Authorization: `Bearer ${this.authToken}` },
      });
      const user = {
        firstName: response.data.first_name,
        lastName: response.data.last_name,
        email: response.data.email,
        id: response.data.id,
        verified: response.data.verified,
        createdAt: response.data.created_at,
        authMethods: response.data.auth_methods,
      };
      return user;
    },
    async verifyEmail(token) {
      await axios.get(`/auth/verify-email/${token}`);
    },
    async resendVerificationEmail(token, email, locale) {
      await axios.post(`/auth/resend-verification-email/`, {
        token: token,
        email: email,
        language: locale,
      });
    },
    async forgotPassword(email, locale) {
      await axios.post(`/auth/forgot-password`, {
        email: email,
        language: locale,
      });
    },
    async deleteAccount() {
      await axios.delete(`/users/me`, {
        headers: { Authorization: `Bearer ${this.authToken}` },
      });
      this.signOut();
    },
    async resetPassword(newPassword, confirmPassword, code = null) {
      const endpoint = code ? `/auth/reset-password/${code}` : `/auth/reset-password/`;
      const config = code ? {} : { headers: { Authorization: `Bearer ${this.authToken}` } };

      const requestData = {
        new_password: newPassword,
        password_confirm: confirmPassword,
      };

      await axios.post(endpoint, requestData, config);
    },
    async updateUser(firstName, lastName) {
      const body = {
        first_name: firstName,
        last_name: lastName,
      };
      await axios.put("users/me", body, {
        headers: { Authorization: `Bearer ${this.authToken}` },
      });
    },
    async loadSubscriptionInfo() {
      if (!this.authToken) {
        return;
      }

      try {
        const subscription = await getSubscription();
        this.isSubscribed = subscription.planId !== null;

        // Only check partner status if not subscribed
        if (!this.isSubscribed) {
          const partner = await getPartner();
          this.isPartner = partner !== null;
        } else {
          this.isPartner = false; // Reset partner status if subscribed
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          this.isSubscribed = false;
          this.isPartner = false;
        } else {
          throw error;
        }
      }
    },
  },
});
