import { createApp, h, resolveComponent } from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCog,
  faSyncAlt,
  faInfoCircle,
  faAngleDown,
  faPhone,
  faEnvelope,
  faTimes,
  faArrowLeft,
  faLock,
  faUnlock,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { createRouter, createWebHistory } from "vue-router";
import { createPinia } from "pinia";
import { useI18n } from "vue-i18n";
import { composeRoute } from "./lib/navigationUtils";

import App from "./App.vue";
import i18n from "./i18n";
import { useLangStore } from "./stores/lang";
import { useAuthStore } from "./stores/auth";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { createVueI18nAdapter } from "vuetify/locale/adapters/vue-i18n";

// User tracking
import VueGtag from "vue-gtag";
import Avo from "./userTracking/Avo";
import mp from "./userTracking/mixpanel";

// error monitoring
import * as Sentry from "@sentry/vue";

import vue3GoogleLogin from "vue3-google-login";

import { initFacebookSdk } from "./lib/initFacebookSdk.js";

Avo.initAvo(
  {
    env: process.env.VUE_APP_ENVIRONMENT,
    webDebugger: false,
  },
  null,
  null,
  mp
);

if (process.env.VUE_APP_ENVIRONMENT != "prod") {
  Paddle.Environment.set("sandbox");
}
Paddle.Setup({
  vendor: parseInt(process.env.VUE_APP_PADDLE_VENDOR_ID),
  eventCallback: function (data) {
    if (data.event == "Checkout.Close") {
      window.location.reload();
    }
  },
});

i18n.setup();

var VueScrollTo = require("vue-scrollto");

library.add(
  faCog,
  faSyncAlt,
  faInfoCircle,
  faAngleDown,
  faPhone,
  faEnvelope,
  faInstagram,
  faTimes,
  faArrowLeft,
  faLock,
  faUnlock,
  faGlobe
);

function requireAuth(to, from, next) {
  const authStore = useAuthStore();

  if (authStore.authToken) {
    next();
    return;
  }

  const pathName = to.meta?.placeholder ? to.meta.placeholder : "signin";
  next(composeRoute(to.params.locale, pathName, to.name));
}

const routes = [
  {
    path: "/",
    redirect: () => {
      return composeRoute(i18n.defaultLocale, "home");
    },
  },
  {
    name: "locale-root",
    path: "/:locale",
    component: {
      render(to) {
        return h(resolveComponent("router-view"));
      },
    },
    beforeEnter: (to, from, next) => {
      const langStore = useLangStore();
      let language = to.params.locale;
      const defaultLocale = langStore.getDefaultLocale;
      const supportedLocales = langStore.getSupportedLocales;
      if (!language) {
        language = defaultLocale;
      } else if (!Object.hasOwn(supportedLocales, language)) {
        language = defaultLocale;
        next(composeRoute(langStore.getDefaultLocale, "not-found"));
      }

      i18n.setLocale(language);

      next();
    },
    children: [
      {
        name: "home",
        path: "/:locale",
        component: require("./views/Index.vue").default,
        pathToRegexpOptions: {
          score: 1000,
        },
      },
      {
        name: "blog",
        path: "/:locale/blog",
        component: require("./views/Blog.vue").default,
      },
      {
        name: "blogPost",
        path: "/:locale/blog/:slug",
        component: require("./views/BlogPost.vue").default,
      },
      {
        name: "socks",
        path: "/:locale/patterns/socks/:project_id?",
        meta: {
          patternType: "sock",
          placeholder: "socks-placeholder",
        },
        component: require("./views/patterns/Socks.vue").default,
        beforeEnter: requireAuth,
      },
      {
        path: "/:locale/patterns/socks/placeholder",
        name: "socks-placeholder",
        component: require("./views/placeholders/SocksPlaceholder.vue").default,
      },
      {
        name: "mittens",
        path: "/:locale/patterns/mittens/:project_id?",
        meta: {
          patternType: "mitten",
          placeholder: "mittens-placeholder",
        },
        component: require("./views/patterns/Mittens.vue").default,
        beforeEnter: requireAuth,
      },
      {
        path: "/:locale/patterns/mittens/placeholder",
        name: "mittens-placeholder",
        component: require("./views/placeholders/MittensPlaceholder.vue").default,
      },
      {
        name: "beanie",
        path: "/:locale/patterns/beanie/:project_id?",
        meta: {
          patternType: "beanie",
          placeholder: "beanie-placeholder",
        },
        component: require("./views/patterns/Beanie.vue").default,
        beforeEnter: requireAuth,
      },
      {
        path: "/:locale/patterns/beanie/placeholder",
        name: "beanie-placeholder",
        component: require("./views/placeholders/BeaniePlaceholder.vue").default,
      },
      {
        name: "sweater",
        path: "/:locale/patterns/sweater/:project_id?",
        meta: {
          patternType: "sweater",
          placeholder: "sweater-placeholder",
        },
        component: require("./views/patterns/Sweater.vue").default,
        beforeEnter: requireAuth,
      },
      {
        path: "/:locale/patterns/sweater/placeholder",
        name: "sweater-placeholder",
        component: require("./views/placeholders/SweaterPlaceholder.vue").default,
      },
      {
        name: "gaugefromswatch",
        path: "/:locale/tools/gaugefromswatch",
        meta: {
          placeholder: "gaugefromswatch-placeholder",
        },
        component: require("./views/tools/GaugeFromSwatch.vue").default,
        beforeEnter: requireAuth,
      },
      {
        path: "/:locale/tools/gaugefromswatch/placeholder",
        name: "gaugefromswatch-placeholder",
        component: require("./views/placeholders/GaugeFromSwatchPlaceholder.vue").default,
      },
      {
        name: "knitcalculator",
        path: "/:locale/tools/knitcalculator",
        meta: {
          placeholder: "knitcalculator-placeholder",
        },
        component: require("./views/tools/KnitCalculator.vue").default,
        beforeEnter: requireAuth,
      },
      {
        path: "/:locale/tools/knitcalculator/placeholder",
        name: "knitcalculator-placeholder",
        component: require("./views/placeholders/KnitCalculatorPlaceholder.vue").default,
      },
      {
        name: "gaugeconverter",
        path: "/:locale/tools/gaugeconverter",
        meta: {
          placeholder: "gaugeconverter-placeholder",
        },
        component: require("./views/tools/GaugeConverter.vue").default,
        beforeEnter: requireAuth,
      },
      {
        path: "/:locale/tools/gaugeconverter/placeholder",
        name: "gaugeconverter-placeholder",
        component: require("./views/placeholders/GaugeConverterPlaceholder.vue").default,
      },
      {
        name: "projects",
        path: "/:locale/projects",
        component: require("./views/Projects.vue").default,
        beforeEnter: requireAuth,
      },
      {
        name: "profile",
        path: "/:locale/profile",
        component: require("./views/UserProfile.vue").default,
        beforeEnter: requireAuth,
      },
      {
        name: "subscription",
        path: "/:locale/subscription",
        component: require("./views/Subscription.vue").default,
        beforeEnter: requireAuth,
      },
      {
        name: "welcome",
        path: "/:locale/welcome",
        component: require("./views/Welcome.vue").default,
        beforeEnter: requireAuth,
      },
      { name: "signin", path: "/:locale/signin", component: require("./views/SignIn.vue").default },
      { name: "signup", path: "/:locale/signup", component: require("./views/SignUp.vue").default },
      {
        name: "verifyemail",
        path: "/:locale/verifyemail/:token",
        component: require("./views/VerifyEmail.vue").default,
      },
      {
        name: "resetpassword",
        path: "/:locale/reset-password/:code",
        component: require("./views/ResetPassword.vue").default,
      },
      {
        name: "forgotpassword",
        path: "/:locale/forgot-password",
        component: require("./views/ForgotPassword.vue").default,
      },
      {
        name: "termsandprivacy",
        path: "/:locale/termsandprivacy",
        component: require("./views/TermsAndPrivacy.vue").default,
      },
      {
        name: "not-found",
        path: "404",
        component: require("./views/NotFound.vue").default,
      },
      {
        path: ":pathMatch(.*)",
        redirect: (to) => {
          return composeRoute(to.params.locale, "not-found");
        },
      },
    ],
  },
];

initFacebookSdk(process.env.VUE_APP_FACEBOOK_APP_ID).then(startApp);

function startApp() {
  const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { top: 0 };
      }
    },
  });

  const pinia = createPinia();
  const app = createApp({
    render: () => h(App),
  });

  if (process.env.VUE_APP_ENVIRONMENT != "dev") {
    Sentry.init({
      app,
      dsn: "https://d9c7f00515b1c619e9abb728f41aceb8@o4506841077841920.ingest.sentry.io/4506841080856576",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/test.knittable\.com/, /^https:\/\/knittable\.com/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }

  const vuetify = createVuetify({
    components,
    directives,
    icons: {
      defaultSet: "mdi",
      aliases,
      sets: {
        mdi,
      },
    },
    locale: {
      adapter: createVueI18nAdapter({ i18n: i18n.vueI18n, useI18n }),
    },
  });

  app.use(i18n.vueI18n);
  app.use(router);
  app.use(VueScrollTo);
  app.use(pinia);
  if (process.env.VUE_APP_ENVIRONMENT == "prod") {
    app.use(VueGtag, {
      config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_TAG },
      params: {
        anonymize_ip: true,
      },
    });
  }
  app.use(vuetify);
  app.use(vue3GoogleLogin, {
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  });
  app.component("font-awesome-icon", FontAwesomeIcon);
  app.mount("#app");
}
