import axios from "../axios";

import { useAuthStore } from "../stores/auth";

const mapResponseData = (patternType, data) => {
  switch (patternType) {
    case "sock":
      return mapSockResponseData(data);
    case "mitten":
      return mapMittenResponseData(data);
    case "beanie":
      return mapBeanieResponseData(data);
    case "sweater":
      return mapSweaterResponseData(data);
  }
};

const mapSockResponseData = (data) => {
  return {
    stitchGauge: data.stitch_gauge,
    rowGauge: data.row_gauge,
    ankleCircumference: data.ankle_circumference,
    sockHeight: data.sock_height,
    footCircumference: data.foot_circumference,
    footLength: data.foot_length,
    castOnStitchCount: data.cast_on_stitch_count,
    footStitchCount: data.foot_stitch_count,
    heelFlapEdgeStitchCount: data.heel_flap_edge_stitch_count,
    heelFlapRowCount: data.heel_flap_row_count,
    cuffLength: data.cuff_length,
    anklePieceLength: data.ankle_piece_length,
    heelFlapMarginStitchCount: data.heel_flap_margin_stitch_count,
    tipLength: data.tip_length,
    tipMarginStitchCount: data.tip_margin_stitch_count,
    requiresSimplifiedDecrease: data.requires_simplified_decrease,
    finalRowStitchCount: data.final_row_stitch_count,
    heelFlapCenterStitchCount: data.heel_flap_center_stitch_count,
    cuffPatternRepCount: data.cuff_pattern_rep_count,
  };
};

const mapMittenResponseData = (data) => {
  return {
    stitchGauge: data.stitch_gauge,
    rowGauge: data.row_gauge,
    cuffLength: data.cuff_length,
    wristCircumference: data.wrist_circumference,
    handCircumference: data.hand_circumference,
    handLength: data.hand_length,
    feltRatio: data.felt_ratio,
    cuffPatternRepCount: data.cuff_pattern_rep_count,
    calculatedCuffLength: data.calculated_cuff_length,
    calculatedWristCircumf: data.calculated_wrist_circumf,
    castOnStitchCount: data.cast_on_stitch_count,
    calculatedHandCircumf: data.calculated_hand_circumf,
    handStitchCount: data.hand_stitch_count,
    calculatedHandLength: data.calculated_hand_length,
    beforeThumbLength: data.before_thumb_length,
    thumbMarginStitchCount: data.thumb_margin_stitch_count,
    thumbOpeningStitchCount: data.thumb_opening_stitch_count,
    tipMarginStitchCount: data.tip_margin_stitch_count,
    finalRowStitchCount: data.final_row_stitch_count,
    requiresSimplifiedDecrease: data.requires_simplified_decrease,
    skipStitchDecreaseRowCount: data.skip_stitch_decrease_row_count,
    tipLength: data.tip_length,
    thumbLength: data.thumb_length,
    initialThumbStitchCount: data.initial_thumb_stitch_count,
    stitchesKnitTogetherInSecondRow: data.stitches_knit_together_in_second_row,
    thumbStitchCount: data.thumb_stitch_count,
    thumbTipRowCount: data.thumb_tip_row_count,
    thumbTipLength: data.thumb_tip_length,
    thumbFinalRowStitchCount: data.thumb_final_row_stitch_count,
  };
};

const mapBeanieResponseData = (data) => {
  return {
    stitchGauge: data.stitch_gauge,
    rowGauge: data.row_gauge,
    headCircumf: data.head_circumference,
    foldLength: data.fold_length,
    decreaseType: data.decrease_type,
    cuffPatternRepCount: data.cuff_pattern_rep_count,
    stockinetteTop: data.stockinette_top,
    calculatedHeadCircumf: data.calculated_head_circumf,
    castOnStitchCount: data.cast_on_stitch_count,
    topPatternRepCount: data.top_pattern_rep_count,
    extraRoundedDecrease_needed: data.extra_rounded_decrease_needed,
    roundingRowCount: data.rounding_row_count,
    decreaseLineStitchCount: data.decrease_line_stitch_count,
    evenlyDecreasedStitchesCount: data.evenly_decreased_stitches_count,
    crossDecreaseCount: data.cross_decrease_count,
    crossDecreaseArray: data.cross_decrease_array,
    everyOtherRowDecreaseCount: data.every_other_row_decrease_count,
    everyRowDecreaseCount: data.every_row_decrease_count,
    evenDecreaseArray: data.even_decrease_array,
    lengthOfDecrease: data.length_of_decrease,
    lengthBeforeDecrease: data.length_before_decrease,
    straightCuffLength: data.straight_cuff_length,
    crossDecreaseSegmentsArray: data.cross_decrease_segments_array,
    crossDecreaseFinalRowStitchCount: data.cross_decrease_final_row_stitch_count,
    crossDecreaseIsEven: data.cross_decrease_is_even,
  };
};

const mapSweaterResponseData = (data) => {
  return {
    stitchGauge: data.stitch_gauge,
    rowGauge: data.row_gauge,
    chestCircumf: data.chest_circumf,
    easeBody: data.body_ease,
    wristCircumf: data.wrist_circumf,
    cuffLength: data.cuff_length,
    neckbandCircumf: data.neckband_circumf,
    neckbandLength: data.neckband_length,
    armCircumf: data.arm_circumf,
    armLength: data.arm_length,
    easeSleeve: data.sleeve_ease,
    patternRibStitchCount: data.pattern_rib_stitch_count,
    patternBodyStitchCount: data.pattern_body_stitch_count,
    raglanStitchCount: data.raglan_stitch_count,
    sweaterLength: data.sweater_length,
    hemLength: data.hem_length,
    applyNeckShaping: data.apply_neck_shaping,
    neckbandType: data.neckband_type,

    neckbandStitchCount: data.yoke.neckband_stitch_count,
    bodyStitchCount: data.yoke.body_stitch_count,
    sleeveStitchCount: data.yoke.sleeve_stitch_count,
    topBodyStitchCount: data.yoke.top_body_stitch_count,
    topSleeveStitchCount: data.yoke.top_sleeve_stitch_count,
    underarmStitchCount: data.yoke.underarm_stitch_count,
    raglanIncreasesSleeve: data.yoke.raglan_increases_sleeve,
    raglanIncreasesBody: data.yoke.raglan_increases_body,
    cuffStitchCount: data.sleeve.cuff_stitch_count,
    sleeveLength: data.sleeve.sleeve_length,
    sleeveDecreaseRoundCount: data.sleeve.decrease_round_count,
    sleeveAboveDecreasesLength: data.sleeve.above_decreases_length,
    sleeveBetweenDecreasesRoundCount: data.sleeve.between_decreases_round_count,
    sleeveAdditionalDecreaseCount: data.sleeve.additional_decrease_count,
    neckShapingRightTurns: data.yoke.neck_shaping_right_turns,
    neckShapingStepStitchCount: data.yoke.neck_shaping_step_stitch_count,

    warnings: data.warnings,
  };
};

export const calculate = async (patternType, projectId) => {
  const authStore = useAuthStore();
  const authToken = authStore.authToken;

  const response = await axios.get("/patterns/calculate", {
    params: { pattern_type: patternType, project_id: projectId },
    headers: { Authorization: `Bearer ${authToken}` },
  });

  return mapResponseData(patternType, response.data);
};

export const calculateSocks = async (
  stitchGauge,
  rowGauge,
  ankleCircumference,
  sockHeight,
  footCircumference,
  footLength,
  cuffPatternRepCount
) => {
  const authStore = useAuthStore();
  const authToken = authStore.authToken;

  const params = {
    stitch_gauge: stitchGauge,
    row_gauge: rowGauge,
    ankle_circumference: ankleCircumference,
    sock_height: sockHeight,
    foot_circumference: footCircumference,
    foot_length: footLength,
    cuff_pattern_rep_count: cuffPatternRepCount,
  };

  const response = await axios.get("/patterns/sock", {
    params: params,
    headers: { Authorization: `Bearer ${authToken}` },
  });

  return mapSockResponseData(response.data);
};

export const calculateMittens = async (
  stitchGauge,
  rowGauge,
  wristCircumf,
  cuffLength,
  handCircumf,
  handLength,
  feltRatio,
  cuffPatternRepCount,
  patternVersion
) => {
  const authStore = useAuthStore();
  const authToken = authStore.authToken;

  const params = {
    stitch_gauge: stitchGauge,
    row_gauge: rowGauge,
    wrist_circumference: wristCircumf,
    cuff_length: cuffLength,
    hand_circumference: handCircumf,
    hand_length: handLength,
    felt_ratio: feltRatio,
    cuff_pattern_rep_count: cuffPatternRepCount,
    version: patternVersion,
  };

  const response = await axios.get("/patterns/mitten", {
    params: params,
    headers: { Authorization: `Bearer ${authToken}` },
  });

  return mapMittenResponseData(response.data);
};

export const calculateBeanie = async (
  stitchGauge,
  rowGauge,
  headCircumf,
  foldLength,
  cuffPatternRepCount,
  stockinetteTop,
  decreaseType
) => {
  const authStore = useAuthStore();
  const authToken = authStore.authToken;

  const params = {
    stitch_gauge: stitchGauge,
    row_gauge: rowGauge,
    head_circumference: headCircumf,
    fold_length: foldLength,
    cuff_pattern_rep_count: cuffPatternRepCount,
    stockinette_top: stockinetteTop,
    decrease_type: decreaseType,
  };

  const response = await axios.get("/patterns/beanie", {
    params: params,
    headers: { Authorization: `Bearer ${authToken}` },
  });

  return mapBeanieResponseData(response.data);
};

export const calculateSweater = async (
  stitchGauge,
  rowGauge,
  chestCircumf,
  easeBody,
  wristCircumf,
  cuffLength,
  neckbandCircumf,
  neckbandLength,
  armCircumf,
  armLength,
  easeSleeve,
  patternRibStitchCount,
  raglanStitchCount,
  patternBodyStitchCount,
  yokeType,
  sweaterLength,
  hemLength,
  sleeveType,
  applyNeckShaping,
  neckbandType,
  patternVersion
) => {
  const authStore = useAuthStore();
  const authToken = authStore.authToken;

  const params = {
    stitch_gauge: stitchGauge,
    row_gauge: rowGauge,
    chest_circumf: chestCircumf,
    body_ease: easeBody,
    wrist_circumf: wristCircumf,
    cuff_length: cuffLength,
    neckband_circumf: neckbandCircumf,
    neckband_length: neckbandLength,
    arm_circumf: armCircumf,
    arm_length: armLength,
    sleeve_ease: easeSleeve,
    pattern_rib_stitch_count: patternRibStitchCount,
    raglan_stitch_count: raglanStitchCount,
    pattern_body_stitch_count: patternBodyStitchCount,
    yoke_type: yokeType,
    sweater_length: sweaterLength,
    hem_length: hemLength,
    sleeve_type: sleeveType,
    apply_neck_shaping: applyNeckShaping,
    neckband_type: neckbandType,
    version: patternVersion,
  };

  const response = await axios.get("/patterns/sweater", {
    params: params,
    headers: { Authorization: `Bearer ${authToken}` },
  });

  return mapSweaterResponseData(response.data);
};
