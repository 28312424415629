<template>
  <div
    :class="['app-input-field-container', { small: small, disabled: props.disabled, error: error }]"
  >
    <label
      >{{ label }}

      <v-icon v-if="props.icon" :icon="props.icon"
    /></label>
    <div class="input-wrapper">
      <input
        v-model="inputValue"
        :placeholder="placeholder"
        @blur="onBlur"
        @input="(event) => onInputChanged(event.target.valueAsNumber)"
        :type="props.type"
        :min="props.minValue"
        :max="props.maxValue"
        :step="props.step"
        :disabled="props.disabled"
        :class="{ highlight: highlight }"
      />
      <div v-if="isBaselineValueDifferent" class="icon-container">
        <v-icon
          class="refresh-icon"
          
          @click="resetToBaseline"
          icon="mdi-refresh"
        />
      </div>
    </div>
    <small class="description">{{ description }}</small>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch, computed } from "vue";

const props = defineProps({
  modelValue: {
    type: [Number, String],
  },
  label: {
    type: String,
  },
  icon: {
    type: String,
  },
  description: {
    type: String,
  },
  placeholder: {
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  step: {
    type: Number,
    default: 0.5,
  },
  allowZero: {
    type: Boolean,
    default: false,
  },
  minValue: {
    type: Number,
    default: 0,
  },
  maxValue: {
    type: Number,
    default: 1000,
  },
  baselineValue: {
    type: Number,
    default: null,
  },
  differsFromBaseline: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: "number",
  },
});
const emit = defineEmits(["update:modelValue", "input", "update:differsFromBaseline"]);

const inputValue = ref(props.modelValue);
const error = ref(false);

const highlight = ref(false);

watch(
  () => props.modelValue,
  () => {
    inputValue.value = props.modelValue;
    updateErrorValue();

    highlight.value = true;

    setTimeout(() => {
      highlight.value = false;
    }, 1000);
  },
  { deep: true }
);

const onBlur = () => {
  updateErrorValue();
};

const onInputChanged = () => {
  updateErrorValue();
  emit("update:modelValue", inputValue.value);
  emit("input");
};

const updateErrorValue = () => {
  if (!props.allowZero) {
    error.value = !inputValue.value;
  } else {
    error.value = !inputValue.value && (inputValue.value === "" || inputValue.value === null);
  }
};

const resetToBaseline = () => {
  inputValue.value = props.baselineValue;
  emit("update:modelValue", inputValue.value);
};

const isBaselineValueDifferent = computed(() => {
  const newValue = props.baselineValue !== null && inputValue.value !== props.baselineValue;
  emit("update:differsFromBaseline", newValue); 
  return newValue;
});
</script>

<style lang="scss" scoped>
@import "./../../../scss/styles.scss";

.app-input-field-container {
  text-align: left;
  gap: 0.5rem;
}

label {
  @include paragraphLargeBold;

  color: var(--knittable-lav-darker);
  display: block;
  i {
    font-size: 1.25rem;
  }
}
.description {
  @include paragraphMediumRegular;

  color: var(--knittable-lav-darker);
  display: block;
}

input {
  @include heading6;

  display: flex;
  height: 5.25rem;
  padding: 1.25rem 1.5rem;
  margin: 0.5rem 0;
  align-items: center;
  gap: 0.625rem;

  border-radius: 0.75rem;
  border: 2px solid var(--knittable-lav-light);
  background: var(--knittable-isa);

  color: var(--knittable-lav-darker);

  width: 100%;
}

input::placeholder {
  @include subtitleLarge;

  color: var(--knittable-lav-light);
}

.small input {
  @include subtitleSmall;

  padding: 1.125rem 1.5rem;
  height: 3.75rem;
}

.app-input-field-container:hover input {
  border-color: var(--knittable-lav);
}

.app-input-field-container input:focus-visible {
  outline: var(--knittable-lav);
  border-color: var(--knittable-lav);
  box-shadow: 0px 0px 24px 0px rgba(99, 63, 158, 0.25);
}

.app-input-field-container input:focus {
  outline: var(--knittable-lav);
  border-color: var(--knittable-lav);
  box-shadow: 0px 0px 24px 0px rgba(99, 63, 158, 0.25);
}

.small input::placeholder {
  @include subtitleSmall;
}

.disabled label {
  color: var(--knittable-lav-light);
}

.disabled .description {
  color: var(--knittable-lav-light);
}
.app-input-field-container.disabled input {
  color: var(--knittable-lav-light);
}
.app-input-field-container.disabled:hover input {
  border-color: var(--knittable-lav-light);
}

.error input {
  color: var(--knittable-begonia);
  background-color: var(--knittable-begonia-light);
  border-color: var(--knittable-begonia-medium);
}

.error label {
  color: var(--knittable-ama);
}

.error .description {
  color: var(--knittable-ama);
}

.app-input-field-container.error input::placeholder {
  color: var(--knittable-begonia-medium);
}
.app-input-field-container.error:hover input {
  border-color: var(--knittable-begonia);
}
.app-input-field-container.error.disabled:hover input {
  border-color: var(--knittable-begonia-medium);
}

.app-input-field-container.error input:focus,
.app-input-field-container.error input:focus-visible {
  outline: var(--knittable-ama);
  border-color: var(--knittable-ama);
  box-shadow: 0px 0px 24px 0px rgba(99, 63, 158, 0.25);
}

.app-input-field-container.error.disabled input {
  color: var(--knittable-begonia-medium);
}

.error input::placeholder {
  color: var(--knittable-begonia);
}

.icon-container {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--knittable-opal);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  right: -5px;
  pointer-events: auto;
}

.input-wrapper {
  position: relative;
}

.refresh-icon {
  font-size: 1rem;
  top: 40%;
  transform: translateY(-50%);
  pointer-events: auto;
  color: var(--knittable-isa);
}

.highlight {
  animation: fadeHighlight 1s ease-in-out;
}

@keyframes fadeHighlight {
  0% {
    background-color: var(--knittable-lav-lighter);
  }
  100% {
    background-color: transparent;
  }
}
</style>
