export const roundToBase = (value, base) => {
  return Math.round(value / base) * base;
};

export const isOdd = (value) => {
  return value % 1 == 0 && value % 2 != 0;
};

export const getStitchCount = (stitchGauge, breadth, patternStitchCount = 1) => {
  return roundToBase(stitchGauge * breadth, patternStitchCount);
};

export const getDimension = (entityGauge, entityCount) => {
  return entityCount / entityGauge;
};

export const getRowGaugeFromStitchGauge = (stitchGauge) => {
  return stitchGauge * 1.33;
};
