<template>
  <div
    class="content-screenshots"
    :style="{ background: backgroundColor }"
    :class="{ 'with-margin-top': marginTop }"
  >
    <div class="screenshots-grid">
      <div class="screenshots-left">
        <div class="phone-screenshots">
          <div class="phone-screenshot front">
            <picture v-if="phoneScreenshots.front.fallback_image">
              <source
                :srcset="require(`@/assets/${phoneScreenshots.front.image}`)"
                type="image/webp"
              />
              <img
                :src="require(`@/assets/${phoneScreenshots.front.fallback_image}`)"
                :alt="phoneScreenshots.front.alt"
              />
            </picture>
            <img
              v-else
              :src="require(`@/assets/${phoneScreenshots.front.image}`)"
              :alt="phoneScreenshots.front.alt"
            />
          </div>
          <div class="phone-screenshot back">
            <picture v-if="phoneScreenshots.back.fallback_image">
              <source
                :srcset="require(`@/assets/${phoneScreenshots.back.image}`)"
                type="image/webp"
              />
              <img
                :src="require(`@/assets/${phoneScreenshots.back.fallback_image}`)"
                :alt="phoneScreenshots.back.alt"
              />
            </picture>
            <img
              v-else
              :src="require(`@/assets/${phoneScreenshots.back.image}`)"
              :alt="phoneScreenshots.back.alt"
            />
          </div>
        </div>
      </div>
      <div class="screenshots-right">
        <h2>{{ title }}</h2>
        <p>{{ description }}</p>

        <div class="subscription-info" v-if="patternItems && patternItems.length">
          <p>{{ subscriptionText }}</p>
          <div class="pattern-grid" :style="{ '--columns-count': columnsCount }">
            <div v-for="(item, index) in patternItems" :key="index" class="pattern-item">
              <localized-link v-if="item.routeName" :to="item.routeName" class="pattern-link">
                <img :src="require(`@/assets/${item.image}`)" :alt="item.alt" />
                <span>{{ item.title }}</span>
              </localized-link>
              <template v-else>
                <img :src="require(`@/assets/${item.image}`)" :alt="item.alt" />
                <span>{{ item.title }}</span>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import LocalizedLink from "@/components/LocalizedLink.vue";
import { computed } from "vue";

const props = defineProps({
  backgroundColor: {
    type: String,
    default: "#a6d2ae",
  },
  phoneScreenshots: {
    type: Object,
    default: () => ({
      front: {
        image: "screenshot-socks.png",
        alt: "Socks pattern screen",
        fallback_image: null,
      },
      back: {
        image: "screenshot_create_user.png",
        alt: "Create user screen",
        fallback_image: null,
      },
    }),
  },
  title: {
    type: String,
    default: "Generate custom patterns with the Knittable pattern tools",
  },
  description: {
    type: String,
    default:
      "By creating a free user, you get access to all of our knitting tools that help you with everyday knitting math problems.",
  },
  subscriptionText: {
    type: String,
    default: "Subscribe (after 14 day free trial) to access our interactive patterns:",
  },
  patternItems: {
    type: Array,
    default: () => [
      {
        image: "sweater-pattern.jpg",
        alt: "Sweater pattern",
        title: "Sweater",
        routeName: "sweater",
      },
      {
        image: "beanie-pattern.jpg",
        alt: "Beanie pattern",
        title: "Beanie",
        routeName: "beanie",
      },
      {
        image: "mittens-pattern.jpg",
        alt: "Mittens pattern",
        title: "Mittens",
        routeName: "mittens",
      },
      {
        image: "socks-pattern.jpg",
        alt: "Socks pattern",
        title: "Socks",
        routeName: "socks",
      },
    ],
  },
  marginTop: {
    type: Boolean,
    default: false,
  },
});

const columnsCount = computed(() => {
  return props.patternItems.length > 0 ? props.patternItems.length : 1;
});
</script>

<style scoped lang="scss">
@import "../../../scss/styles.scss";

.content-screenshots {
  padding: 3rem;
  color: white;

  &.with-margin-top {
    margin-top: 3rem;
  }
}

.app-sm-and-down .content-screenshots {
  padding: 2rem 1rem;

  &.with-margin-top {
    margin-top: 2rem;
  }
}

.screenshots-grid {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 4rem;
  align-items: center;
}

.app-sm-and-down .screenshots-grid {
  grid-template-columns: 1fr;
  gap: 2rem;
  display: flex;
  flex-direction: column-reverse;
}

.screenshots-left {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 610px;
}

.app-sm-and-down .screenshots-left {
  height: 533px;
  margin-top: 1rem;
  width: 100%;
}

.phone-screenshots {
  position: relative;
  width: 400px;
  height: 100%;
}

.app-sm-and-down .phone-screenshots {
  width: 100%;
  position: relative;
  max-width: 400px;
  margin: 0 auto;
}

.phone-screenshot {
  position: absolute;
  width: 250px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  &.front {
    bottom: 0;
    right: -20px;
    z-index: 2;
  }

  &.back {
    top: 0;
    left: -20px;
    z-index: 1;
  }
}

.app-sm-and-down .phone-screenshot {
  position: absolute;
  width: 80%;
  max-width: 200px;

  &.front {
    bottom: 0px;
    right: 20px;
    z-index: 2;
  }

  &.back {
    top: 0px;
    left: 20px;
    z-index: 1;
  }
}

.screenshots-right {
  h2 {
    font-family: "Philosopher", sans-serif;
    font-size: 3.5rem;
    line-height: 110%;
    font-weight: 700;
    color: white;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  p {
    @include paragraphLargeRegular;
    margin-bottom: 2rem;
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}

.app-sm-and-down .screenshots-right {
  padding: 0 1rem;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  p {
    @include paragraphMediumRegular;
  }
}

.subscription-info {
  p {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }
}

.app-sm-and-down .subscription-info {
  p {
    font-size: 1.25rem;
  }
}

.pattern-grid {
  display: grid;
  grid-template-columns: repeat(var(--columns-count), 1fr);
  gap: 1.5rem;
  margin-top: 1.5rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.app-sm-and-down .pattern-grid {
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  max-width: 400px;
}

.pattern-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 150px;
  margin: 0 auto;

  img {
    width: 100%;
    border-radius: 2rem;
    overflow: hidden;
    aspect-ratio: 1;
    object-fit: cover;
    margin-bottom: 0.75rem;
  }

  span {
    font-family: "Philosopher", sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    color: white;
    text-align: center;
  }
}

.app-sm-and-down .pattern-item {
  max-width: 180px;

  span {
    font-size: 1.25rem;
  }
}

.pattern-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }
}
</style>
