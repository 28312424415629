<template>
  <h3 :class="[
    'app-front-page-section-title', 
    { 
      'app-front-page-section-title-default': !props.colorInverted && !props.color,
      'app-front-page-section-title-lav': props.colorInverted && !props.color,
      'app-front-page-section-title-custom': props.color
    }
  ]" :style="customColorStyle">
    {{ props.title }}
  </h3>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  title: {
    type: String,
  },
  colorInverted: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: null,
  },
});

const customColorStyle = computed(() => {
  if (props.color) {
    return { color: props.color };
  }
  return {};
});
</script>

<style lang="scss">
.app-front-page-section-title-default {
  color: var(--knittable-isa);
}

.app-front-page-section-title-lav {
  color: var(--knittable-lav-darker);
}

.app-front-page-section-title {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding-top: 6rem;
  margin-bottom: 6rem;
}

.app-sm-and-down .app-front-page-section-title {
  font-size: 3rem;

  padding-top: 5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 3.5rem;
}
</style>
