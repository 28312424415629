<template>
  <div class="pattern-tool-sheet app-front-page-sheet">
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="6" class="p0 left-col">
        <div class="pattern-tool-text-container">
          <div class="pattern-tool-super-title">
            {{ $t("get_started.super_title") }}
          </div>
          <div class="pattern-tool-title">
            <span class="pattern-tool-title-1"
              >{{ $t("get_started.create_your_own") }}
              <span class="pattern-tool-title-2">{{ $t("get_started.pattern") }}</span>
            </span>
          </div>
          <div class="pattern-tool-buttons">
            <main-button
              v-for="pattern in patterns"
              :key="pattern.routeName"
              @click="router.push(composeRoute(locale, pattern.routeName))"
              color="yellow"
              class="tool-button"
            >
              {{ pattern.title }}
              <span class="arrow-icon">
                <v-icon icon="mdi-arrow-right"></v-icon>
              </span>
            </main-button>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" class="p0 right-col">
        <div class="pattern-tool-image-container">
          <div class="pattern-tool-img-box">
            <div class="carousel-wrapper">
              <v-carousel
                v-model="currentSlide"
                hide-delimiters
                :show-arrows="false"
                height="100%"
                cycle
                :interval="5000"
              >
                <v-carousel-item v-for="(image, i) in carouselImages" :key="i">
                  <v-img :src="image.src" :alt="image.alt" cover height="100%" />
                </v-carousel-item>
              </v-carousel>
              <div class="carousel-dot-indicators">
                <span
                  v-for="(dot, index) in carouselImages.length"
                  :key="dot"
                  :class="['dot', index === currentSlide ? 'active' : '']"
                  @click="currentSlide = index"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import MainButton from "./general/MainButton.vue";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useLangStore } from "../stores/lang";
import { composeRoute } from "../lib/navigationUtils";
import { getImagePath } from "@/lib/imageUtils";

const { t } = useI18n();
const router = useRouter();
const langStore = useLangStore();
const locale = langStore.getLocale();

const patterns = computed(() => [
  { title: t("common.Sweater"), routeName: "sweater" },
  { title: t("common.Beanie"), routeName: "beanie" },
  { title: t("common.Mittens"), routeName: "mittens" },
  { title: t("common.Socks"), routeName: "socks" },
]);

const currentSlide = ref(0);

const carouselImages = [
  {
    src: getImagePath("hero-image.jpg"),
    alt: "Sweater",
  },
  {
    src: getImagePath("our_patterns_beanie.jpg"),
    alt: "Beanie",
  },
  {
    src: getImagePath("image-mittens.jpg"),
    alt: "Mittens",
  },
  {
    src: getImagePath("our_patterns_raglan_sweater.jpg"),
    alt: "Sweater",
  },
  {
    src: getImagePath("image-mittens.jpg"),
    alt: "Mittens",
  },
  {
    src: getImagePath("mittens_adult_white.jpg"),
    alt: "Mittens",
  },
  {
    src: getImagePath("set_kid_leonie.jpg"),
    alt: "Set",
  },
];
</script>

<style scoped lang="scss">
@import "../../scss/styles.scss";

.pattern-tool-sheet {
  padding-top: 8.5rem;
  background-color: white;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: radial-gradient(circle, rgba(147, 51, 234, 0.1) 1px, transparent 1px);
    background-size: 24px 24px;
    opacity: 0.5;
    pointer-events: none;
  }
}

.left-col {
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-col {
  margin: 0;
  padding: 0;
  padding-right: 2rem;
}

.pattern-tool-text-container {
  padding-left: 5.5rem;
  margin-right: 10%;
  text-align: left;
}

.pattern-tool-super-title {
  color: var(--knittable-lav-darker);
  @include subtitleLarge;
  font-weight: 600;
  letter-spacing: 0.04em;
  padding-bottom: 1rem;
}

.pattern-tool-title {
  @include heading2;
  line-height: 1.2;

  .pattern-tool-title-1 + br {
    display: block;
  }
}

.pattern-tool-title-1 {
  color: var(--knittable-lav-darker);
}

.pattern-tool-title-2,
.pattern-tool-title-3 {
  color: var(--knittable-begonia);
}

.pattern-tool-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-top: 2rem;
}

.tool-button {
  width: 100%;
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1.5rem;

  .arrow-icon {
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
  }
}

.pattern-tool-image-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.pattern-tool-img-box {
  border-radius: 40px;
  width: 100%;
  aspect-ratio: 1;
  overflow: hidden;
  margin-bottom: 0.5rem;
  margin-right: 2rem;
  margin-left: 2rem;
  max-height: 650px;
}

.carousel-wrapper {
  position: relative;
  height: 100%;
}

.carousel-dot-indicators {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
  z-index: 1;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }

  &.active {
    background-color: white;
  }
}

// Responsive styles
.app-sm-and-down {
  .right-col {
    padding: 0;
    margin: 0;
  }
  .pattern-tool-text-container {
    padding-left: 2.5rem;
    padding-bottom: 2rem;
    padding-top: 0.5rem;
  }

  .pattern-tool-super-title {
    font-size: 1.5rem;
  }

  .pattern-tool-title {
    font-size: 3rem;

    .pattern-tool-title-1 + br {
      display: none;
    }

    .pattern-tool-title-1::after {
      content: " ";
    }
  }

  .pattern-tool-image-container {
    padding-right: 0;
  }

  .pattern-tool-img-box {
    border-radius: 40px;
    margin-bottom: 20px;
    max-height: 450px;
  }
}
</style>
