<template>
  <div class="not-found-page">
    <h3 class="app-page-title">{{ $t("common.Page_not_found") }}</h3>
    <p>{{ $t("common.The_page_you_are_looking_for_does_not_exist") }}</p>
    <main-button @click="goHome" color="purple">
      {{ $t("common.Go_home") }}
    </main-button>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
import { composeRoute } from "../lib/navigationUtils";
import MainButton from "../components/general/MainButton.vue";
import { ref } from "vue";

const router = useRouter();
const route = useRoute();
const locale = ref(route.params.locale || "en");

const goHome = () => {
  router.push(composeRoute(locale.value, "home"));
};
</script>

<style scoped lang="scss">
@import "./../../scss/styles.scss";

.not-found-page {
  padding: 4rem 2rem;
  text-align: center;

  .app-page-title {
    color: var(--knittable-lav-darker);
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 2rem;
    color: var(--knittable-lav-darker);
  }
}
</style>
