<template>
  <pattern-and-tool-placeholder
    :title="$t('common.Mittens')"
    :description="$t('mittens.pattern_description')"
    :first-bubble-text="$t('mittens_placeholder.First_bubble')"
    :second-bubble-text="$t('mittens_placeholder.Second_bubble')"
    :third-bubble-text="$t('mittens_placeholder.Third_bubble')"
    :screenshot-path="getLocalizedWebpScreenshotPath"
    :screenshot-fallback-path="getLocalizedPngScreenshotPath"
    :detail-screenshot-path="getLocalizedDetailScreenshotPath"
    :screenshot-alt="getLocalizedPngScreenshotPath"
    :detail-screenshot-alt="getLocalizedDetailScreenshotPath"
    color="purple"
    bubble-color="yellow"
    :sign-in-text="$t('placeholders.pattern_sign_in_title')"
    :carousel-images="carouselImages"
  />
</template>

<script setup>
import PatternAndToolPlaceholder from "../../components/PatternAndToolPlaceholder.vue";
import { useI18n } from "vue-i18n";
import { getLocalizedImagePath } from "@/lib/imageUtils";
import mittensMockupEnWebp from "@/assets/screenshot-mittens-en.webp";
import mittensMockupIsWebp from "@/assets/screenshot-mittens-is.webp";
import mittensMockupEnPng from "@/assets/screenshot-mittens-en.png";
import mittensMockupIsPng from "@/assets/screenshot-mittens-is.png";
import mittensMockup2En from "@/assets/screenshot-mittens-2-en.png";
import mittensMockup2Is from "@/assets/screenshot-mittens-2-is.png";
import mittensMockup2Fi from "@/assets/screenshot-mittens-2-fi.png";
import mittensMockupFiWebp from "@/assets/screenshot-mittens-fi.webp";
import mittensMockupFiPng from "@/assets/screenshot-mittens-fi.png";

const { locale } = useI18n();

const imageMapWebp = {
  en: mittensMockupEnWebp,
  is: mittensMockupIsWebp,
  fi: mittensMockupFiWebp,
};

const imageMapPng = {
  en: mittensMockupEnPng,
  is: mittensMockupIsPng,
  fi: mittensMockupFiPng,
};

const detailImageMap = {
  en: mittensMockup2En,
  is: mittensMockup2Is,
  fi: mittensMockup2Fi,
};

const getLocalizedWebpScreenshotPath = getLocalizedImagePath(imageMapWebp, locale);
const getLocalizedPngScreenshotPath = getLocalizedImagePath(imageMapPng, locale);
const getLocalizedDetailScreenshotPath = getLocalizedImagePath(detailImageMap, locale);

const carouselImages = [
  {
    source: "mittens_child_pattern.jpg",
    alt: "child's mittens with a pattern",
  },
  {
    source: "mittens_adult_white.jpg",
    alt: "white mittens and a cappuchino",
  },
  {
    source: "mittens-pattern.jpg",
    alt: "felted pink mittens",
  },
  {
    source: "set_baby_green.jpg",
    alt: "a set of olive socks, white mittens and an olive beanie",
  },
];
</script>
