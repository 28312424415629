<template>
  <div class="our-patterns-block">
    <front-page-section-title
      :title="$t('our_patterns.title')"
      color="white"
    ></front-page-section-title>

    <v-row class="pattern-cards">
      <v-col
        v-for="pattern in patterns"
        :key="pattern.routeName"
        cols="12"
        :sm="12"
        :md="pattern.routeName === 'sweater' ? 12 : 4"
        class="pattern-col"
      >
        <div class="pattern-card" :class="{ featured: pattern.routeName === 'sweater' }">
          <div
            class="pattern-card-content"
            :class="{ 'featured-content': pattern.routeName === 'sweater' }"
          >
            <div
              class="pattern-image-wrapper"
              :class="{ 'featured-image': pattern.routeName === 'sweater' }"
            >
              <v-img
                :src="pattern.image"
                :alt="pattern.title"
                cover
                height="100%"
                class="pattern-image"
              />
            </div>

            <div class="pattern-content">
              <h3 class="pattern-name">
                {{ pattern.title }}
              </h3>

              <div v-if="pattern.routeName === 'sweater'" class="pattern-features">
                <p class="pattern-description">{{ $t("our_patterns.sweater_description") }}</p>
                <div class="features-list">
                  <ul>
                    <li>{{ $t("our_patterns.feature_any_yarn") }}</li>
                    <li>{{ $t("our_patterns.feature_sizes") }}</li>
                    <li>{{ $t("our_patterns.feature_ribbing") }}</li>
                    <li>{{ $t("our_patterns.feature_neck_shaping") }}</li>
                    <li>{{ $t("our_patterns.feature_repeat") }}</li>
                    <li>{{ $t("our_patterns.feature_raglans") }}</li>
                  </ul>
                </div>
              </div>

              <div v-else class="pattern-features-small">
                <ul>
                  <li>{{ $t("our_patterns.feature_any_yarn") }}</li>
                  <li>{{ $t("our_patterns.feature_sizes") }}</li>
                  <li>{{ $t("our_patterns.feature_ribbing") }}</li>
                  <li v-if="pattern.routeName === 'mittens'">
                    {{ $t("our_patterns.feature_felting") }}
                  </li>
                  <li v-if="pattern.routeName === 'beanie'">
                    {{ $t("our_patterns.feature_decreases") }}
                  </li>
                </ul>
              </div>

              <main-button
                @click="router.push(composeRoute(locale, pattern.routeName))"
                color="yellow"
                class="start-knitting-btn"
              >
                {{ $t("our_patterns.start_knitting") }}
              </main-button>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import FrontPageSectionTitle from "./FrontPageSectionTitle.vue";
import MainButton from "./general/MainButton.vue";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useLangStore } from "../stores/lang";
import { composeRoute } from "../lib/navigationUtils";
import { getImagePath } from "@/lib/imageUtils";

const { t } = useI18n();
const router = useRouter();
const langStore = useLangStore();
const locale = langStore.getLocale();

const patterns = computed(() => [
  {
    title: t("common.Sweater"),
    routeName: "sweater",
    image: getImagePath("our_patterns_raglan_sweater.jpg"),
  },
  {
    title: t("common.Socks"),
    routeName: "socks",
    image: getImagePath("socks_baby_pink.jpg"),
  },
  {
    title: t("common.Mittens"),
    routeName: "mittens",
    image: getImagePath("mittens-pattern.jpg"),
  },
  {
    title: t("common.Beanie"),
    routeName: "beanie",
    image: getImagePath("beanie-pattern.jpg"),
  },
]);
</script>

<style scoped lang="scss">
@import "../../scss/styles.scss";

.our-patterns-block {
  padding: 0rem 2rem;
  background-color: var(--knittable-opal-dark);
  padding-bottom: 6rem;
}

.pattern-cards {
  max-width: 1400px;
  margin: 0 auto;
}

.pattern-col {
  padding: 1rem;
}

.pattern-card {
  background: white;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &.featured {
    .pattern-card-content {
      display: flex;
      flex-direction: row;
      height: 100%;
    }

    .pattern-image-wrapper {
      flex: 1;
      height: auto;
    }

    .pattern-content {
      flex: 1;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    .pattern-name {
      margin-top: 2rem;
    }
  }
}

.pattern-card-content {
  height: 100%;
}

.pattern-image-wrapper {
  height: 300px;

  &.featured-image {
    height: 100%;
  }
}

.pattern-content {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 420px;
}

.pattern-name {
  @include heading6;
  color: var(--knittable-lav-darker);
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.pattern-description {
  @include paragraphLargeBold;
  color: black;
  margin-bottom: 2rem;
  text-align: left;
}

.pattern-features,
.pattern-features-small {
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
    color: var(--knittable-black);
    list-style-position: outside;
    list-style-image: url("./../assets/ellipse-lav.svg");
    padding-left: 1.25rem;
    margin-bottom: 1.5rem;

    li {
      padding-left: 0.75rem;
      font-size: 1rem;
      color: black;
      text-align: left;
    }
  }
}

.start-knitting-btn {
  position: relative;
  bottom: 0;
  width: 100%;
  margin-top: auto;
  margin-bottom: 1rem;
}

// Responsive styles
@media (max-width: 960px) {
  .pattern-col {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .pattern-card.featured {
    .pattern-card-content {
      flex-direction: column;
      height: fit-content;
    }
  }
}

.app-sm-and-down {
  .pattern-description {
    text-align: center;
  }
  .our-patterns-block {
    padding-right: 0rem;
    padding-left: 0rem;
  }

  .pattern-content {
    padding: 1.5rem;
  }
}
</style>
