<template>
  <pattern-and-tool-placeholder
    :title="$t('common.Knitting_gauge_from_swatch')"
    :description="$t('gauge_from_swatch.tool_description')"
    :first-bubble-text="$t('gauge_from_swatch_placeholder.First_bubble')"
    :second-bubble-text="$t('gauge_from_swatch_placeholder.Second_bubble')"
    :screenshot-path="getLocalizedWebpScreenshotPath"
    :screenshot-fallback-path="getLocalizedPngScreenshotPath"
    :screenshot-alt="getLocalizedPngScreenshotPath"
    color="yellow"
    bubble-color="green"
    :sign-in-text="$t('placeholders.tool_sign_in_title')"
  />
</template>

<script setup>
import PatternAndToolPlaceholder from "../../components/PatternAndToolPlaceholder.vue";
import { useI18n } from 'vue-i18n';
import { getLocalizedImagePath } from '@/lib/imageUtils';
import gaugeFromSwatchMockupEnWebp from '@/assets/gauge-from-swatch-mockup-en.webp';
import gaugeFromSwatchMockupIsWebp from '@/assets/gauge-from-swatch-mockup-is.webp';
import gaugeFromSwatchMockupEnPng from '@/assets/gauge-from-swatch-mockup-en.png';
import gaugeFromSwatchMockupIsPng from '@/assets/gauge-from-swatch-mockup-is.png';
import gaugeFromSwatchMockupFiWebp from '@/assets/gauge-from-swatch-mockup-fi.webp';
import gaugeFromSwatchMockupFiPng from '@/assets/gauge-from-swatch-mockup-fi.png';

const { locale } = useI18n();

const imageMapWebp = {
  en: gaugeFromSwatchMockupEnWebp,
  is: gaugeFromSwatchMockupIsWebp,
  fi: gaugeFromSwatchMockupFiWebp,
};

const imageMapPng = {
  en: gaugeFromSwatchMockupEnPng,
  is: gaugeFromSwatchMockupIsPng,
  fi: gaugeFromSwatchMockupFiPng,
};

const getLocalizedWebpScreenshotPath = getLocalizedImagePath(imageMapWebp, locale);
const getLocalizedPngScreenshotPath = getLocalizedImagePath(imageMapPng, locale);
</script>