<template>
  <v-dialog v-model="projectStateStore.isNewProjectModalOpen" width="500">
    <v-card class="app-modal-card">
      <div>
        <button class="close-button" @click="projectStateStore.isNewProjectModalOpen = false">
          <v-icon>mdi-close</v-icon>
        </button>
        <h6 class="app-modal-title">{{ $t("pattern_library.New_project") }}</h6>
        <form @submit.prevent="handleSubmit">
          <input-field
            v-model="projectName"
            :label="$t('pattern_library.Project_name')"
            :small="true"
            type="text"
          ></input-field>
          <checkbox-field class="checkbox-field" v-model="baseOnCurrentProject">{{
            $t("pattern_library.Base_on_current_project")
          }}</checkbox-field>
          <div class="button-container">
            <main-button color="purple" :disabled="!formIsValid">{{
              $t("pattern_library.Create")
            }}</main-button>
          </div>
        </form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import InputField from "./../general/InputField.vue";
import CheckboxField from "./../general/CheckboxField.vue";
import MainButton from "./../general/MainButton.vue";
import { useProjectStateStore } from "../../stores/projectState";

const route = useRoute();
const router = useRouter();
const patternType = route.meta.patternType;
const projectStateStore = useProjectStateStore(patternType);

const projectName = ref("");
const baseOnCurrentProject = ref(false);

const formIsValid = computed(() => {
  return projectName.value !== undefined && projectName.value !== "";
});

const handleSubmit = async () => {
  let projectId = await projectStateStore.performSaveProject(
    projectName.value,
    baseOnCurrentProject.value
  );
  projectName.value = "";
  baseOnCurrentProject.value = false;
  projectStateStore.isNewProjectModalOpen = false;
  router.replace({
    ...route,
    params: {
      ...route.params,
      project_id: projectId,
    },
  });
};
</script>

<style scoped lang="scss">
@import "./../../../scss/modal.scss";

.checkbox-field {
  margin-top: 10px;
}

.button-container button {
  margin-top: 20px;
  width: 100%;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  color: var(--knittable-lav-darker);
}
</style>
