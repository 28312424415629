import axios from "../axios";

import { useAuthStore } from "../stores/auth";

const mapProjectData = (project) => {
  return {
    settings: project.settings,
    bookmark: project.bookmark,
    patternVersion: project.pattern_version,
    createdAt: project.created_at,
    updatedAt: project.updated_at,
    id: project.id,
    name: project.name,
    patternType: project.pattern_type,
    lastActivityAt: project.updated_at || project.created_at,
  };
};

export const saveProject = async (patternType, patternVersion, formData, bookmark, name = null) => {
  const authStore = useAuthStore();
  const authToken = authStore.authToken;

  const params = {
    pattern_version: patternVersion,
    settings: formData,
    bookmark: bookmark,
    name: name,
  };

  const response = await axios.post(`projects/${patternType}`, params, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

  return mapProjectData(response.data);
};

export const saveBookmark = async (patternType, projectId, bookmark) => {
  const authStore = useAuthStore();
  const authToken = authStore.authToken;

  const params = {
    bookmark: bookmark,
  };

  const response = await axios.put(`projects/${patternType}/${projectId}/bookmark`, params, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

  return mapProjectData(response.data);
};

export const loadCurrentProject = async (patternType) => {
  try {
    const authStore = useAuthStore();
    const authToken = authStore.authToken;

    const response = await axios.get(`projects/${patternType}/current`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });

    const returnObject = mapProjectData(response.data);
    return returnObject;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    } else {
      throw error;
    }
  }
};

export const loadProject = async (patternType, projectId) => {
  const authStore = useAuthStore();
  const authToken = authStore.authToken;

  const response = await axios.get(`projects/${patternType}/${projectId}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
  return mapProjectData(response.data);
};

export const getProjects = async (patternType = null) => {
  const authStore = useAuthStore();
  const authToken = authStore.authToken;

  let response;
  if (patternType) {
    response = await axios.get(`projects/${patternType}`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  } else {
    response = await axios.get(`projects/all`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  }
  const projects = response.data.map(mapProjectData);
  return projects;
};

export const updateProject = async (patternType, projectId, projectSettings, version) => {
  const authStore = useAuthStore();
  const authToken = authStore.authToken;

  const params = {
    settings: projectSettings,
    pattern_version: version,
  };

  const response = await axios.put(`projects/${patternType}/${projectId}`, params, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

  return mapProjectData(response.data);
};

export const updateProjectName = async (patternType, projectId, name) => {
  const authStore = useAuthStore();
  const authToken = authStore.authToken;

  const params = {
    name: name,
  };

  const response = await axios.put(`projects/${patternType}/${projectId}`, params, {
    headers: { Authorization: `Bearer ${authToken}` },
  });

  return mapProjectData(response.data);
};

export const deleteProject = async (patternType, projectId) => {
  const authStore = useAuthStore();
  const authToken = authStore.authToken;

  await axios.delete(`projects/${patternType}/${projectId}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};
