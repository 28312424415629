<template>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <pattern-header
    v-model:show-information="showPatternInformation"
    v-model:show-settings="showSettings"
    :disabled="!showPattern"
    :default-title="$t('common.Sweater')"
  >
  </pattern-header>
  <v-container class="app-pattern-form-container" fluid>
    <v-row justify="center">
      <v-col
        v-if="showSettings"
        cols="12"
        sm="12"
        md="6"
        class="app-pattern-col app-pattern-form-col"
      >
        <!-- <div class="app-form-notification app-warning" v-if="showNotificationOnLoadedPattern">
          {{ $t("sweater.Pattern_updated_warning", { email: "hello@knittable.com" }) }}
        </div> -->
        <div class="app-form-description">{{ $t("sweater.pattern_description") }}</div>
        <v-form class="app-pattern-form" @submit.prevent="calculatePattern(false, true)">
          <div class="app-pattern-form-section">
            <header class="app-pattern-form-header">
              <div>
                {{ $t("common.Size") }}
              </div>
            </header>
            <tooltip
              position="bottom"
              width="medium"
              :colors-inverted="true"
              :is-enabled="
                projectStateStore.currentSettings.chosenFit != null ||
                projectStateStore.currentSettings.chosenLength != null ||
                projectStateStore.currentSettings.chosenSize != null
              "
            >
              <template v-slot:content>
                <persons-select-field
                  v-model="projectStateStore.currentSettings.chosenPerson"
                  :include-gendered-options="true"
                  @item-selected="(item, oldItem) => onPersonSelected(item.value, oldItem?.value)"
                >
                </persons-select-field>
              </template>
              <template v-slot:tooltip>{{ $t("sweater.Change_person_warning") }}</template>
            </tooltip>

            <select-field
              v-if="!projectStateStore.startingFromScratch | (currentStep >= 1)"
              v-model="projectStateStore.currentSettings.chosenSize"
              ref="selectSizesField"
              :label="t('common3.Size_')"
              :description="
                projectStateStore.currentSettings.chosenPerson?.value === 'child'
                  ? null
                  : t('sweater.Size_description')
              "
              :placeholder="t('common3.Choose_a_size')"
              :items="sizes"
              :small="true"
              @item-selected="(item) => onSizeSelected(item.value)"
            ></select-field>
          </div>

          <div
            class="app-pattern-form-section"
            v-if="!projectStateStore.startingFromScratch | (currentStep >= 2)"
          >
            <header class="app-pattern-form-header">
              <div>
                {{ $t("sweater.Shape") }}
              </div>
            </header>

            <select-field
              v-if="!projectStateStore.startingFromScratch | (currentStep >= 2)"
              v-model="projectStateStore.currentSettings.chosenFit"
              :label="t('sweater.Fit')"
              :placeholder="t('sweater.Choose_fit')"
              :items="fitOptions"
              :small="true"
              @item-selected="(item) => onFitSelected(item.value)"
            ></select-field>

            <select-field
              v-if="!projectStateStore.startingFromScratch | (currentStep >= 3)"
              v-model="projectStateStore.currentSettings.chosenLength"
              :label="t('sweater.Length')"
              :placeholder="t('sweater.Choose_length')"
              :items="lengthOptions"
              :small="true"
              @item-selected="(item) => onLengthSelected(item.value)"
            ></select-field>

            <advanced-settings
              v-if="!projectStateStore.startingFromScratch | (currentStep >= 4)"
              :title="t('common.Advanced_settings')"
              :disabled="!showAdvancedSettings"
              :is-modified="hasCustomAdvancedSettings"
            >
              <div class="app-pattern-form-measurment-cat-title">{{ $t("sweater.Neckband") }}</div>
              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="projectStateStore.currentSettings.neckbandCircumf"
                  :label="t('sweater.Neckband_circumf_in_cm_')"
                  :description="t('sweater.Neckband_circumf_description')"
                  :placeholder="t('sweater.Type_in_neckband_circumf')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="
                    projectStateStore.currentSettings.chosenSize?.value.sizeNeckbandCircumf
                  "
                  v-model:differsFromBaseline="differsFromBaseline.neckbandCircumf"
                ></input-field>
                <v-spacer></v-spacer>
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="projectStateStore.currentSettings.neckbandLength"
                  :label="t('sweater.Neckband_length_in_cm_')"
                  :description="t('sweater.Neckband_length_description')"
                  :placeholder="t('sweater.Type_in_neckband_length')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="
                    projectStateStore.currentSettings.chosenSize?.value.sizeNeckbandLength
                  "
                  v-model:differsFromBaseline="differsFromBaseline.neckbandLength"
                ></input-field>
              </div>

              <div class="app-pattern-form-measurment-cat-title">{{ $t("sweater.Body") }}</div>
              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="projectStateStore.currentSettings.chestCircumf"
                  :label="t('sweater.Chest_circumf')"
                  :description="t('sweater.Chest_circumf_description')"
                  :placeholder="t('sweater.Type_in_chest_circumf')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="
                    projectStateStore.currentSettings.chosenSize?.value.sizeChestCircumf
                  "
                  v-model:differsFromBaseline="differsFromBaseline.chestCircumf"
                ></input-field>
                <v-spacer></v-spacer>
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="projectStateStore.currentSettings.easeBody"
                  :label="t('sweater.Body_ease')"
                  :description="t('sweater.Body_ease_description')"
                  :placeholder="t('sweater.Type_in_body_ease')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :allow-zero="true"
                  :min-value="-5"
                  :max-value="40"
                  :baseline-value="projectStateStore.currentSettings.chosenFit?.value.easeBody"
                  v-model:differsFromBaseline="differsFromBaseline.easeBody"
                ></input-field>
              </div>

              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="projectStateStore.currentSettings.finishedChestCircumf"
                  :label="t('sweater.Finished_chest_circumf')"
                  :description="t('sweater.Finished_chest_circumf_description')"
                  :disabled="true"
                  :small="true"
                ></input-field>
              </div>

              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="projectStateStore.currentSettings.hemLength"
                  :label="t('sweater.Hem_length_in_cm_')"
                  :description="t('sweater.Hem_length_description')"
                  :placeholder="t('sweater.Type_in_hem_length')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="
                    projectStateStore.currentSettings.chosenSize?.value.sizeHemLength
                  "
                  v-model:differsFromBaseline="differsFromBaseline.hemLength"
                ></input-field>
                <v-spacer></v-spacer>
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="projectStateStore.currentSettings.sweaterLength"
                  :label="t('sweater.Sweater_length_in_cm_')"
                  :description="t('sweater.Sweater_length_description')"
                  :placeholder="t('sweater.Type_in_sweater_length')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="calculatedChosenSweaterLength"
                  v-model:differsFromBaseline="differsFromBaseline.sweaterLength"
                ></input-field>
              </div>

              <div class="app-pattern-form-measurment-cat-title">{{ $t("sweater.Sleeves") }}</div>
              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="projectStateStore.currentSettings.armCircumf"
                  :label="t('sweater.Arm_circumf')"
                  :description="t('sweater.Arm_circumf_description')"
                  :placeholder="t('sweater.Type_in_arm_circumf')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="
                    projectStateStore.currentSettings.chosenSize?.value.sizeArmCircumf
                  "
                  v-model:differsFromBaseline="differsFromBaseline.armCircumf"
                ></input-field>
                <v-spacer></v-spacer>
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="projectStateStore.currentSettings.easeSleeve"
                  :label="t('sweater.Sleeve_ease')"
                  :description="t('sweater.Sleeve_ease_description')"
                  :placeholder="t('sweater.Type_in_sleeve_ease')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :allow-zero="true"
                  :min-value="-5"
                  :max-value="40"
                  :baseline-value="projectStateStore.currentSettings.chosenFit?.value.easeSleeve"
                  v-model:differsFromBaseline="differsFromBaseline.easeSleeve"
                ></input-field>
              </div>

              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="projectStateStore.currentSettings.sleeveCircumf"
                  :label="t('sweater.Sleeve_circumf')"
                  :description="t('sweater.Sleeve_circumf_description')"
                  :placeholder="t('sweater.Type_in_sleeve_circumf')"
                  :disabled="true"
                  :small="true"
                ></input-field>
              </div>

              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="projectStateStore.currentSettings.armLength"
                  :label="t('sweater.Arm_length_in_cm_')"
                  :description="t('sweater.Arm_length_description')"
                  :placeholder="t('sweater.Type_in_arm_length')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="
                    projectStateStore.currentSettings.chosenSize?.value.sizeArmLength
                  "
                  v-model:differsFromBaseline="differsFromBaseline.armLength"
                ></input-field>
              </div>

              <div class="app-pattern-form-side-by-side-container">
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="projectStateStore.currentSettings.wristCircumf"
                  :label="t('sweater.Wrist_circumf')"
                  :description="t('sweater.Wrist_circumf_description')"
                  :placeholder="t('sweater.Type_in_wrist_circumf')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :baseline-value="
                    projectStateStore.currentSettings.chosenSize?.value.sizeWristCircumf
                  "
                  v-model:differsFromBaseline="differsFromBaseline.wristCircumf"
                ></input-field>
                <v-spacer></v-spacer>
                <input-field
                  class="app-pattern-form-input-field"
                  v-model="projectStateStore.currentSettings.cuffLength"
                  :label="t('sweater.Cuff_length_in_cm_')"
                  :description="t('sweater.Cuff_length_description')"
                  :placeholder="t('sweater.Type_in_cuff_length')"
                  :disabled="valuesDisabled"
                  :small="true"
                  :step="1"
                  :baseline-value="
                    projectStateStore.currentSettings.chosenSize?.value.sizeCuffLength
                  "
                  v-model:differsFromBaseline="differsFromBaseline.cuffLength"
                ></input-field>
              </div>
            </advanced-settings>
          </div>

          <div
            class="app-pattern-form-section"
            v-if="!projectStateStore.startingFromScratch | (currentStep >= 4)"
          >
            <header class="app-pattern-form-header">
              <div>
                {{ $t("common4.Design") }}
              </div>
            </header>
            <toggle-field
              :label="t('sweater.Neckband_type')"
              :options="neckbandTypeOptions"
              v-model="projectStateStore.currentSettings.neckbandType"
              v-if="!projectStateStore.startingFromScratch | (currentStep >= 4)"
            ></toggle-field>

            <ribbing-toggle-field
              v-model="projectStateStore.currentSettings.patternRibStitchCount"
              v-if="!projectStateStore.startingFromScratch | (currentStep >= 4)"
            >
            </ribbing-toggle-field>
            <div class="app-pattern-form-side-by-side-container">
              <input-field
                class="app-pattern-form-input-field"
                v-model="projectStateStore.currentSettings.raglanStitchCount"
                :label="t('sweater.Raglan_stitch_count')"
                :description="t('sweater.Raglan_stitch_count_description')"
                :placeholder="t('sweater.Type_in_raglan_stitch_count')"
                :small="true"
                :step="1"
              ></input-field>
              <v-spacer></v-spacer>
              <input-field
                class="app-pattern-form-input-field"
                v-model="projectStateStore.currentSettings.patternBodyStitchCount"
                :label="t('sweater.Body_pattern_repeat')"
                :description="t('sweater.Body_pattern_repeat_description')"
                :placeholder="t('sweater.Type_in_body_pattern_repeat')"
                :step="1"
                :small="true"
                :min-value="1"
              ></input-field>
            </div>

            <checkbox-field v-model="projectStateStore.currentSettings.applyNeckShaping">{{
              $t("sweater.Shape_neck_with_short_rows")
            }}</checkbox-field>
          </div>

          <div
            class="app-pattern-form-section"
            v-if="!projectStateStore.startingFromScratch | (currentStep >= 5)"
          >
            <knitting-gauge
              v-model:row-gauge="projectStateStore.currentSettings.rowGauge"
              v-model:stitch-gauge="projectStateStore.currentSettings.stitchGauge"
              v-model:estimate-row-gauge-enabled="
                projectStateStore.currentSettings.estimateRowGaugeEnabled
              "
            ></knitting-gauge>
          </div>
          <div v-if="isSubscribedOrOnTrial">
            <div v-if="projectStateStore.newVersionAvailable(latestVersion)">
              <div class="new-version-available-notification">
                {{ $t("pattern_form.A_new_version_is_available_") }}
              </div>
              <pattern-form-button
                :label="$t('pattern_form.Upgrade_and_calculate')"
                :disabled="!buttonIsEnabled"
                @click="calculatePattern(true, true)"
              ></pattern-form-button>
            </div>
            <pattern-form-button
              :label="$t('common.Calculate_pattern')"
              :disabled="!buttonIsEnabled"
            >
            </pattern-form-button>
          </div>
        </v-form>
        <pattern-form-button
          v-if="!isSubscribedOrOnTrial"
          :label="$t('common7.Subscribe')"
          @click="goToSubscription"
          color="red"
        >
        </pattern-form-button>
        <pattern-form-button
          v-if="!projectStateStore.startingFromScratch"
          :label="$t('common.Cancel')"
          color="grey"
          @click="cancelEdit"
        >
        </pattern-form-button>
      </v-col>
      <v-col cols="12" sm="12" md="6" class="app-pattern-col" v-if="errorMessage">
        <div cols="12" sm="12" md="6" class="app-form-notification app-error">
          {{ errorMessage }}
        </div>
      </v-col>
      <v-col
        id="pattern-container"
        cols="12"
        sm="12"
        md="6"
        class="app-pattern-col"
        v-else-if="showPattern"
      >
        <pattern-notifications :warnings="sweaterData.warnings"></pattern-notifications>
        <pattern-information
          v-model:show-information="showPatternInformation"
          :disabled="buttonIsEnabled"
          :pattern-type="patternType"
          :description="t('sweater.pattern_description')"
          :stitch-gauge="projectStateStore.currentSettings.stitchGauge"
          :row-gauge="projectStateStore.currentSettings.rowGauge"
          :properties="[
            {
              label: $t('common.Size_category'),
              value: projectStateStore.currentSettings.chosenPerson?.key,
            },
            { label: $t('common.Size'), value: projectStateStore.currentSettings.chosenSize?.key },
            { label: $t('sweater.Fit'), value: projectStateStore.currentSettings.chosenFit?.key },
            {
              label: $t('sweater.Length'),
              value: projectStateStore.currentSettings.chosenLength?.key,
            },
          ]"
          :measurements="[
            {
              label: $t('sweater.Neckband_circumf_in_cm_'),
              value: projectStateStore.currentSettings.neckbandCircumf,
              differsFromBaseline: differsFromBaseline.neckbandCircumf,
            },
            {
              label: $t('sweater.Neckband_length_in_cm_'),
              value: projectStateStore.currentSettings.neckbandLength,
              differsFromBaseline: differsFromBaseline.neckbandLength,
            },
            {
              label: $t('sweater.Chest_circumf'),
              value: projectStateStore.currentSettings.chestCircumf,
              differsFromBaseline: differsFromBaseline.chestCircumf,
            },
            {
              label: $t('sweater.Body_ease'),
              value: projectStateStore.currentSettings.easeBody,
              differsFromBaseline: differsFromBaseline.easeBody,
            },
            {
              label: $t('sweater.Hem_length_in_cm_'),
              value: projectStateStore.currentSettings.hemLength,
              differsFromBaseline: differsFromBaseline.hemLength,
            },
            {
              label: $t('sweater.Sweater_length_in_cm_'),
              value: projectStateStore.currentSettings.sweaterLength,
              differsFromBaseline: differsFromBaseline.sweaterLength,
            },
            {
              label: $t('sweater.Arm_circumf'),
              value: projectStateStore.currentSettings.armCircumf,
              differsFromBaseline: differsFromBaseline.armCircumf,
            },
            {
              label: $t('sweater.Sleeve_ease'),
              value: projectStateStore.currentSettings.easeSleeve,
              differsFromBaseline: differsFromBaseline.easeSleeve,
            },
            {
              label: $t('sweater.Arm_length_in_cm_'),
              value: projectStateStore.currentSettings.armLength,
              differsFromBaseline: differsFromBaseline.armLength,
            },
            {
              label: $t('sweater.Wrist_circumf'),
              value: projectStateStore.currentSettings.wristCircumf,
              differsFromBaseline: differsFromBaseline.wristCircumf,
            },
            {
              label: $t('sweater.Cuff_length_in_cm_'),
              value: projectStateStore.currentSettings.cuffLength,
              differsFromBaseline: differsFromBaseline.cuffLength,
            },
          ]"
          :design-details="[
            {
              label: $t('sweater.Neckband_type'),
              value: projectStateStore.currentSettings.neckbandType?.key,
            },
            {
              label: $t('common.Ribbing_type'),
              value: projectStateStore.currentSettings.patternRibStitchCount?.key,
            },
            {
              label: $t('sweater.Raglan_stitch_count'),
              value: projectStateStore.currentSettings.raglanStitchCount,
            },
            {
              label: $t('sweater.Body_pattern_repeat'),
              value: projectStateStore.currentSettings.patternBodyStitchCount,
            },
          ]"
        ></pattern-information>
        <pattern-renderer
          :versions="versions"
          :disabled="buttonIsEnabled"
          :data="sweaterData"
        ></pattern-renderer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";

import AdvancedSettings from "../../components/AdvancedSettings";
import CheckboxField from "../../components/general/CheckboxField.vue";
import InputField from "../../components/general/InputField.vue";
import KnittingGauge from "../../components/KnittingGauge.vue";
import { composeRoute } from "../../lib/navigationUtils";
import PatternFormButton from "../../components/patterns/PatternFormButton.vue";
import PatternRenderer from "./../../components/patterns/PatternRenderer";
import PersonsSelectField from "./../../components/patterns/PersonsSelectField";
import { calculateSweater } from "./../../services/pattern";
import SelectField from "../../components/general/SelectField.vue";
import { measurementFieldsDisabled, hasEmptyValues } from "../../lib/patternFormUtils";
import { useLangStore } from "./../../stores/lang";
import { roundToBase } from "./../../lib/calculationUtils.js";
import RibbingToggleField from "../../components/patterns/RibbingToggleField.vue";
import ToggleField from "../../components/general/ToggleField.vue";
import PatternInformation from "../../components/pattern_utils/PatternInformation.vue";
import Tooltip from "../../components/Tooltip.vue";
import PatternHeader from "../../components/pattern_utils/PatternHeader.vue";
import PatternNotifications from "../../components/pattern_utils/PatternNotifications.vue";

import { useProjectStateStore } from "../../stores/projectState";

const defaultSettings = {
  chosenPerson: null,
  chosenSize: null,
  chosenFit: null,
  chosenLength: null,
  stitchGauge: null,
  rowGauge: null,
  estimateRowGaugeEnabled: true,
  finishedChestCircumf: null,
  chestCircumf: null,
  easeBody: null,
  cuffLength: null,
  wristCircumf: null,
  neckbandCircumf: null,
  neckbandLength: null,
  sleeveCircumf: null,
  armCircumf: null,
  easeSleeve: null,
  armLength: null,
  hemLength: null,
  patternRibStitchCount: null,
  raglanStitchCount: null,
  patternBodyStitchCount: 1,
  yokeType: "raglan",
  sweaterLength: null,
  sleeveType: "regular",
  applyNeckShaping: true,
  neckbandType: null,
};

const loading = ref(false);

const langStore = useLangStore();
const locale = langStore.getLocale();
const router = useRouter();

const route = useRoute();
const patternType = route.meta.patternType;
const projectStateStore = useProjectStateStore(patternType, defaultSettings);

const latestVersion = "1.0.0";
const versions = {
  "0.1.0": "SweaterPattern_v0_1_0",
  "0.1.1": "SweaterPattern_v0_1_1",
  "1.0.0": "SweaterPattern",
};

const { t } = useI18n();

const differsFromBaseline = ref({
  neckbandCircumf: false,
  neckbandLength: false,
  chestCircumf: false,
  easeBody: false,
  hemLength: false,
  sweaterLength: false,
  armCircumf: false,
  easeSleeve: false,
  armLength: false,
  wristCircumf: false,
  cuffLength: false,
});

const hasCustomAdvancedSettings = ref(false);

watch(
  differsFromBaseline,
  (newValue) => {
    hasCustomAdvancedSettings.value = Object.values(newValue).some((value) => value);
  },
  { deep: true }
);

watch(
  () => projectStateStore.currentSettings,
  () => {
    projectStateStore.checkIfHasChanges();
  },
  { deep: true }
);

watch(
  () => projectStateStore.startingFromScratch,
  () => {
    if (projectStateStore.startingFromScratch) {
      showPattern.value = false;
      showSettings.value = true;
      currentStep.value = 0;
    }
  },
  { deep: true }
);

const selectSizesField = ref(null);
const sizes = ref(null);
let sweaterData = ref(null);

const valuesDisabled = computed(() => measurementFieldsDisabled(projectStateStore.currentSettings));

const showPattern = ref(false);
const showSettings = ref(false);
const showPatternInformation = ref(false);

const currentStep = ref(0);

const isSubscribedOrOnTrial = ref(true);

const errorMessage = ref(null);

const calculatedChosenSweaterLength = ref(null);

const initializePattern = async (projectId = null) => {
  loading.value = true;
  try {
    await projectStateStore.initializeProjectState(latestVersion, projectId);
    if (projectId != null && projectId != route.params.project_id) {
      router.replace({
        ...route,
        params: { ...route.params, project_id: projectId },
      });
    }

    if (projectStateStore.storedSettings == null || !formIsValid.value) {
      showSettings.value = true;
      showPattern.value = false;
    } else {
      await calculatePattern(false, false);
    }
  } catch (error) {
    if (error.response?.status === 404 && route.params.project_id) {
      router.push(composeRoute(locale.value, "not-found"));
    } else {
      console.error("Error loading project:", error);
    }
  } finally {
    loading.value = false;
  }
};

watch(
  () => route.params.project_id,
  async (newProjectId) => {
    if (route.meta.patternType !== patternType || newProjectId === projectStateStore.projectId) {
      return;
    }

    await initializePattern(newProjectId || null);

    if (route.params.edit) {
      showSettings.value = true;
    }

    if (route.params.project_id && route.params.project_id != projectStateStore.projectId) {
      router.replace({
        ...route,
        params: { ...route.params, project_id: projectStateStore.projectId },
      });
    }
  },
  { immediate: true }
);

const cancelEdit = () => {
  projectStateStore.resetToStoredSettings();
  showSettings.value = false;
};

watch(
  () => projectStateStore.currentSettings.chosenPerson,
  () => {
    if (projectStateStore.currentSettings.chosenPerson == null) {
      return;
    }

    switch (projectStateStore.currentSettings.chosenPerson.value) {
      case "child":
        sizes.value = sizeBabyChildYouth.value;
        break;
      // case "man":
      //   sizes.value = sizeMan.value;
      //   break;
      case "woman":
        sizes.value = sizeWomen.value;
        break;
      // case "unisex":
      //   sizes.value = sizeUnisex.value;
      //   break;
      case "custom":
        sizes.value = null;
    }
  }
);

function onPersonSelected(newChosenPerson, oldChosenPerson) {
  if (newChosenPerson == oldChosenPerson) {
    return;
  }

  if (newChosenPerson == "custom") {
    projectStateStore.currentSettings.chosenSize = "custom";
  } else {
    projectStateStore.currentSettings.chosenSize = null;
  }

  resetSizes();
  projectStateStore.currentSettings.chosenFit = null;
  projectStateStore.currentSettings.chosenLength = null;

  currentStep.value = Math.max(currentStep.value, 1);
}

const resetSizes = () => {
  projectStateStore.currentSettings.neckbandCircumf = null;
  projectStateStore.currentSettings.chestCircumf = null;
  projectStateStore.currentSettings.armCircumf = null;
  projectStateStore.currentSettings.neckbandLength = null;
  projectStateStore.currentSettings.cuffLength = null;
  projectStateStore.currentSettings.hemLength = null;
};

function calculateSweaterLength(chosenLength, chestCircumf) {
  switch (chosenLength) {
    case "cropped":
      return calculateCroppedLength(chestCircumf);
    case "regular":
      return calculateRegularLength(chestCircumf);
    case "long":
      return calculateLongLength(chestCircumf);
    default:
      return null;
  }
}

watch(
  () => [
    projectStateStore.currentSettings.chosenLength,
    projectStateStore.currentSettings.chestCircumf,
  ],
  () => {
    if (
      projectStateStore.currentSettings.chosenLength?.value == null ||
      projectStateStore.currentSettings.chestCircumf == null
    ) {
      calculatedChosenSweaterLength.value = null;
    } else {
      calculatedChosenSweaterLength.value = calculateSweaterLength(
        projectStateStore.currentSettings.chosenLength.value,
        projectStateStore.currentSettings.chestCircumf
      );
    }
  }
);

function onSizeSelected(newChosenSize) {
  projectStateStore.currentSettings.neckbandCircumf = newChosenSize.sizeNeckbandCircumf;
  projectStateStore.currentSettings.chestCircumf = newChosenSize.sizeChestCircumf;
  projectStateStore.currentSettings.armCircumf = newChosenSize.sizeArmCircumf;
  projectStateStore.currentSettings.armLength = newChosenSize.sizeArmLength;
  projectStateStore.currentSettings.wristCircumf = newChosenSize.sizeWristCircumf;
  projectStateStore.currentSettings.neckbandLength = newChosenSize.sizeNeckbandLength;
  projectStateStore.currentSettings.cuffLength = newChosenSize.sizeCuffLength;
  projectStateStore.currentSettings.hemLength = newChosenSize.sizeHemLength;

  if (
    projectStateStore.currentSettings.chosenLength &&
    projectStateStore.currentSettings.chosenLength.value
  ) {
    // Realculate sweater length based on the chosen length
    projectStateStore.currentSettings.sweaterLength = calculateSweaterLength(
      projectStateStore.currentSettings.chosenLength.value,
      projectStateStore.currentSettings.chestCircumf
    );
  }

  currentStep.value = Math.max(currentStep.value, 2);
}

function onFitSelected(newChosenFit) {
  projectStateStore.currentSettings.easeBody = newChosenFit.easeBody;
  projectStateStore.currentSettings.easeSleeve = newChosenFit.easeSleeve;

  currentStep.value = Math.max(currentStep.value, 3);
}

function onLengthSelected(newChosenLength) {
  // Calculate sweater length based on the chosen length
  projectStateStore.currentSettings.sweaterLength = calculateSweaterLength(
    newChosenLength,
    projectStateStore.currentSettings.chestCircumf
  );

  currentStep.value = Math.max(currentStep.value, 4);
}

const buttonIsEnabled = computed(() => {
  return formIsValid.value && projectStateStore.hasChanges;
});

const formIsValid = computed(() => {
  const validFormData = { ...projectStateStore.currentSettings };
  // easeBody and easeSleeve are allowed to be 0
  if (validFormData.easeBody === 0) {
    delete validFormData.easeBody;
  }
  if (validFormData.easeSleeve === 0) {
    delete validFormData.easeSleeve;
  }
  return !hasEmptyValues(validFormData);
});

const calculatePattern = async (upgrade, saveProject) => {
  try {
    loading.value = true;
    if (formIsValid.value) {
      if (upgrade) {
        projectStateStore.version = latestVersion;
      }
      await getSweaterParameters();
      if (saveProject) {
        await projectStateStore.addOrUpdateProject();
      }
      showPattern.value = true;
      showSettings.value = false;
    } else {
      showSettings.value = true;
      showPattern.value = false;
    }
    window.scrollTo(0, 0);
  } catch (error) {
    if (error.response && error.response.status === 403) {
      isSubscribedOrOnTrial.value = false;
    } else {
      throw error;
    }
  } finally {
    loading.value = false;
  }
};

async function getSweaterParameters() {
  try {
    const sweaterParameters = await calculateSweater(
      projectStateStore.currentSettings.stitchGauge / 10,
      projectStateStore.currentSettings.rowGauge / 10,
      projectStateStore.currentSettings.chestCircumf,
      projectStateStore.currentSettings.easeBody,
      projectStateStore.currentSettings.wristCircumf,
      projectStateStore.currentSettings.cuffLength,
      projectStateStore.currentSettings.neckbandCircumf,
      projectStateStore.currentSettings.neckbandLength,
      projectStateStore.currentSettings.armCircumf,
      projectStateStore.currentSettings.armLength,
      projectStateStore.currentSettings.easeSleeve,
      projectStateStore.currentSettings.patternRibStitchCount.value,
      projectStateStore.currentSettings.raglanStitchCount,
      projectStateStore.currentSettings.patternBodyStitchCount,
      projectStateStore.currentSettings.yokeType,
      projectStateStore.currentSettings.sweaterLength,
      projectStateStore.currentSettings.hemLength,
      projectStateStore.currentSettings.sleeveType,
      projectStateStore.currentSettings.applyNeckShaping,
      projectStateStore.currentSettings.neckbandType.value,
      projectStateStore.version
    );
    if (sweaterParameters.warnings.length > 0) {
      sweaterParameters.warnings = sweaterParameters.warnings.map((warning) =>
        warningTranslation(warning)
      );
    }

    errorMessage.value = null;
    sweaterData.value = sweaterParameters;
    projectStateStore.startingFromScratch = false;
    return sweaterParameters;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      showPattern.value = false;
      showSettings.value = true;
      errorMessage.value = errorTranslation(error.response.data.detail);
    } else {
      throw error;
    }
  }
}

const warningTranslation = (warningCode) => {
  switch (warningCode) {
    case "SINGLE_RIBBING_RAGLAN_STITCH_COUNT_MISMATCH":
      return t("sweater.Single_ribbing_raglan_stitch_count_mismatch");
    case "DOUBLE_RIBBING_RAGLAN_STITCH_COUNT_MISMATCH":
      return t("sweater.Double_ribbing_raglan_stitch_count_mismatch");
    case "KNITTING_GAUGE_RATIO_SUSPICIOUS":
      return t("sweater.Kitting_gauge_ratio_suspicious", {
        rowGauge: roundToBase(projectStateStore.currentSettings.stitchGauge * 1.33, 0.5),
      });
    default:
      return t("sweater.default_warning");
  }
};

const errorTranslation = (errorData) => {
  switch (errorData.code) {
    case "RAGLAN_TOO_WIDE":
      return t("sweater.Raglan_too_wide", {
        maxRaglanStitchCount: errorData.extra_data["maximum_allowed"],
      });
    case "BODY_SLEEVE_WIDTH_DISCREPANCY":
      if (errorData.extra_data["part"] == "body") {
        return t("sweater.Body_not_wide_enough");
      } else {
        return t("sweater.Sleeve_not_wide_enough");
      }
    case "NECK_SHAPING_FAILED":
      return t("sweater.Neck_shaping_failed");
    default:
      return t("sweater.default_error");
  }
};

const goToSubscription = async () => {
  await projectStateStore.addOrUpdateProject();
  router.push(composeRoute(locale.value, "subscription", "sweater"));
};

const calculateCroppedLength = (chestCircumf) => {
  // based on sweater length from several cropped sweater patterns
  return projectStateStore.currentSettings.chosenPerson.value == "child"
    ? roundToBase(-96 + 31.5 * Math.log(chestCircumf), 0.5)
    : roundToBase(0.25 * chestCircumf + 26, 0.5);
};

const calculateRegularLength = (chestCircumf) => {
  // based on sweater length from several regular sweater patterns
  return projectStateStore.currentSettings.chosenPerson.value == "child"
    ? roundToBase(-132 + 42 * Math.log(chestCircumf), 0.5)
    : roundToBase(0.28 * chestCircumf + 31, 0.5);
};

const calculateLongLength = (chestCircumf) => {
  // based on sweater length from several long sweater patterns
  return projectStateStore.currentSettings.chosenPerson.value == "child"
    ? roundToBase(-189 + 57.8 * Math.log(chestCircumf), 0.5)
    : roundToBase(0.123 * chestCircumf + 62, 0.5);
};

const personSizeIsSelected = computed(() => {
  return (
    projectStateStore.currentSettings.chosenPerson != null &&
    projectStateStore.currentSettings.chosenPerson.value != "custom"
  );
});

const customSizeIsSelected = computed(() => {
  return (
    projectStateStore.currentSettings.chosenPerson != null &&
    projectStateStore.currentSettings.chosenPerson.value == "custom"
  );
});

const showAdvancedSettings = computed(() => {
  if (customSizeIsSelected.value) {
    return true;
  }
  if (
    personSizeIsSelected &&
    projectStateStore.currentSettings.chosenSize != null &&
    projectStateStore.currentSettings.chosenFit != null &&
    projectStateStore.currentSettings.chosenLength != null
  ) {
    return true;
  }

  return false;
});

watch(
  () => projectStateStore.currentSettings.patternRibStitchCount,
  (value) => {
    if (value == null) {
      return;
    }

    if (!projectStateStore.currentSettings.raglanStitchCount) {
      projectStateStore.currentSettings.raglanStitchCount = value.value == 2 ? 1 : 2;
    }
    currentStep.value = Math.max(currentStep.value, 5);
  }
);

watch(
  () => [
    projectStateStore.currentSettings.chestCircumf,
    projectStateStore.currentSettings.easeBody,
  ],
  ([newChestCircumf, newEaseBody]) => {
    if (newChestCircumf == null || newEaseBody == null) {
      projectStateStore.currentSettings.finishedChestCircumf = null;
    } else {
      projectStateStore.currentSettings.finishedChestCircumf = newChestCircumf + newEaseBody;
    }
  }
);

watch(
  () => [
    projectStateStore.currentSettings.armCircumf,
    projectStateStore.currentSettings.easeSleeve,
  ],
  ([newArmCircumf, newEaseSleeve]) => {
    if (newArmCircumf == null || newEaseSleeve == null) {
      projectStateStore.currentSettings.sleeveCircumf = null;
    } else {
      projectStateStore.currentSettings.sleeveCircumf = newArmCircumf + newEaseSleeve;
    }
  }
);

watch(
  () => [
    projectStateStore.currentSettings.chestCircumf,
    projectStateStore.currentSettings.easeBody,
  ],
  ([newChestCircumf, newEaseBody]) => {
    projectStateStore.currentSettings.finishedChestCircumf = newChestCircumf + newEaseBody;
  }
);

watch(
  () => [
    projectStateStore.currentSettings.armCircumf,
    projectStateStore.currentSettings.easeSleeve,
  ],
  ([newArmCircumf, newEaseSleeve]) => {
    projectStateStore.currentSettings.sleeveCircumf = newArmCircumf + newEaseSleeve;
  }
);

const fitOptions = computed(() => {
  if (projectStateStore.currentSettings.chosenPerson?.value === "child") {
    return [
      {
        key: t("sweater.Fitted") + ` (${t("sweater.cm_ease", { easeAmount: -3 })})`,
        value: {
          easeBody: -3,
          easeSleeve: -1,
        },
      },
      {
        key: t("sweater.Tailored") + ` (${t("sweater.cm_ease", { easeAmount: 0 })})`,
        value: {
          easeBody: 0,
          easeSleeve: 0,
        },
      },
      {
        key: t("sweater.Relaxed") + ` (${t("sweater.cm_ease", { easeAmount: 10 })})`,
        value: {
          easeBody: 10,
          easeSleeve: 4,
        },
      },
      {
        key: t("sweater.Loose") + ` (${t("sweater.cm_ease", { easeAmount: 15 })})`,
        value: {
          easeBody: 15,
          easeSleeve: 6,
        },
      },
      {
        key: t("sweater.Oversized") + ` (${t("sweater.cm_ease", { easeAmount: 25 })})`,
        value: {
          easeBody: 25,
          easeSleeve: 10,
        },
      },
    ];
  }

  // Default fit options for adults
  return [
    {
      key: t("sweater.Fitted") + ` (${t("sweater.cm_ease", { easeAmount: -5 })})`,
      value: {
        easeBody: -5,
        easeSleeve: -3.5,
      },
    },
    {
      key: t("sweater.Tailored") + ` (${t("sweater.cm_ease", { easeAmount: 0 })})`,
      value: {
        easeBody: 0,
        easeSleeve: 0,
      },
    },
    {
      key: t("sweater.Relaxed") + ` (${t("sweater.cm_ease", { easeAmount: 10 })})`,
      value: {
        easeBody: 10,
        easeSleeve: 7,
      },
    },
    {
      key: t("sweater.Loose") + ` (${t("sweater.cm_ease", { easeAmount: 15 })})`,
      value: {
        easeBody: 15,
        easeSleeve: 11,
      },
    },
    {
      key: t("sweater.Oversized") + ` (${t("sweater.cm_ease", { easeAmount: 25 })})`,
      value: {
        easeBody: 25,
        easeSleeve: 18,
      },
    },
  ];
});
const lengthOptions = computed(() => {
  return [
    {
      key: t("sweater.Cropped"),
      value: "cropped",
    },
    {
      key: t("sweater.Regular"),
      value: "regular",
    },
    {
      key: t("sweater.Long"),
      value: "long",
    },
  ];
});

const neckbandTypeOptions = computed(() => {
  return [
    { key: t("sweater.Plain"), value: "plain" },
    { key: t("sweater.Folded"), value: "folded" },
  ];
});

const sizeBabyChildYouth = computed(() => {
  return [
    {
      key: "0 - 3 " + t("common.months"),
      value: {
        sizeNeckbandCircumf: 25,
        sizeArmCircumf: 15,
        sizeWristCircumf: 10,
        sizeChestCircumf: 42,
        sizeArmLength: 17,
        sizeNeckbandLength: 2,
        sizeCuffLength: 3,
        sizeHemLength: 3,
      },
    },
    {
      key: "3 - 6 " + t("common.months"),
      value: {
        sizeNeckbandCircumf: 26.5,
        sizeArmCircumf: 16,
        sizeWristCircumf: 10.5,
        sizeChestCircumf: 45,
        sizeArmLength: 18,
        sizeNeckbandLength: 2,
        sizeCuffLength: 3,
        sizeHemLength: 3,
      },
    },
    {
      key: "6 - 9 " + t("common.months"),
      value: {
        sizeNeckbandCircumf: 28,
        sizeArmCircumf: 17,
        sizeWristCircumf: 10.5,
        sizeChestCircumf: 47,
        sizeArmLength: 19,
        sizeNeckbandLength: 2.5,
        sizeCuffLength: 3.5,
        sizeHemLength: 3.5,
      },
    },
    {
      key: "9 - 12 " + t("common.months"),
      value: {
        sizeNeckbandCircumf: 29.5,
        sizeArmCircumf: 17.5,
        sizeWristCircumf: 11,
        sizeChestCircumf: 49,
        sizeArmLength: 21,
        sizeNeckbandLength: 2.5,
        sizeCuffLength: 3.5,
        sizeHemLength: 3.5,
      },
    },
    {
      key: "1 - 2 " + t("common.years"),
      value: {
        sizeNeckbandCircumf: 31,
        sizeArmCircumf: 18,
        sizeWristCircumf: 11.5,
        sizeChestCircumf: 51,
        sizeArmLength: 23,
        sizeNeckbandLength: 3,
        sizeCuffLength: 4,
        sizeHemLength: 4,
      },
    },
    {
      key: "2 - 4 " + t("common.years"),
      value: {
        sizeNeckbandCircumf: 32.5,
        sizeArmCircumf: 19.5,
        sizeWristCircumf: 12.0,
        sizeChestCircumf: 54,
        sizeArmLength: 28,
        sizeNeckbandLength: 3,
        sizeCuffLength: 4,
        sizeHemLength: 4,
      },
    },
    {
      key: "4 - 6 " + t("common.years"),
      value: {
        sizeNeckbandCircumf: 34,
        sizeArmCircumf: 21,
        sizeWristCircumf: 12.5,
        sizeChestCircumf: 59,
        sizeArmLength: 32,
        sizeNeckbandLength: 3.5,
        sizeCuffLength: 4.5,
        sizeHemLength: 4.5,
      },
    },
    {
      key: "6 - 8 " + t("common.years"),
      value: {
        sizeNeckbandCircumf: 35,
        sizeArmCircumf: 22,
        sizeWristCircumf: 13.0,
        sizeChestCircumf: 63,
        sizeArmLength: 36,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
    {
      key: "8 - 10 " + t("common.years"),
      value: {
        sizeNeckbandCircumf: 36,
        sizeArmCircumf: 23,
        sizeWristCircumf: 13.5,
        sizeChestCircumf: 68,
        sizeArmLength: 39,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
    {
      key: "10 - 12 " + t("common.years"),
      value: {
        sizeNeckbandCircumf: 37,
        sizeArmCircumf: 24,
        sizeWristCircumf: 14.5,
        sizeChestCircumf: 74,
        sizeArmLength: 43,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
  ];
});

const sizeWomen = computed(() => {
  return [
    {
      key: "1 (" + t("sweater.chest_circumference") + " 75-80 cm)",
      value: {
        sizeNeckbandCircumf: 40.5,
        sizeArmCircumf: 26.0,
        sizeWristCircumf: 14,
        sizeChestCircumf: 77.5,
        sizeArmLength: 48.0,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
    {
      key: "2 (" + t("sweater.chest_circumference") + " 80-85 cm)",
      value: {
        sizeNeckbandCircumf: 42,
        sizeArmCircumf: 26.0,
        sizeWristCircumf: 15,
        sizeChestCircumf: 82.5,
        sizeArmLength: 48.5,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
    {
      key: "3 (" + t("sweater.chest_circumference") + " 85-90 cm)",
      value: {
        sizeNeckbandCircumf: 43,
        sizeArmCircumf: 27.5,
        sizeWristCircumf: 16,
        sizeChestCircumf: 87.5,
        sizeArmLength: 49.0,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
    {
      key: "4 (" + t("sweater.chest_circumference") + " 90-95 cm)",
      value: {
        sizeNeckbandCircumf: 44,
        sizeArmCircumf: 29.0,
        sizeWristCircumf: 17,
        sizeChestCircumf: 92.5,
        sizeArmLength: 50.0,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
    {
      key: "5 (" + t("sweater.chest_circumference") + " 95-100 cm)",
      value: {
        sizeNeckbandCircumf: 45.5,
        sizeArmCircumf: 31.0,
        sizeWristCircumf: 18,
        sizeChestCircumf: 97.5,
        sizeArmLength: 50.0,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
    {
      key: "6 (" + t("sweater.chest_circumference") + " 100-110 cm)",
      value: {
        sizeNeckbandCircumf: 42.5,
        sizeArmCircumf: 32.0,
        sizeWristCircumf: 19,
        sizeChestCircumf: 105,
        sizeArmLength: 49.0,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
    {
      key: "7 (" + t("sweater.chest_circumference") + " 110-120 cm)",
      value: {
        sizeNeckbandCircumf: 46.5,
        sizeArmCircumf: 34.0,
        sizeWristCircumf: 20,
        sizeChestCircumf: 115,
        sizeArmLength: 48.0,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
    {
      key: "8 (" + t("sweater.chest_circumference") + " 120-130 cm)",
      value: {
        sizeNeckbandCircumf: 47.5,
        sizeArmCircumf: 36.0,
        sizeWristCircumf: 21,
        sizeChestCircumf: 125,
        sizeArmLength: 48.0,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
    {
      key: "9 (" + t("sweater.chest_circumference") + " 130-140 cm)",
      value: {
        sizeNeckbandCircumf: 48,
        sizeArmCircumf: 38.0,
        sizeWristCircumf: 22,
        sizeChestCircumf: 135,
        sizeArmLength: 46.0,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
    {
      key: "10 (" + t("sweater.chest_circumference") + " 140-150 cm)",
      value: {
        sizeNeckbandCircumf: 48.5,
        sizeArmCircumf: 40.0,
        sizeWristCircumf: 23,
        sizeChestCircumf: 145,
        sizeArmLength: 45,
        sizeNeckbandLength: 4,
        sizeCuffLength: 5,
        sizeHemLength: 5,
      },
    },
  ];
});
</script>

<style lang="scss" scoped>
@import "./../../../scss/form.scss";
</style>
–
