<template>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <span v-if="!useOneTap">
    <GoogleLogin
      :callback="callback"
      :buttonConfig="{ shape: 'pill', locale: locale }"
      class="google-login-button"
    />
  </span>
  <div v-else>
    <GoogleLogin :callback="callback" prompt />
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";

import { useRouter } from "vue-router";
import { useAuthStore } from "./../../stores/auth";
import { composeRoute } from "./../../lib/navigationUtils";
import { useLangStore } from "./../../stores/lang";

const router = useRouter();
const langStore = useLangStore();
const locale = langStore.getLocale();

const loading = ref(false);

const authStore = useAuthStore();

const props = defineProps({
  useOneTap: {
    type: Boolean,
    default: false,
  },
  next: {
    type: String,
    required: true
  }
});

const callback = async (response) => {
  if (!response.credential) {
    alert("No credential received");
    return;
  }

  try {
    loading.value = true;
    await authStore.googleAuth(response.credential);
    router.push(composeRoute(locale.value, props.next));
  } catch (error) {
    alert(error);
  } finally {
    loading.value = false;
  }
};
</script>
