<template>
  <div class="app-featured-blog-sheet">
    <v-row class="app-featured-blog-row">
      <v-col cols="12" sm="12" md="6" class="p0 left-col">
        <div class="app-featured-blog-text-container">
          <div class="app-featured-blog-super-title" v-if="props.title != null || !props.slug">
            {{ props.title || $t('featured_blog.new_blog_post') }}
          </div>
          <h2 class="app-featured-blog-title">
            {{ featuredBlog.title }}
          </h2>
          <div class="app-featured-blog-excerpt">
            {{ featuredBlog.excerpt }}
          </div>
          <div class="app-featured-blog-button-container">
            <LocalizedLink 
              v-if="featuredBlog.slug"
              :to="'blogPost'"
              :params="{ slug: featuredBlog.slug }"
              :underline="false"
            >
              <main-button color="purple" class="app-featured-blog-button">
                {{ $t('featured_blog.read_post') }}
              </main-button>
            </LocalizedLink>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" class="app-image-container-col">
        <div class="app-image-container">
          <img :src="blogImage" :alt="featuredBlog.title" class="app-featured-blog-img" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useLangStore } from "./../stores/lang";
import MainButton from "./general/MainButton.vue";
import LocalizedLink from "./LocalizedLink.vue";
import { getImagePath } from "@/lib/imageUtils";

const props = defineProps({
  slug: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
});

const { t } = useI18n();
const langStore = useLangStore();
const locale = langStore.getLocale();

const featuredBlog = ref({
  title: '',
  excerpt: '',
  slug: '',
  image: ''
});

const blogImage = computed(() => {
  return featuredBlog.value.image ? getImagePath(featuredBlog.value.image) : '';
});

async function loadBlogManifest(localeValue) {
  try {
    // Try to load the manifest for the current locale
    const manifest = await import(`../blog-manifests/${localeValue}.json`);
    return manifest.default;
  } catch (error) {
    // If current locale fails and it's not English, try English
    if (localeValue !== 'en') {
      try {
        const enManifest = await import('../blog-manifests/en.json');
        return enManifest.default;
      } catch (fallbackError) {
        console.warn('Error loading both locale and fallback manifests:', fallbackError);
        return null;
      }
    }
    console.warn('Error loading blog manifest:', error);
    return null;
  }
}

async function updateFeaturedBlog(localeValue, specificSlug = null) {
  let manifest = await loadBlogManifest(localeValue);

  if (!manifest || manifest.length === 0) {
    console.log(`No blog manifest found for locale "${localeValue}". Falling back to English.`);
    manifest = await loadBlogManifest('en');
  }

  if (manifest && manifest.length > 0) {
    let blogPost;

    if (specificSlug) {
      // Find the blog post with the specified slug
      blogPost = manifest.find(blog => blog.slug === specificSlug);
    } else {
      blogPost = manifest[0];
    }

    if (blogPost) {
      featuredBlog.value = blogPost;
    } else {
      console.log(`Blog post with slug "${specificSlug}" not found.`);
    }   
  } else {
    console.log("No blog manifests found, including the English fallback.");
  }
}

onMounted(() => {
  updateFeaturedBlog(locale.value, props.slug);
});

// Watch for locale changes
watch([locale, () => props.slug], ([newLocale, newSlug]) => {
  updateFeaturedBlog(newLocale, newSlug);
});
</script>

<style scoped lang="scss">
@import "../../scss/styles.scss";

.app-featured-blog-row {
  margin: 0;
}

.app-image-container-col {
  padding: 0;
}

.app-featured-blog-sheet {
  background-color: var(--knittable-opal-dark);
}

.left-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-featured-blog-text-container {
  padding: 4rem;
  text-align: center;
  margin: 0 auto;
  width: 100%;
}

.app-featured-blog-super-title {
  color: var(--knittable-yama-medium);
  @include subtitleLarge;
  font-weight: 600;
  letter-spacing: 0.04em;
  padding-bottom: 1rem;
}

.app-sm-and-down .app-featured-blog-super-title {
  font-size: 1.5rem;
}

.app-featured-blog-title {
  @include heading3;
  color: white;
  margin-bottom: 1.5rem;
  
  /* Set container width with some padding */
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  
  /* Force break for extremely long words */
  word-break: break-word; /* This is the modern approach */
  overflow-wrap: break-word; /* Fallback */
  
  /* Keep hiding overflow */
  overflow: hidden;
  
  /* Allow multiple lines */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.app-sm-and-down .app-featured-blog-title {
  font-size: 3rem;
  width: 100%;
}

.app-featured-blog-excerpt {
  color: white;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.app-featured-blog-button {
  padding: 1rem 2rem;
  margin: 0 auto;
}

.app-image-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.app-featured-blog-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0;
}

.app-sm-and-down .app-image-container {
  aspect-ratio: 1;
}

.app-sm-and-down .app-featured-blog-text-container {
  padding: 2rem;
}

.app-sm-and-down .app-featured-blog-img-box {
  border-radius: 60px;
  width: 75%;
  filter: drop-shadow(20px 20px var(--knittable-yama));
  margin-bottom: 20px;
  margin-right: 20px;
}

.app-sm-and-down .app-featured-blog-button-container {
  text-align: center;
}
</style> 