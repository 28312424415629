import { computed } from 'vue';

/**
 * Returns a computed property that resolves to the appropriate image path based on the current locale
 * 
 * @param {Object} imageMap - Object mapping locale codes to imported image paths
 * @param {Object} locale - The i18n locale object (typically from useI18n())
 * @param {String} defaultLocale - Fallback locale code if current locale isn't in the imageMap
 * @returns {ComputedRef<String>} Computed property that returns the appropriate image path
 * 
 * @example
 * // In your component:
 * import { getLocalizedImagePath } from '@/lib/imageUtils';
 * import { useI18n } from 'vue-i18n';
 * import imageEn from '@/assets/image-en.png';
 * import imageIs from '@/assets/image-is.png';
 * 
 * const { locale } = useI18n();
 * const imageMap = { en: imageEn, is: imageIs };
 * const localizedImage = getLocalizedImagePath(imageMap, locale);
 */
export function getLocalizedImagePath(imageMap, locale, defaultLocale = 'en') {
    return computed(() => {
        return imageMap[locale.value] || imageMap[defaultLocale];
    });
}

// Function to handle image paths
export const getImagePath = (imagePath) => {
    // If no image path is provided, return a placeholder
    if (!imagePath) {
        console.warn('No image path provided');
        return ''; // Or return a placeholder image path
    }

    // If the path is a URL (starts with http or https), return it as is
    if (imagePath.startsWith('http')) {
        return imagePath;
    }

    // For local assets, use webpack's require
    try {
        // First try with the path as is (might be a full path already)
        return require(`@/assets/${imagePath}`);
    } catch (firstError) {
        console.warn(`Could not load image: ${imagePath}`, firstError);
        // Return a placeholder or empty string
        return '';
    }
};

