<template>
  <div class="image-carousel-container" :class="{ 'has-navigation': images.length > 4 }">
    <button 
      v-if="images.length > 4" 
      class="carousel-nav-button prev-button" 
      @click="scrollCarousel(-1)"
      aria-label="Previous images"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </button>
    
    <div class="image-carousel" ref="carouselRef">
      <div 
        v-for="(image, index) in images" 
        :key="index" 
        class="carousel-item"
      >
        <img 
          :src="require(`@/assets/${image.source}`)" 
          :alt="image.alt || ''" 
          class="carousel-image"
        />
      </div>
    </div>
    
    <button 
      v-if="images.length > 4" 
      class="carousel-nav-button next-button" 
      @click="scrollCarousel(1)"
      aria-label="Next images"
    >
      <v-icon>mdi-chevron-right</v-icon>
    </button>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useDisplay } from 'vuetify';

const props = defineProps({
  images: {
    type: Array,
    default: () => [],
    required: true
  }
});

const { smAndDown } = useDisplay();
const carouselRef = ref(null);

const scrollCarousel = (direction) => {
  if (!carouselRef.value) return;
  
  const scrollAmount = smAndDown.value 
    ? carouselRef.value.offsetWidth * 0.8  // Scroll about 2 images on mobile
    : carouselRef.value.offsetWidth * 0.5;  // Scroll about 2 images on desktop
  
  carouselRef.value.scrollBy({
    left: scrollAmount * direction,
    behavior: 'smooth'
  });
};

onMounted(() => {
  // Add scroll snap behavior if needed
  if (carouselRef.value && props.images.length > 4) {
    carouselRef.value.style.scrollSnapType = 'x mandatory';
  }
});
</script>

<style scoped lang="scss">
.image-carousel-container {
  position: relative;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  
  &.has-navigation {
    padding: 0 3rem;
  }
}

.app-sm-and-down .image-carousel-container {
  margin-top: 2rem;
  
  &.has-navigation {
    padding: 0 2rem;
  }
}

.image-carousel {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden; /* Prevent vertical scrolling */
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  gap: 0.5rem;
  
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
}

.carousel-item {
  flex: 0 0 calc(25% - 0.375rem); /* Adjusted for smaller gap: 0.5rem * 0.75 = 0.375rem */
  scroll-snap-align: start;
  height: 0;
  padding-bottom: calc(25% - 0.375rem); /* Maintain aspect ratio with smaller gap */
  position: relative;
}

.app-sm-and-down .carousel-item {
  flex: 0 0 calc(40% - 0.3rem); /* Adjusted for smaller gap: 0.5rem * 0.6 = 0.3rem */
  padding-bottom: calc(40% - 0.3rem); /* Maintain aspect ratio with smaller gap */
}

.carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  object-fit: cover;
}

.carousel-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: var(--knittable-lav-light);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s;
  
  &:hover {
    background-color: var(--knittable-lav);
  }
  
  &.prev-button {
    left: 0;
  }
  
  &.next-button {
    right: 0;
  }
  
  .v-icon {
    color: var(--knittable-lav-darker);
  }
}

.app-sm-and-down .carousel-nav-button {
  width: 2rem;
  height: 2rem;
}
</style> 