<template>
  <div class="content-grid" :class="{ 'app-md-and-up': mdAndUp, 'app-sm-and-down': !mdAndUp }">
    <div v-for="(item, index) in items" :key="index" class="grid-item">
      <div class="image-container">
        <img :src="require(`@/assets/${item.image.source}`)" :alt="item.image.alt" />
      </div>
      <h3>{{ item.title }}</h3>
      <p
        v-for="(text, textIndex) in item.text"
        :key="textIndex"
        class="item-text"
        v-html="text"
      ></p>
    </div>
  </div>
</template>

<script setup>
import { useDisplay } from "vuetify";

const { mdAndUp } = useDisplay();

defineProps({
  items: {
    type: Array,
    required: true,
    default: () => [],
  },
});
</script>

<style scoped lang="scss">
@import "../../../scss/styles.scss";

.content-grid {
  display: grid;
  background: #ede6f3 0% 0% no-repeat padding-box;
  grid-template-columns: repeat(3, 1fr);
  padding: 4rem 2rem;
  justify-content: center;
  max-width: 1920px;
  gap: 4rem;
}

.app-sm-and-down .content-grid {
  grid-template-columns: 1fr;
  gap: 3rem;
  padding: 3rem 1rem;
}

.app-sm-and-down .grid-item {
  padding: 0 1rem;
}

.grid-item {
  text-align: center;
  max-width: 419px;
  margin: 0 auto;
  width: 100%;

  .image-container {
    border-radius: 1.3rem;
    overflow: hidden;
    margin-bottom: 1.5rem;
    aspect-ratio: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h3 {
    font-family: "Philosopher", sans-serif;
    font-size: 36px;
    font-weight: bold;
    color: var(--knittable-lav-darker);
    margin-bottom: 1rem;
    text-align: center;
    letter-spacing: 0;
  }

  .app-sm-and-down & h3 {
    font-size: 36px;
    line-height: 40px;
  }

  p {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0;
    color: #000000;
    opacity: 1;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .app-sm-and-down & p {
    font-size: 18px;
    line-height: 24px;
  }

  :deep(.link) {
    color: var(--knittable-lav-darker);
    text-decoration: underline;

    &:hover {
      color: var(--knittable-lav);
    }
  }
}
</style>
