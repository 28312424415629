 <template>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <h5 class="title">{{ props.customTitle || $t("sign_in.Welcome_back_") }}</h5>

  <SocialAuth :next="next" />

  <div class="chapter-title">{{ $t("sign_in.Sign_in_with_email") }}</div>

  <div class="app-central-container">
    <div class="app-central-card">
      <v-card-text>
        <v-form v-model="signInFormValid" @submit.prevent="signIn">
          <v-container class="sign-in-inputs">
            <v-row>
              <v-text-field
                v-model="email"
                :rules="emailRules"
                :label="$t('common_user_settings.E_mail')"
                required
                density="compact"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                hint="At least 8 characters"
                :label="$t('common_user_settings.Password')"
                required
                @click:append="showPassword = !showPassword"
                density="compact"
              ></v-text-field>
            </v-row>

            <main-button class="submit-btn" color="purple" :disabled="!signInFormValid">
              {{ $t("sign_in.Submit") }}
            </main-button>
          </v-container>
        </v-form>
        <v-text-field
          v-model="signInErrorMessage"
          label="Error Message"
          :value="signInErrorMessage"
          readonly
          prepend-icon="mdi-alert-circle-outline"
          v-if="signInErrorMessage"
        ></v-text-field>
        <resend-verification-button
          v-if="resendVerificationOption"
          :email="email"
        ></resend-verification-button>
      </v-card-text>
      <div class="sign-in-footer">
        {{ $t("sign_in.Not_a_registered_user_") }}
        <localized-link to="signup">{{ $t("sign_in.Sign_up_instead_") }}</localized-link>
      </div>
      <div class="sign-in-footer">
        {{ $t("sign_in.Forgot_password_") }}
        <localized-link to="forgotpassword">{{ $t("sign_in.Get_a_new_one_") }}</localized-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { composeRoute } from "../../lib/navigationUtils";
import { useAuthStore } from "../../stores/auth";
import LocalizedLink from "../LocalizedLink.vue";
import MainButton from "../general/MainButton.vue";
import { useLangStore } from "./../../stores/lang";
import { emailRules } from "./../../lib/formRules.js";
import ResendVerificationButton from "../ResendVerificationButton.vue";
import SocialAuth from "../auth/SocialAuth.vue";

const authStore = useAuthStore();

const langStore = useLangStore();
const locale = langStore.getLocale();

const router = useRouter();
const route = useRoute();

const signInFormValid = ref(false);
const email = ref("");
const password = ref("");
const showPassword = ref(false);

const signInErrorMessage = ref("");

const loading = ref(false);

const resendVerificationOption = ref(false);

const props = defineProps({
  customTitle: {
    type: String,
    default: "",
  },
});

async function signIn() {
  try {
    signInErrorMessage.value = "";

    loading.value = true;
    await authStore.signIn(email.value, password.value);

    router.push(composeRoute(locale.value, next.value));
  } catch (error) {
    signInErrorMessage.value = error.msg;
    if (error.response?.data.detail == "UNVERIFIED_ACCOUNT") {
      resendVerificationOption.value = true;
    }
  } finally {
    loading.value = false;
  }
}

const next = computed(() => {
    return route.query.next ?? 'home';
});
</script>

<style scoped lang="scss">
@import "./../../../scss/styles.scss";
p {
  margin-bottom: 1rem;
}

.sign-in-inputs {
  padding-bottom: 1rem;
}

.submit-btn {
  margin-top: 1.5rem;
}

.chapter-title {
  @include paragraphLargerBolder;
  color: var(--knittable-lav-darker);
  margin-top: 1.5rem;
}

.sign-in-footer {
  @include paragraphSmallRegular;
}

.title {
  color: var(--knittable-lav-darker);
  padding-top: 1.5rem;
}

.app-sm-and-down .title {
  padding-left: 1rem;
  padding-right: 1rem;
}

.sign-in-inputs {
  max-width: 550px;
}

.app-sm-and-down .sign-in-inputs {
  max-width: 900px;
}
</style>
