<template>
  <div class="app-blog-container">
    <h1 class="app-page-title">{{ $t("common.Blog") }}</h1>
    <!-- <StoryblokComponent v-if="story" :blok="story.content" />    -->
    <div class="blog-posts-grid">
      <article v-for="post in blogPosts" :key="post.slug" class="blog-post-card">
        <localized-link :to="`blogPost`" :params="{ slug: post.slug }" class="blog-post-link">
          <div class="blog-post-image">
            <img :src="getImagePath(post.image)" :alt="post.title" />
          </div>
          <div class="blog-post-content">
            <h2>{{ post.title }}</h2>
            <p class="blog-post-excerpt">{{ post.excerpt }}</p>
            <div class="blog-post-meta">
              <span class="blog-post-date">{{ formatDate(post.date) }}</span>
            </div>
          </div>
        </localized-link>
      </article>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { getAllPosts } from '../utils/blogLoader';
import LocalizedLink from '../components/LocalizedLink.vue';
import { useRoute } from 'vue-router';
import { getImagePath } from '@/lib/imageUtils';

const route = useRoute();
const blogPosts = ref([]);
const loading = ref(true);

// Function to format date to DD.MM.YYYY
const formatDate = (dateString) => {
  if (!dateString) return '';
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return dateString; // Return original if invalid
    
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    
    return `${day}.${month}.${year}`;
  } catch (error) {
    console.warn('Error formatting date:', error);
    return dateString; // Return original on error
  }
};


async function loadBlogPosts() {
  loading.value = true;
  const locale = route.params.locale;
  blogPosts.value = await getAllPosts(locale);
  loading.value = false;
}

onMounted(loadBlogPosts);

// Reload posts when route changes (e.g., when changing language)
watch(() => route.params.locale, (newLocale) => {
  if (newLocale) {
    loadBlogPosts();
  }
});
</script>

<style scoped lang="scss">
@import "../../scss/styles.scss";

.app-blog-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.blog-posts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.blog-post-card {
  background: white;
  border-radius: 1rem;
  overflow: hidden;
  transition: transform 0.2s;
  border: 1px solid var(--knittable-lav-light);
  display: flex;
  flex-direction: column;

  &:hover {
    transform: translateY(-4px);
  }
}

.blog-post-link {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.blog-post-image {
  width: 100%;
  height: 200px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.blog-post-content {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  h2 {
    @include heading6;
    color: var(--knittable-lav-darker);
    margin-bottom: 0.5rem;
    line-height: 1.2; /* Set explicit line height */
    height: 2.4em; /* Exactly 2 lines (2 × 1.2) */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.blog-post-excerpt {
  @include paragraphMediumRegular;
  color: var(--knittable-mocha);
  margin-bottom: 1rem;
  flex-grow: 1;
  
  /* CSS-based truncation */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.5em; /* Fallback for browsers that don't support line-clamp */
}

.blog-post-meta {
  @include paragraphSmallRegular;
  color: var(--knittable-grey-darker);
  margin-top: auto;
}
</style>