<template>
  <div class="app-pricing-sheet app-front-page-sheet">
    <PromoCode v-if="showPromoCode" :code="promoCode" />
    <front-page-section-title
      v-else
      :title="customTitle || $t('pricing.Choose_what_fits_you_best')"
      :color-inverted="true"
    ></front-page-section-title>
    <subscription-plans :active="authToken != null"></subscription-plans>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";

import FrontPageSectionTitle from "./FrontPageSectionTitle.vue";
import SubscriptionPlans from "./SubscriptionPlans.vue";
import PromoCode from "./PromoCode.vue";
import { useAuthStore } from "../stores/auth";

const props = defineProps({
  showPromoCode: {
    type: Boolean,
    default: false
  },
  customTitle: {
    type: String,
    default: ''
  },
  promoCode: {
    type: String,
    default: 'HELLOKNITTABLE'
  }
});

const authStore = useAuthStore();
const { authToken } = storeToRefs(authStore);
</script>

<style>
.app-pricing-sheet {
  background-color: var(--knittable-isa);
}
</style>
