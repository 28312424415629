<template>
  <div class="social-auth">
    <v-row class="social-auth-button-container">
      <v-col class="button-col" v-if="isGoogleAuthEnabled">
        <GoogleAuth :next="next" />
      </v-col>
      <v-col class="button-col">
        <FacebookAuth :next="next" />
      </v-col>
    </v-row>
    <div class="google-sign-up-info">
      {{ $t("social_auth.Google_sign_up_info") }}
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import FacebookAuth from "./FacebookAuth.vue";
import GoogleAuth from "./GoogleAuth.vue";

const props = defineProps({
  next: {
    type: String,
    required: true,
  },
});

// Check if Google Auth is enabled from the environment variable
const isGoogleAuthEnabled = process.env.VUE_APP_GOOGLE_AUTH_ENABLED === 'true';
</script>

<style scoped lang="scss">
@import "./../../../scss/styles.scss";

.social-auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
}

.social-auth-button-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.google-sign-up-info {
  @include paragraphSmallRegular;
  margin-top: 0.5rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.button-col {
  padding: 5px;
}
</style>
