<template>
  <div v-if="projects.length === 0" class="no-projects">
    {{ $t("pattern_library.No_projects_found") }}
  </div>
  <div v-else class="projects-table-container">
    <table class="projects-table">
      <thead>
        <tr>
          <th></th>
          <th>{{ $t("pattern_library.Name") }}</th>
          <th class="md-and-up-only">{{ $t("common.Size_category") }}</th>
          <th class="md-and-up-only">{{ $t("common.Size") }}</th>
          <th class="md-and-up-only">{{ $t("common.Knitting_gauge") }}</th>
          <th class="md-and-up-only">{{ $t("pattern_library.Last_modified") }}</th>
          <th class="md-and-up-only">{{ $t("pattern_library.Created") }}</th>
          <th>{{ $t("pattern_library.Actions") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="project in projects"
          :key="project.id"
          :class="{ selected: selectedProject?.id === project.id }"
          class="project-row"
          @click="handleOpenProject(project)"
        >
          <td class="pattern-icon-container">
            <img
              class="pattern-icon"
              alt="pattern icon"
              :src="patternTypeIcons[project.patternType]"
            />
          </td>
          <td class="project-name">{{ project.name || "-" }}</td>
          <td class="md-and-up-only">{{ formatSizeCategory(project.settings) }}</td>
          <td class="md-and-up-only">{{ formatSize(project.settings) }}</td>
          <td class="md-and-up-only">{{ formatGauge(project.settings) }}</td>
          <td class="time-info md-and-up-only">
            <TimeAgo v-if="project.updatedAt" :timestamp="project.updatedAt" />
            <div v-else>-</div>
          </td>
          <td class="time-info md-and-up-only">
            <TimeAgo v-if="project.createdAt" :timestamp="project.createdAt" />
            <div v-else>-</div>
          </td>
          <td class="actions" @click.stop>
            <v-tooltip>
              <template #activator="{ props }">
                <v-icon v-bind="props" icon="mdi-pencil" @click="handleEditProject(project)" />
              </template>
              <span>{{ $t("pattern_library.Edit_project") }}</span>
            </v-tooltip>
            <v-tooltip>
              <template #activator="{ props }">
                <v-icon v-bind="props" icon="mdi-delete" @click="handleOpenDeleteDialog(project)" />
              </template>
              <span>{{ $t("pattern_library.Delete_project") }}</span>
            </v-tooltip>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <delete-project-modal v-model="deleteDialog" @confirm="handleConfirmDelete" />
</template>

<script setup>
import { ref, defineEmits } from "vue";
import { useI18n } from "vue-i18n";
import TimeAgo from "../general/TimeAgo.vue";
import { deleteProject } from "../../services/project";
import { useRouter } from "vue-router";
import { composeRoute } from "../../lib/navigationUtils";
import { useLangStore } from "../../stores/lang";
import DeleteProjectModal from "./DeleteProjectModal.vue";
import socksIcon from "./../../assets/icons/socks-icon.svg";
import sweaterIcon from "./../../assets/icons/sweater-icon.svg";
import mittensIcon from "./../../assets/icons/mittens-icon.svg";
import beanieIcon from "./../../assets/icons/beanie-icon.svg";

const router = useRouter();
const langStore = useLangStore();
const locale = langStore.getLocale();

const { t } = useI18n();

const patternTypeToRoute = {
  sock: "socks",
  mitten: "mittens",
  beanie: "beanie",
  sweater: "sweater",
};

const patternTypeIcons = {
  sock: socksIcon,
  sweater: sweaterIcon,
  mitten: mittensIcon,
  beanie: beanieIcon,
};

const props = defineProps({
  projects: {
    type: Array,
    required: true,
  },
});

const deleteDialog = ref(false);
const selectedProject = ref(null);

const emit = defineEmits(["select-project", "project-deleted"]);

const formatSizeCategory = (settings) => {
  if (!settings?.chosenPerson?.key) return "-";
  return settings.chosenPerson.key;
};

const formatSize = (settings) => {
  if (!settings?.chosenSize?.key) return "-";
  return settings.chosenSize.key;
};

const formatGauge = (settings) => {
  if (!settings?.stitchGauge || !settings?.rowGauge) return "-";
  return t("pattern_library.stitches_x_rows", {
    stitchGauge: settings.stitchGauge,
    rowGauge: settings.rowGauge,
  });
};

const handleOpenProject = (project) => {
  let routeName = patternTypeToRoute[project.patternType];
  router.push({
    ...composeRoute(locale.value, routeName),
    params: {
      project_id: project.id,
    },
  });
  emit("select-project", project);
};

const handleEditProject = (project) => {
  let routeName = patternTypeToRoute[project.patternType];
  router.push({
    ...composeRoute(locale.value, routeName),
    params: {
      project_id: project.id,
      edit: true,
    },
  });
  emit("select-project", project);
};

const handleOpenDeleteDialog = (project) => {
  selectedProject.value = project;
  deleteDialog.value = true;
};

const handleConfirmDelete = async () => {
  await deleteProject(selectedProject.value.patternType, selectedProject.value.id);
  emit("project-deleted", selectedProject.value.id);
  selectedProject.value = null;
  deleteDialog.value = false;
};
</script>

<style scoped lang="scss">
@import "./../../../scss/styles.scss";

.no-projects {
  text-align: center;
  padding: 2rem;
  color: var(--knittable-lav-darker);
}

.projects-table-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.projects-table {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 0.75rem 1rem;
    text-align: left;
    border-bottom: 1px solid var(--knittable-lav-light);
    white-space: nowrap;
  }

  th {
    @include paragraphMediumBold;
    color: var(--knittable-lav-darker);
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
  }

  td {
    @include paragraphMediumRegular;
    color: var(--knittable-lav-darker);
  }

  .project-row {
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: var(--knittable-lav-lighter);
    }

    &.selected {
      background-color: var(--knittable-lav-light);
    }
  }

  .actions {
    cursor: default;
  }
}

@media (max-width: 600px) {
  .projects-table {
    th,
    td {
      padding: 0.5rem;
      font-size: 0.875rem;
    }
  }
}

.button-icon {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 0.3rem;
}

.pattern-icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
}

td.pattern-icon-container {
  text-align: right;
  width: 24px;
}

td.actions {
  color: var(--knittable-lav);
}

.project-name {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-sm-and-down .project-name {
  width: 300px;
}

.time-info {
  width: 100px;
}

.app-sm-and-down .md-and-up-only {
  display: none;
}
</style>
