<template>
  <div class="app-blog-post-container">
    <template v-if="post">
      <!-- Blog Post Template -->
      <BlogTemplate
        v-if="post.template === 'how-to'"
        :title="post.title"
        :description="post.description"
        :gridItems="post.gridItems"
        :tableSections="post.tableSections"
        :screenshotsConfig="post.screenshotsConfig"
        :carouselImages="post.carouselImages"
        class="full-width-template"
      />

      <BlogTemplate
        v-if="post.template === 'project'"
        :title="post.title"
        :intro="post.intro"
        :description="post.description"
        :gridItems="post.gridItems"
        :tableSections="post.tableSections"
        :projectSpecifics="post.projectSpecifics"
        :carouselImages="post.carouselImages"
        :screenshotsConfig="post.screenshotsConfig"
        :showPricing="false"
        class="full-width-template"
      />
    </template>

    <div v-else class="blog-post-not-found">
      <h2>{{ $t("blog.Post_not_found") }}</h2>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { parseMarkdown } from "../utils/blogLoader";
import BlogTemplate from "../components/blog/BlogTemplate.vue";

const route = useRoute();
const post = ref(null);
const loading = ref(true);
const error = ref(null);

async function loadBlogPost() {
  const slug = route.params.slug;
  const locale = route.params.locale;

  loading.value = true;
  error.value = null;

  try {
    // Try to load localized version first
    try {
      const markdown = await import(`../../content/blog/${slug}/${locale}.md`);
      post.value = parseMarkdown(markdown.default);
    } catch (localeError) {
      // If localized version doesn't exist, try the English version
      try {
        const markdown = await import(`../../content/blog/${slug}/en.md`);
        post.value = parseMarkdown(markdown.default);
        console.warn(`No localized version found for ${locale} in ${slug}, using English version`);
      } catch (enError) {
        // If English version doesn't exist, try to find any available version
        try {
          // This is a fallback that would require server-side logic or a manifest file
          // For now, we'll just show an error
          error.value = "Post not found";
          post.value = null;
        } catch (defaultError) {
          error.value = "Post not found";
          post.value = null;
        }
      }
    }
  } catch (error) {
    console.error("Failed to load blog post:", error);
    post.value = null;
    error.value = "Failed to load blog post";
  } finally {
    loading.value = false;
  }
}

onMounted(loadBlogPost);

// Reload post when route changes (e.g., when changing language)
watch(
  () => route.params,
  (newParams) => {
    if (newParams.slug) {
      loadBlogPost();
    }
  },
  { deep: true }
);
</script>

<style scoped lang="scss">
@import "../../scss/styles.scss";

.app-blog-post-container {
  max-width: 1920px;
  margin: 0 auto;
  overflow-x: hidden;

  .full-width-template {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.blog-post {
  background: white;
  border-radius: 1rem;
  padding: 2rem;
  margin-top: 8.5rem;
}

.blog-post-header {
  text-align: center;
  margin-bottom: 2rem;

  h1 {
    @include heading3;
    color: var(--knittable-lav-darker);
    margin-bottom: 1rem;
  }
}

.blog-post-meta {
  @include paragraphSmallRegular;
  color: var(--knittable-grey-darker);
}

.blog-post-featured-image {
  margin: 2rem 0;
  border-radius: 0.5rem;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
}

.blog-post-content {
  @include paragraphMediumRegular;
  color: var(--knittable-black);

  h2 {
    @include heading5;
    color: var(--knittable-lav-darker);
    margin: 2rem 0 1rem;
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.6;
  }
}

.blog-post-not-found {
  text-align: center;
  padding: 4rem 0;

  h2 {
    @include heading5;
    color: var(--knittable-ama);
  }
}
</style>
