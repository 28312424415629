<template>
  <pattern-and-tool-placeholder
    :title="$t('common.Sweater')"
    :description="$t('sweater.pattern_description')"
    :first-bubble-text="$t('sweater_placeholder.First_bubble')"
    :second-bubble-text="$t('sweater_placeholder.Second_bubble')"
    :third-bubble-text="$t('sweater_placeholder.Third_bubble')"
    :screenshot-path="getLocalizedWebpScreenshotPath"
    :screenshot-fallback-path="getLocalizedPngScreenshotPath"
    :detail-screenshot-path="getLocalizedDetailScreenshotPath"
    :screenshot-alt="getLocalizedPngScreenshotPath"
    :detail-screenshot-alt="getLocalizedDetailScreenshotPath"
    color="purple"
    bubble-color="yellow"
    :sign-in-text="$t('placeholders.pattern_sign_in_title')"
    :carousel-images="carouselImages"
  />
</template>

<script setup>
import PatternAndToolPlaceholder from "../../components/PatternAndToolPlaceholder.vue";
import { useI18n } from "vue-i18n";
import { getLocalizedImagePath } from "@/lib/imageUtils";
import sweaterMockupEnWebp from "@/assets/screenshot-sweater-en.webp";
import sweaterMockupIsWebp from "@/assets/screenshot-sweater-is.webp";
import sweaterMockupEnPng from "@/assets/screenshot-sweater-en.png";
import sweaterMockupIsPng from "@/assets/screenshot-sweater-is.png";
import sweaterMockup2En from "@/assets/screenshot-sweater-2-en.png";
import sweaterMockup2Is from "@/assets/screenshot-sweater-2-is.png";
import sweaterMockupFiWebp from "@/assets/screenshot-sweater-fi.webp";
import sweaterMockupFiPng from "@/assets/screenshot-sweater-fi.png";
import sweaterMockup2Fi from "@/assets/screenshot-sweater-2-fi.png";

const { locale } = useI18n();

const imageMapWebp = {
  en: sweaterMockupEnWebp,
  is: sweaterMockupIsWebp,
  fi: sweaterMockupFiWebp,
};

const imageMapPng = {
  en: sweaterMockupEnPng,
  is: sweaterMockupIsPng,
  fi: sweaterMockupFiPng,
};

const detailImageMap = {
  en: sweaterMockup2En,
  is: sweaterMockup2Is,
  fi: sweaterMockup2Fi,
};

const getLocalizedWebpScreenshotPath = getLocalizedImagePath(imageMapWebp, locale);
const getLocalizedPngScreenshotPath = getLocalizedImagePath(imageMapPng, locale);
const getLocalizedDetailScreenshotPath = getLocalizedImagePath(detailImageMap, locale);

const carouselImages = [
  {
    source: "sweater-pattern.jpg",
    alt: "ribbed fitted raglan sweater",
  },
  {
    source: "our_patterns_raglan_sweater.jpg",
    alt: "raglan sweaters made from leftover yarn",
  },
  {
    source: "sweater_child_embroidered.jpg",
    alt: "embroidered child's raglansweater",
  },
  {
    source: "sweater_adult_tailored.jpg",
    alt: "tailored adult raglansweater",
  },
];
</script>
