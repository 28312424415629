<template>
  <pattern-and-tool-placeholder
    :title="$t('common7.Change_gauge')"
    :description="$t('gauge_converter.tool_description')"
    :first-bubble-text="$t('gauge_converter_placeholder.First_bubble')"
    :second-bubble-text="$t('gauge_converter_placeholder.Second_bubble')"
    :third-bubble-text="$t('gauge_converter_placeholder.Third_bubble')"
    :screenshot-path="getLocalizedWebpScreenshotPath"
    :screenshot-fallback-path="getLocalizedPngScreenshotPath"
    :detail-screenshot-path="getLocalizedDetailScreenshotPath"
    :screenshot-alt="getLocalizedPngScreenshotPath"
    :detail-screenshot-alt="getLocalizedDetailScreenshotPath"
    color="red"
    bubble-color="yellow"
    :sign-in-text="$t('placeholders.tool_sign_in_title')"
  />
</template>

<script setup>
import PatternAndToolPlaceholder from "../../components/PatternAndToolPlaceholder.vue";
import { useI18n } from 'vue-i18n';
import { getLocalizedImagePath } from '@/lib/imageUtils';
import gaugeConverterMockupEnWebp from '@/assets/gauge-converter-mockup-en.webp';
import gaugeConverterMockupIsWebp from '@/assets/gauge-converter-mockup-is.webp';
import gaugeConverterMockupEnPng from '@/assets/gauge-converter-mockup-en.png';
import gaugeConverterMockupIsPng from '@/assets/gauge-converter-mockup-is.png';
import gaugeConverterMockup2En from '@/assets/gauge-converter-mockup-2-en.png';
import gaugeConverterMockup2Is from '@/assets/gauge-converter-mockup-2-is.png';
import gaugeConverterMockupFiWebp from '@/assets/gauge-converter-mockup-fi.webp';
import gaugeConverterMockupFiPng from '@/assets/gauge-converter-mockup-fi.png';
import gaugeConverterMockup2Fi from '@/assets/gauge-converter-mockup-2-fi.png';
const { locale } = useI18n();

const imageMapWebp = {
  en: gaugeConverterMockupEnWebp,
  is: gaugeConverterMockupIsWebp,
  fi: gaugeConverterMockupFiWebp,
};

const imageMapPng = {
  en: gaugeConverterMockupEnPng,
  is: gaugeConverterMockupIsPng,
  fi: gaugeConverterMockupFiPng,
};

const detailImageMap = {
  en: gaugeConverterMockup2En,
  is: gaugeConverterMockup2Is,
  fi: gaugeConverterMockup2Fi,
};

const getLocalizedWebpScreenshotPath = getLocalizedImagePath(imageMapWebp, locale);
const getLocalizedPngScreenshotPath = getLocalizedImagePath(imageMapPng, locale);
const getLocalizedDetailScreenshotPath = getLocalizedImagePath(detailImageMap, locale);
</script>
