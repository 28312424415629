<template>
  <div class="get-started-block">
    <front-page-section-title
      :title="$t('get_started.title')"
      color="var(--knittable-lav-darker)"
    ></front-page-section-title>

    <div class="sections-container">
      <!-- First section -->
      <v-row class="section-row">
        <v-col cols="12" sm="12" md="6" class="section-col light-bg no-padding order-md-1 order-sm-2 order-2">
          <div class="image-wrapper">
            <div class="devices-layout">
              <img 
                :src="getLocalizedLaptopPath" 
                alt="Laptop view of Knittable"
                class="laptop"
              >
              <img 
                :src="getLocalizedIpadPath" 
                alt="iPad view of Knittable"
                class="ipad"
              >
              <img 
                :src="getLocalizedMobilePath" 
                alt="Mobile view of Knittable"
                class="mobile"
              >
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" class="section-col dark-bg order-md-2 order-sm-1 order-1">
          <div class="content-wrapper">
            <h2 class="section-title">{{ $t('get_started.create_account') }} <br><span class="free-account">{{ $t('get_started.free_account') }}</span></h2>
            <p class="section-description">
              <strong>{{ $t('get_started.no_downloads_text_bold') }}</strong>{{ $t('get_started.no_downloads_text') }}
            </p>
          </div>
        </v-col>
      </v-row>

      <!-- Second section -->
      <v-row class="section-row">
        <v-col cols="12" sm="12" md="6" class="section-col dark-bg order-md-1 order-sm-1 order-1">
          <div class="content-wrapper">
            <h2 class="section-title">{{ $t('get_started.explore_tools') }}</h2>
            <p class="section-description">
              <strong>{{ $t('get_started.tools_description_bold') }}</strong>{{ $t('get_started.tools_description') }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" class="section-col light-bg no-padding order-md-2 order-sm-2 order-2">
          <div class="image-wrapper">
            <div class="stacked-screenshots" ref="stackedScreenshotsRef">
              <img 
                :src="getLocalizedGaugeFromSwatchPath" 
                :alt="$t('common.Knitting_gauge_from_swatch')"
                class="section-img screenshot"
              >              
              <img 
                :src="getLocalizedKnitCalculatorPath" 
                :alt="$t('common7.Knit_calculator')"
                class="section-img screenshot"
              >
              <img 
                :src="getLocalizedGaugeConverterPath" 
                :alt="$t('common7.Change_gauge')"
                class="section-img screenshot"
              >
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- Third section -->
      <v-row class="section-row">
        <v-col cols="12" sm="12" md="6" class="section-col light-bg no-padding order-md-1 order-sm-2 order-2">
          <div class="image-wrapper">
            <div class="stacked-patterns" ref="stackedPatternsRef">
              <picture class="screenshot">
                <source :srcset="getLocalizedSocksWebpPath" type="image/webp">
                <img 
                  :src="getLocalizedSocksPngPath"
                  :alt="$t('common.Socks')"
                  class="section-img"
                >
              </picture>              
              <picture class="screenshot">
                <source :srcset="getLocalizedMittensWebpPath" type="image/webp">
                <img 
                  :src="getLocalizedMittensPngPath"
                  :alt="$t('common.Mittens')"
                  class="section-img"
                >
              </picture>              
              <picture class="screenshot">
                <source :srcset="getLocalizedBeanieWebpPath" type="image/webp">
                <img 
                  :src="getLocalizedBeaniePngPath"
                  :alt="$t('common.Beanie')"
                  class="section-img"
                >
              </picture>
              <picture class="screenshot">
                <source :srcset="getLocalizedSweaterWebpPath" type="image/webp">
                <img 
                  :src="getLocalizedSweaterPngPath"
                  :alt="$t('common.Sweater')"
                  class="section-img"
                >
              </picture>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" class="section-col dark-bg order-md-2 order-sm-1 order-1">
          <div class="content-wrapper">
            <h2 class="section-title">{{ $t('get_started.unlimited_custom_custom_patterns') }}</h2>
            <p class="section-description">
              <strong>{{ $t('get_started.pattern_description_bold') }}</strong> {{ $t('get_started.pattern_description') }}
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script setup>
import FrontPageSectionTitle from "./FrontPageSectionTitle.vue";
import { useI18n } from 'vue-i18n';
 import { getLocalizedImagePath } from "@/lib/imageUtils";
import { ref, onMounted, onUnmounted } from 'vue';


import gaugeConverterMockupEnWebp from '@/assets/gauge-converter-mockup-en.webp';
import gaugeConverterMockupIsWebp from '@/assets/gauge-converter-mockup-is.webp';
import gaugeConverterMockupFiWebp from '@/assets/gauge-converter-mockup-fi.webp';

import knitCalculatorMockupEnWebp from '@/assets/knit-calculator-mockup-en.webp';
import knitCalculatorMockupIsWebp from '@/assets/knit-calculator-mockup-is.webp';
import knitCalculatorMockupFiWebp from '@/assets/knit-calculator-mockup-fi.webp';

import gaugeFromSwatchMockupEnWebp from '@/assets/gauge-from-swatch-mockup-en.webp';
import gaugeFromSwatchMockupIsWebp from '@/assets/gauge-from-swatch-mockup-is.webp';
import gaugeFromSwatchMockupFiWebp from '@/assets/gauge-from-swatch-mockup-fi.webp';


import laptopEnWebp from '@/assets/device-screenshots/laptop-en.webp';
import laptopIsWebp from '@/assets/device-screenshots/laptop-is.webp';
import laptopFiWebp from '@/assets/device-screenshots/laptop-fi.webp';

import mobileEnWebp from '@/assets/screenshot-socks-en.webp';
import mobileIsWebp from '@/assets/screenshot-socks-is.webp';
import mobileFiWebp from '@/assets/screenshot-socks-fi.webp';

import ipadEnWebp from '@/assets/device-screenshots/ipad-en.webp';
import ipadIsWebp from '@/assets/device-screenshots/ipad-is.webp';
import ipadFiWebp from '@/assets/device-screenshots/ipad-fi.webp';


import mittensMockupEnWebp from "@/assets/screenshot-mittens-en.webp";
import mittensMockupIsWebp from "@/assets/screenshot-mittens-is.webp";
import mittensMockupFiWebp from "@/assets/screenshot-mittens-fi.webp";
import mittensMockupEnPng from "@/assets/screenshot-mittens-en.png";
import mittensMockupIsPng from "@/assets/screenshot-mittens-is.png";
import mittensMockupFiPng from "@/assets/screenshot-mittens-fi.png";

import sweaterMockupEnWebp from "@/assets/screenshot-sweater-en.webp";
import sweaterMockupIsWebp from "@/assets/screenshot-sweater-is.webp";
import sweaterMockupFiWebp from "@/assets/screenshot-sweater-fi.webp";
import sweaterMockupEnPng from "@/assets/screenshot-sweater-en.png";
import sweaterMockupIsPng from "@/assets/screenshot-sweater-is.png";
import sweaterMockupFiPng from "@/assets/screenshot-sweater-fi.png";

import beanieMockupEnWebp from "@/assets/screenshot-beanie-en.webp";
import beanieMockupIsWebp from "@/assets/screenshot-beanie-is.webp";
import beanieMockupFiWebp from "@/assets/screenshot-beanie-fi.webp";
import beanieMockupEnPng from "@/assets/screenshot-beanie-en.png";
import beanieMockupIsPng from "@/assets/screenshot-beanie-is.png";
import beanieMockupFiPng from "@/assets/screenshot-beanie-fi.png";

import socksMockupEnWebp from "@/assets/screenshot-socks-en.webp";
import socksMockupIsWebp from "@/assets/screenshot-socks-is.webp";
import socksMockupFiWebp from "@/assets/screenshot-socks-fi.webp";
import socksMockupEnPng from "@/assets/screenshot-socks-en.png";
import socksMockupIsPng from "@/assets/screenshot-socks-is.png";
import socksMockupFiPng from "@/assets/screenshot-socks-fi.png";

const { locale } = useI18n();

const gaugeConverterMap = {
  en: gaugeConverterMockupEnWebp,
  is: gaugeConverterMockupIsWebp,
  fi: gaugeConverterMockupFiWebp,
};

const knitCalculatorMap = {
  en: knitCalculatorMockupEnWebp,
  is: knitCalculatorMockupIsWebp,
  fi: knitCalculatorMockupFiWebp,
};

const gaugeFromSwatchMap = {
  en: gaugeFromSwatchMockupEnWebp,
  is: gaugeFromSwatchMockupIsWebp,
  fi: gaugeFromSwatchMockupFiWebp,
};

const laptopMap = {
  en: laptopEnWebp,
  is: laptopIsWebp,
  fi: laptopFiWebp,
};

const mobileMap = {
  en: mobileEnWebp,
  is: mobileIsWebp,
  fi: mobileFiWebp,
};

const ipadMap = {
  en: ipadEnWebp,
  is: ipadIsWebp,
  fi: ipadFiWebp,
};

// Replace the image maps
const mittensMapWebp = {
  en: mittensMockupEnWebp,
  is: mittensMockupIsWebp,
  fi: mittensMockupFiWebp,
};

const mittensMapPng = {
  en: mittensMockupEnPng,
  is: mittensMockupIsPng,
  fi: mittensMockupFiPng,
};

const sweaterMapWebp = {
  en: sweaterMockupEnWebp,
  is: sweaterMockupIsWebp,
  fi: sweaterMockupFiWebp,
};

const sweaterMapPng = {
  en: sweaterMockupEnPng,
  is: sweaterMockupIsPng,
  fi: sweaterMockupFiPng,
};

const beanieMapWebp = {
  en: beanieMockupEnWebp,
  is: beanieMockupIsWebp,
  fi: beanieMockupFiWebp,
};

const beanieMapPng = {
  en: beanieMockupEnPng,
  is: beanieMockupIsPng,
  fi: beanieMockupFiPng,
};

// Add the socks image maps
const socksMapWebp = {
  en: socksMockupEnWebp,
  is: socksMockupIsWebp,
  fi: socksMockupFiWebp,
};

const socksMapPng = {
  en: socksMockupEnPng,
  is: socksMockupIsPng,
  fi: socksMockupFiPng,
};

const getLocalizedGaugeConverterPath = getLocalizedImagePath(gaugeConverterMap, locale);
const getLocalizedKnitCalculatorPath = getLocalizedImagePath(knitCalculatorMap, locale);
const getLocalizedGaugeFromSwatchPath = getLocalizedImagePath(gaugeFromSwatchMap, locale);

const getLocalizedMittensWebpPath = getLocalizedImagePath(mittensMapWebp, locale);
const getLocalizedMittensPngPath = getLocalizedImagePath(mittensMapPng, locale);

const getLocalizedSweaterWebpPath = getLocalizedImagePath(sweaterMapWebp, locale);
const getLocalizedSweaterPngPath = getLocalizedImagePath(sweaterMapPng, locale);

const getLocalizedBeanieWebpPath = getLocalizedImagePath(beanieMapWebp, locale);
const getLocalizedBeaniePngPath = getLocalizedImagePath(beanieMapPng, locale);

const getLocalizedSocksWebpPath = getLocalizedImagePath(socksMapWebp, locale);
const getLocalizedSocksPngPath = getLocalizedImagePath(socksMapPng, locale);

const getLocalizedLaptopPath = getLocalizedImagePath(laptopMap, locale);
const getLocalizedMobilePath = getLocalizedImagePath(mobileMap, locale);
const getLocalizedIpadPath = getLocalizedImagePath(ipadMap, locale);

const stackedPatternsRef = ref(null);
const stackedScreenshotsRef = ref(null);

onMounted(() => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('is-visible');
      } else {
        entry.target.classList.remove('is-visible');
      }
    });
  }, {
    threshold: 0.3
  });

  if (stackedPatternsRef.value) {
    observer.observe(stackedPatternsRef.value);
  }
  if (stackedScreenshotsRef.value) {
    observer.observe(stackedScreenshotsRef.value);
  }

  // Cleanup
  onUnmounted(() => {
    if (stackedPatternsRef.value) {
      observer.unobserve(stackedPatternsRef.value);
    }
    if (stackedScreenshotsRef.value) {
      observer.unobserve(stackedScreenshotsRef.value);
    }
  });
});
</script>

<style scoped lang="scss">
@import "../../scss/styles.scss";

.get-started-block {
  background-color: white;
}

.sections-container {
  margin-top: 4rem;
}

.section-row {
  margin: 0;
}

.section-col {
  padding: 0;
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.light-bg {
    background-color: var(--knittable-lav-lighter);
  }

  &.dark-bg {
    background-color: var(--knittable-lav-light);
  }
}

.content-wrapper {
  padding: 4rem;
  max-width: 600px;
  width: 100%;
}

.image-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  position: relative;
}

.stacked-screenshots {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 600px;
  max-height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .screenshot {
    position: absolute;
    width: auto;
    height: 90%;
    max-width: 33%;
    transition: transform 0.6s ease;
    object-fit: contain;

    &:nth-child(1) {
      transform: rotate(0deg) translateX(-65%);
      z-index: 1;
    }

    &:nth-child(2) {
      transform: rotate(0deg) translateX(0%);
      z-index: 2;
    }

    &:nth-child(3) {
      transform: rotate(0deg) translateX(65%);
      z-index: 3;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &.is-visible {
    .screenshot {
      &:nth-child(1) {
        transform: rotate(-12deg) translateX(-85%);
      }
      
      &:nth-child(2) {
        transform: rotate(0deg) translateX(0%);
      }

      &:nth-child(3) {
        transform: rotate(12deg) translateX(85%);
      }
    }
  }
}

.stacked-patterns {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 600px;
  max-height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .screenshot {
    position: absolute;
    width: auto;
    height: 90%;
    max-width: 35%;
    transition: transform 0.6s ease;
    object-fit: contain;

    &:nth-child(1) {
      transform: rotate(0deg) translateX(0%);
      z-index: 1;
    }

    &:nth-child(2) {
      transform: rotate(0deg) translateX(0%);
      z-index: 2;
    }

    &:nth-child(3) {
      transform: rotate(0deg) translateX(0%);
      z-index: 3;
    }

    &:nth-child(4) {
      transform: rotate(0deg) translateX(0%);
      z-index: 4;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &.is-visible {
    .screenshot {
      &:nth-child(1) {
        transform: rotate(0deg) translateX(-100%);
      }
      
      &:nth-child(2) {
        transform: rotate(0deg) translateX(-35%);
      }

      &:nth-child(3) {
        transform: rotate(0deg) translateX(35%);
      }

      &:nth-child(4) {
        transform: rotate(0deg) translateX(100%);
      }
    }
  }
}

.stacked-pricing-screenshots {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 600px;
  max-height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .screenshot {
    position: absolute;
    width: auto;
    height: 90%;
    max-width: 33%;
    border-radius: 1.5rem;
    object-fit: contain;

    &:nth-child(1) {
      transform: translateX(-85%) scale(0.9);
      z-index: 1;
      height: 85%;
    }

    &:nth-child(2) {
      transform: translateX(0) scale(1.1);
      z-index: 3;
      height: 95%;
    }

    &:nth-child(3) {
      transform: translateX(85%) scale(0.9);
      z-index: 1;
      height: 85%;
    }
  }
}

.devices-layout {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 600px;
  max-height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .laptop {
    width: 90%;
    height: auto;
    position: relative;
    z-index: 1;
  }

  .ipad {
    position: absolute;
    width: 40%;
    height: auto;
    transform: translate(46%, 10%);
    z-index: 2;
  }

  .mobile {
    position: absolute;
    width: 20%;
    height: auto;
    transform: translate(160%, 30%);
    z-index: 3;
  }
}

.app-sm-and-down {
  .section-col {
    height: 350px;
    min-height: 350px;

    &.no-padding {
      height: 350px;
    }
  }

  .content-wrapper {
    padding: 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .image-wrapper {
    height: 100%;
  }

  .section-img {
    height: 100%;
    object-fit: contain;
  }

  .section-title {
    font-size: 2rem;
  }

  .section-subtitle {
    font-size: 1.75rem;
  }

  .section-description {
    font-size: 1.125rem;
  }

  .stacked-screenshots {
    max-width: 500px;
    
    .screenshot {
      height: 85%;
      max-width: 40%;

      &:nth-child(1) {
        transform: rotate(-5deg) translateX(-55%);
      }

      &:nth-child(3) {
        transform: rotate(5deg) translateX(55%);
      }
    }

    &.is-visible {
      .screenshot {
        &:nth-child(1) {
          transform: rotate(-8deg) translateX(-60%);
        }
        
        &:nth-child(3) {
          transform: rotate(8deg) translateX(60%);
        }
      }
    }
  }

  .stacked-pricing-screenshots {
    max-width: 500px;
    
    .screenshot {
      max-width: 40%;

      &:nth-child(1) {
        transform: translateX(-85%);
        height: 80%;
      }

      &:nth-child(2) {
        height: 90%;
      }

      &:nth-child(3) {
        transform: translateX(85%);
        height: 80%;
      }
    }
  }

  .devices-layout {
    max-width: 500px;

    .laptop {
      width: 85%;
    }

    .ipad {
      width: 38%;
      transform: translate(35%, 9%);
    }

    .mobile {
      width: 20%;
      transform: translate(149%, 30%);
    }
  }

  .stacked-patterns {
    margin-left: 1rem;
    margin-right: 1rem;
    max-width: 500px;
    
    .screenshot {
      max-width: 35%;

      &:nth-child(1) {
        transform: translateX(-90%);
      }

      &:nth-child(2) {
        transform: translateX(-30%);
      }

      &:nth-child(3) {
        transform: translateX(30%);
      }

      &:nth-child(4) {
        transform: translateX(90%);
      }
    }

    &.is-visible {
      .screenshot {
      &:nth-child(1) {
        transform: scale(1.2) rotate(0deg) translateX(-100%);
      }
      
      &:nth-child(2) {
        transform: scale(1.2) rotate(0deg) translateX(-35%);
      }

      &:nth-child(3) {
        transform: scale(1.2) rotate(0deg) translateX(35%);
      }

      &:nth-child(4) {
        transform: scale(1.2) rotate(0deg) translateX(100%);
      }
    }
    }
  }
}

.section-title {
  @include heading6;
  color: var(--knittable-lav-darker);
  margin-bottom: 1rem;
}

.section-subtitle {
  @include heading7;
  color: var(--knittable-begonia);
  margin-bottom: 1.5rem;
}

.section-description {
  @include paragraphLargeRegular;
  color: var(--knittable-text);
}

.section-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style> 