<template>
  <span>{{ formattedTime }}</span>
</template>

<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { formatTimestampToReadable } from "../../lib/timestampUtils";

const props = defineProps({
  timestamp: {
    type: [String, Number, Date],
    required: true,
  },
  useRelative: {
    type: Boolean,
    default: true,
  },
  maxRelativeAge: {
    type: Number,
    default: 604800, // 1 week
  },
});

const { t } = useI18n();

const formattedTime = computed(() => {
  const date = new Date(props.timestamp);
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);

  if (props.useRelative && diffInSeconds < props.maxRelativeAge) {

    if (diffInSeconds < 60) {
      return t("time_format.just_now");
    }

    if (diffInSeconds < 3600) {
      return t("time_format.x_minutes_ago", { count: Math.floor(diffInSeconds / 60) });
    }

    if (diffInSeconds < 86400) {
      return t("time_format.x_hours_ago", { count: Math.floor(diffInSeconds / 3600) });
    }

    if (diffInSeconds < 604800) {
      return t("time_format.x_days_ago", { count: Math.floor(diffInSeconds / 86400) });
    }
  }

  return formatTimestampToReadable(date);
});
</script>
