<template>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <v-row
    v-if="active && subscription?.planId === null"
    justify="center"
    class="app-pricing-card-row ma-0"
  >
    <div class="free-trial-info">
      <subscription-notification></subscription-notification>
    </div>
  </v-row>
  <v-row class="app-pricing-card-row ma-0">
    <v-col cols="12" sm="6" md="4">
      <pricing-card
        :active="active"
        subscription-type="free"
        :subscription="subscription"
        :email="email"
      ></pricing-card>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <pricing-card
        :active="active"
        subscription-type="monthly"
        :subscription="subscription"
        :email="email"
      ></pricing-card>
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <pricing-card
        :active="active"
        subscription-type="yearly"
        :subscription="subscription"
        :email="email"
      ></pricing-card>
    </v-col>
  </v-row>
</template>

<script setup>
import { ref, onMounted } from "vue";

import { getSubscription } from "../services/access";
import { useAuthStore } from "../stores/auth";

import SubscriptionNotification from "./SubscriptionNotification.vue";
import PricingCard from "./PricingCard.vue";

const authStore = useAuthStore();

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
});

const email = ref(null);
const subscription = ref(null);

const loading = ref(false);

onMounted(() => {
  if (props.active) {
    loadSubscriptionInfo();
  }
});

async function loadSubscriptionInfo() {
  try {
    loading.value = true;
    subscription.value = await getSubscription();
    const user = await authStore.getUser();
    email.value = user.email;
  } catch (error) {
    alert(error.msg);
  } finally {
    loading.value = false;
  }
}
</script>

<style scoped lang="scss">
@import "./../../scss/styles.scss";

.app-sm-and-down .app-pricing-title {
  font-size: 3rem;
  text-align: left;

  padding-top: 5rem;
  padding-left: 1.5rem;
  margin-bottom: 5rem;
}

.app-pricing-card-row {
  padding-left: 7.5rem;
  padding-right: 7.5rem;
  padding-bottom: 3rem;
}

.app-sm-and-down .app-pricing-card-row {
  padding-left: 1rem;
  padding-right: 1rem;
}

.free-trial-info {
  padding-top: 2rem;
}
</style>
