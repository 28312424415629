export function formatTimestampToReadable(timestamp) {
  const newTimestamp = new Date(timestamp);
  return new Intl.DateTimeFormat("default", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  }).format(newTimestamp);
}
