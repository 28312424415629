<template>
  <pattern-and-tool-placeholder
    :title="$t('common.Beanie')"
    :description="$t('beanie.pattern_description')"
    :first-bubble-text="$t('beanie_placeholder.First_bubble')"
    :second-bubble-text="$t('beanie_placeholder.Second_bubble')"
    :third-bubble-text="$t('beanie_placeholder.Third_bubble')"
    :screenshot-path="getLocalizedWebpScreenshotPath"
    :screenshot-fallback-path="getLocalizedPngScreenshotPath"
    :detail-screenshot-path="getLocalizedDetailScreenshotPath"
    :screenshot-alt="getLocalizedPngScreenshotPath"
    :detail-screenshot-alt="getLocalizedDetailScreenshotPath"
    color="purple"
    bubble-color="yellow"
    :sign-in-text="$t('placeholders.pattern_sign_in_title')"
    :carousel-images="carouselImages"
  />
</template>

<script setup>
import PatternAndToolPlaceholder from "../../components/PatternAndToolPlaceholder.vue";
import { useI18n } from "vue-i18n";
import { getLocalizedImagePath } from "@/lib/imageUtils";
import beanieMockupEnWebp from "@/assets/screenshot-beanie-en.webp";
import beanieMockupIsWebp from "@/assets/screenshot-beanie-is.webp";
import beanieMockupEnPng from "@/assets/screenshot-beanie-en.png";
import beanieMockupIsPng from "@/assets/screenshot-beanie-is.png";
import beanieMockup2En from "@/assets/screenshot-beanie-2-en.png";
import beanieMockup2Is from "@/assets/screenshot-beanie-2-is.png";
import beanieMockup2Fi from "@/assets/screenshot-beanie-2-fi.png";
import beanieMockupFiWebp from "@/assets/screenshot-beanie-fi.webp";
import beanieMockupFiPng from "@/assets/screenshot-beanie-fi.png";

const { locale } = useI18n();

const imageMapWebp = {
  en: beanieMockupEnWebp,
  is: beanieMockupIsWebp,
  fi: beanieMockupFiWebp,
};

const imageMapPng = {
  en: beanieMockupEnPng,
  is: beanieMockupIsPng,
  fi: beanieMockupFiPng,
};

const detailImageMap = {
  en: beanieMockup2En,
  is: beanieMockup2Is,
  fi: beanieMockup2Fi,
};

const getLocalizedWebpScreenshotPath = getLocalizedImagePath(imageMapWebp, locale);
const getLocalizedPngScreenshotPath = getLocalizedImagePath(imageMapPng, locale);
const getLocalizedDetailScreenshotPath = getLocalizedImagePath(detailImageMap, locale);

const carouselImages = [
  {
    source: "our_patterns_beanie.jpg",
    alt: "yellow double ribbed beanie",
  },
  {
    source: "beanie-pattern.jpg",
    alt: "blue double ribbed beanie",
  },
  {
    source: "beanie_child_pair.jpg",
    alt: "single ribbed child beanies",
  },
  {
    source: "beanie_adult_blue.jpg",
    alt: "blue single ribbed beanie",
  },
];
</script>
