<template>
  <router-link :to="getTo()" :class="[{ 'underline': underline }]">
    <slot />
  </router-link>
</template>

<script setup>
import { useLangStore } from "./../stores/lang";

import { composeRoute } from "./../lib/navigationUtils.js";

const langStore = useLangStore();
const locale = langStore.getLocale();

const props = defineProps({
  to: {
    type: String,
    required: true,
  },
  next: {
    type: String,
  },
  params: {
    type: Object,
    default: () => ({})
  },
  underline: {
    type: Boolean,
    default: true,
  },
});

const getTo = () => {
  const route = composeRoute(locale.value, props.to, props.next);
  
  // Add any additional params to the route
  if (props.params && Object.keys(props.params).length > 0) {
    route.params = { ...route.params, ...props.params };
  }
  
  return route;
};
</script>

<style scoped>
.underline {
  text-decoration: none;
}
</style>
