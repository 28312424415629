import { defineStore } from "pinia";

import { formatTimestampToReadable } from "../lib/timestampUtils";

import {
  loadCurrentProject,
  loadProject,
  getProjects,
  saveProject,
  updateProject,
  updateProjectName,
  saveBookmark,
  deleteProject,
} from "../services/project";

function getFormattedTimestamp(project) {
  const timestamp = project.updatedAt ? new Date(project.updatedAt) : new Date(project.createdAt);
  return formatTimestampToReadable(timestamp);
}

export const useProjectStateStore = (patternType, defaultSettings) => {
  const store = defineStore(`project-state-${patternType}`, {
    state: () => ({
      patternType: patternType,
      isNewProjectModalOpen: false,
      defaultSettings: defaultSettings,
      currentSettings: defaultSettings,
      storedSettings: null,
      bookmark: null,
      latestVersion: null,
      version: null,
      name: null,
      lastSavedAt: null,
      hasChanges: false,
      startingFromScratch: true,
      projectId: null,
    }),
    actions: {
      async initializeProjectState(latestVersion, projectId = null) {
        this.latestVersion = latestVersion;
        let project;
        if (projectId) {
          project = await loadProject(this.patternType, projectId);
        } else {
          project = await loadCurrentProject(this.patternType);
        }

        if (project) {
          this.updateProjectState(project);
          this.resetToStoredSettings();
          return;
        }
        this.resetToDefaultSettings();
      },
      async addOrUpdateProject() {
        if (this.projectId) {
          await this.performUpdateProject(this.projectId);
        } else {
          await this.performSaveProject(null, true);
        }
      },
      async performUpdateProject(projectId) {
        const updatedProject = await updateProject(
          this.patternType,
          projectId,
          this.currentSettings,
          this.version
        );
        this.updateProjectState(updatedProject);
        this.checkIfHasChanges();
      },
      async performUpdateProjectName(name) {
        const updatedProject = await updateProjectName(this.patternType, this.projectId, name);
        this.updateProjectState(updatedProject);
      },
      async performSaveProject(projectName, baseOnCurrentProject) {
        let savedProject;
        if (baseOnCurrentProject) {
          savedProject = await saveProject(
            this.patternType,
            this.version,
            this.currentSettings,
            this.bookmark,
            projectName
          );
        } else {
          savedProject = await saveProject(
            this.patternType,
            this.latestVersion,
            this.defaultSettings,
            null,
            projectName
          );
        }
        this.updateProjectState(savedProject, !baseOnCurrentProject);
        this.resetToStoredSettings(!baseOnCurrentProject);
        this.checkIfHasChanges();
        return savedProject.id;
      },
      async performDeleteProject() {
        await deleteProject(this.patternType, this.projectId);
        this.resetToDefaultSettings();
      },
      resetToDefaultSettings() {
        this.currentSettings = JSON.parse(JSON.stringify(this.defaultSettings));
        this.version = this.latestVersion;
        this.startingFromScratch = true;
        this.projectId = null;
        this.storedSettings = null;
        this.bookmark = null;
        this.name = null;
        this.lastSavedAt = null;
      },
      resetToStoredSettings(startingFromScratch = false) {
        if (this.patternType === "sweater") {
          if (!this.storedSettings.applyNeckShaping) {
            // Neck shaping is not supported in the old patterns
            this.storedSettings.applyNeckShaping = false;
          }
          if (!Object.hasOwn(this.storedSettings, "neckbandType")) {
            this.storedSettings.neckbandType = {
              value: "folded",
            };
          }
        }
        this.currentSettings = {
          ...this.currentSettings,
          ...JSON.parse(JSON.stringify(this.storedSettings)), // disconnect from storedSettings
        };

        this.startingFromScratch = startingFromScratch;
        return;
      },
      async saveBookmark(bookmark) {
        const savedProject = await saveBookmark(this.patternType, this.projectId, bookmark);
        this.bookmark = savedProject.bookmark;

        this.lastSavedAt = getFormattedTimestamp(savedProject);
      },
      newVersionAvailable(latestVersion) {
        return latestVersion > this.version;
      },
      checkIfHasChanges() {
        this.hasChanges =
          this.storedSettings == null ||
          JSON.stringify(this.currentSettings) !== JSON.stringify(this.storedSettings);
      },
      updateProjectState(project, startingFromScratch = false) {
        if (project) {
          this.storedSettings = project.settings;
          this.bookmark = project.bookmark;
          this.version = project.patternVersion;
          this.name = project.name;
          this.lastSavedAt = getFormattedTimestamp(project);
          this.startingFromScratch = startingFromScratch;
          this.projectId = project.id;
        }
      },
    },
  })();

  return store;
};
