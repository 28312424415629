import matter from 'gray-matter';
import { marked } from 'marked';
import i1Ban from '../i18n';

// Function to parse markdown content with front matter
export function parseMarkdown(markdown) {
    const { data, content } = matter(markdown);
    return {
        ...data,
        content: marked(content),
    };
}

// Get all posts for the current locale
export async function getAllPosts(locale = null) {
    const currentLocale = locale || i1Ban.getLocale();

    // Get all blog post folders
    const postFolders = require.context('../../content/blog', true, /\.md$/);

    // Group files by folder (post)
    const postsByFolder = {};
    postFolders.keys().forEach(key => {
        // Extract folder name and locale from path
        // e.g., "./felted-mittens/en.md" -> folder: "felted-mittens", locale: "en"
        const match = key.match(/^\.\/([^/]+)\/([^/]+)\.md$/);
        if (match) {
            const [, folder, fileLocale] = match;
            if (!postsByFolder[folder]) {
                postsByFolder[folder] = {};
            }
            postsByFolder[folder][fileLocale] = key;
        }
    });

    // For each post folder, find the best locale version
    const posts = await Promise.all(
        Object.keys(postsByFolder).map(async (folder) => {
            // Try to find the post in the current locale
            let postKey = postsByFolder[folder][currentLocale] || null;

            // If not found in current locale, try English
            if (!postKey && currentLocale !== 'en') {
                postKey = postsByFolder[folder]['en'] || null;
            }

            // If not found in English, try the default locale
            if (!postKey) {
                const availableLocales = Object.keys(postsByFolder[folder]);
                if (availableLocales.length > 0) {
                    postKey = postsByFolder[folder][availableLocales[0]];
                }
            }

            // If we found a version of the post, parse and return it
            if (postKey) {
                const markdown = postFolders(postKey).default;
                const post = parseMarkdown(markdown);
                return {
                    ...post,
                    slug: folder,
                };
            }

            return null;
        })
    );

    // Filter out null posts and sort by date
    return posts
        .filter(post => post !== null)
        .sort((a, b) => new Date(b.date) - new Date(a.date));
}

// Helper function to check if a localized version exists for a post
function isLocalizedVersionExists(filename, allFiles) {
    for (const locale of Object.keys(i1Ban.supportedLocales)) {
        if (allFiles.includes(`./${locale}-${filename}.md`)) {
            return true;
        }
    }
    return false;
} 