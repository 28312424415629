<template>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <div class="app-page-top-spacer"></div>

  <v-row class="pt-4 pb-12">
    <v-col v-if="mdAndUp" cols="12" sm="6" md="2" order-md="1" order-sm="1" order-xs="1"></v-col>
    <v-col cols="12" sm="12" md="2" class="pattern-utils-col" order-md="3">
      <div class="pattern-utils-bar">
        <subscription-lock-tooltip
          v-if="!authStore.isPartnerOrSubscribed"
          class="subscription-lock-icon"
          @go-to-subscription="goToSubscription"
        />
        <div class="pattern-util-container show-settings">
          <pattern-util
            :tooltipText="
              showSettings
                ? $t('pattern_library.Hide_settings')
                : $t('pattern_library.Show_settings')
            "
            icon="mdi-pencil"
            @click="toggleShowSettings"
            :disabled="props.disabled || !authStore.isPartnerOrSubscribed"
            :inverted="showSettings"
          ></pattern-util>
        </div>

        <div v-if="isProjectLibraryEnabled" class="pattern-util-container">
          <pattern-util
            :tooltipText="$t('pattern_library.Start_new_draft')"
            icon="mdi-plus"
            :disabled="!projectStateStore.projectId || !authStore.isPartnerOrSubscribed"
            @click="projectStateStore.isNewProjectModalOpen = true"
          ></pattern-util>
        </div>

        <div v-if="isProjectLibraryEnabled" class="pattern-util-container load-pattern">
          <pattern-util
            :tooltipText="$t('pattern_library.Load_project')"
            icon="mdi-folder"
            @click="loadProjectDialog = true"
            :disabled="!authStore.isPartnerOrSubscribed"
          ></pattern-util>
        </div>

        <div class="pattern-util-container">
          <pattern-util
            :tooltipText="$t('pattern_library.Download_pdf')"
            icon="mdi-pdf-box"
            @click="downloadPdf"
            :disabled="props.disabled || !authStore.isPartnerOrSubscribed"
          ></pattern-util>
        </div>

        <div class="pattern-util-container">
          <pattern-util
            :tooltipText="$t('pattern_library.Delete_project')"
            icon="mdi-delete"
            @click="deleteDialog = true"
            :disabled="!projectStateStore.projectId || !authStore.isPartnerOrSubscribed"
          ></pattern-util>
        </div>
      </div>
    </v-col>
    <span id="hidden-title-span" class="hidden-span title">{{ editedTitle }}</span>

    <v-col cols="12" sm="12" md="8" order-md="2">
      <div class="title-container">
        <span v-if="projectStateStore.lastSavedAt" class="saved-status-icon">
          <v-tooltip>
            <template #activator="{ props }">
              <v-icon v-bind="props" v-if="projectStateStore.lastSavedAt">mdi-cloud-check</v-icon>
              <v-icon v-bind="props" v-else>mdi-cloud-alert</v-icon>
            </template>
            <span>{{
              $t("pattern_library.Last_saved_at", { savedAt: projectStateStore.lastSavedAt })
            }}</span>
          </v-tooltip>
        </span>

        <div
          class="title-wrapper"
          @mouseover="showEditHint = true"
          @mouseleave="showEditHint = false"
          :class="{
            'title-hover': showEditHint && !isEditing && projectStateStore.projectId,
            'title-underlined':!isEditing && projectStateStore.projectId,
          }"
          :disabled="true"
        >
          <v-tooltip :disabled="isEditing || !projectStateStore.projectId">
            <template #activator="{ props: tooltipProps }">
              <h3
                v-if="!isEditing"
                @click="startEditing"
                ref="titleRef"
                class="title"
                v-bind="tooltipProps"
              >
                {{ title }}
              </h3>
              <input
                v-else
                ref="titleInputRef"
                v-model="editedTitle"
                class="title-input title"
                type="text"
                maxlength="100"
                @keyup.enter="saveTitle"
                @keyup.esc="cancelEdit"
                @input="resizeInput"
              />
            </template>
            <span>{{ $t("pattern_library.Click_to_rename") }}</span>
          </v-tooltip>
        </div>

        <div class="edit-actions" :class="{ 'is-editing': isEditing }">
          <div class="character-counter" v-if="isEditing">{{ editedTitle.length }}/100</div>
          <v-icon
            :class="['edit-action-icon save-icon', { disabled: isSaveTitleDisabled }]"
            @click="saveTitle"
          >
            mdi-check
          </v-icon>
          <v-icon class="edit-action-icon cancel-icon" @click="cancelEdit"> mdi-close </v-icon>
        </div>

        <v-snackbar
          v-model="showSuccessMessage"
          :timeout="2000"
          location="top"
          rounded="pill"
          elevation="4"
          class="custom-snackbar"
        >
          <div class="d-flex align-center">
            <v-icon class="mr-2">mdi-check-circle</v-icon>
            {{ $t("pattern_library.Name_updated") }}
          </div>
        </v-snackbar>
      </div>
    </v-col>
  </v-row>
  <new-project-modal />
  <load-project-modal v-model="loadProjectDialog" />
  <delete-project-modal v-model="deleteDialog" @confirm="confirmDelete" />
</template>

<script setup>
import { defineProps, ref, watch, nextTick, computed, onMounted } from "vue";
import { useDisplay } from "vuetify";
import PatternUtil from "./PatternUtil.vue";
import html2pdf from "html3pdf";
import avo from "./../../userTracking/Avo";
import LoadProjectModal from "./LoadProjectModal.vue";
import NewProjectModal from "./NewProjectModal.vue";
import { useProjectStateStore } from "../../stores/projectState";
import { useRoute, useRouter } from "vue-router";
import { useLangStore } from "../../stores/lang";
import { composeRoute } from "../../lib/navigationUtils";
import DeleteProjectModal from "./DeleteProjectModal.vue";
import SubscriptionLockTooltip from "../SubscriptionLockTooltip.vue";
import { useAuthStore } from "../../stores/auth";

const route = useRoute();
const router = useRouter();
const patternType = route.meta.patternType;
const projectStateStore = useProjectStateStore(patternType);
const langStore = useLangStore();
const locale = langStore.getLocale();
const authStore = useAuthStore();
const isProjectLibraryEnabled = process.env.VUE_APP_ENABLE_PROJECT_LIBRARY === "true";

const { mdAndUp } = useDisplay();
const loading = ref(false);

const props = defineProps({
  defaultTitle: {
    type: String,
    required: true,
  },
  showInformation: {
    type: Boolean,
    required: true,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: true,
    default: false,
  },
  showSettings: {
    type: Boolean,
    required: true,
    default: false,
  },
});

const showInformation = ref(props.showInformation);
const showSettings = ref(props.showSettings);
const titleRef = ref(null);
const emit = defineEmits(["update:showInformation", "update:showSettings"]);

const deleteDialog = ref(false);
const loadProjectDialog = ref(false);
const isEditing = ref(false);
const showEditHint = ref(false);
const showSuccessMessage = ref(false);
const titleInputRef = ref(null);
const previousTitle = ref("");
const editedTitle = ref("");

const title = computed(() => {
  return projectStateStore.name || props.defaultTitle;
});

watch(
  () => showInformation.value,
  (newValue) => {
    emit("update:showInformation", newValue);
  }
);

const toggleShowSettings = () => {
  showSettings.value = !showSettings.value;
};
watch(
  () => showSettings.value,
  (newValue) => {
    emit("update:showSettings", newValue);
  }
);
watch(
  () => props.showSettings,
  (newValue) => {
    showSettings.value = newValue;
  }
);

async function confirmDelete() {
  try {
    loading.value = true;
    await projectStateStore.performDeleteProject();
    router.push(composeRoute(locale.value, patternType));
  } catch (error) {
    alert("Error deleting project:", error);
  } finally {
    deleteDialog.value = false;
    loading.value = false;
  }
}

async function downloadPdf() {
  if (props.disabled) {
    return;
  }
  const storedShowInformationValue = showInformation.value;

  const combinedContent = document.createElement("div");
  const patternContainer = document.getElementById("pattern-container");
  if (!patternContainer) {
    console.error("Pattern container not found");
    return;
  }

  showInformation.value = true;
  // Wait for the DOM to update
  nextTick(async () => {
    try {
      const titleElement = titleRef.value;
      const titleText = titleElement ? titleElement.textContent : props.defaultTitle;
      combinedContent.appendChild(titleElement.cloneNode(true));

      // Create a temporary container to hold the innerHTML
      const tempContainer = document.createElement("div");
      tempContainer.innerHTML = patternContainer.innerHTML;
      while (tempContainer.firstChild) {
        combinedContent.appendChild(tempContainer.firstChild);
      }

      showInformation.value = storedShowInformationValue;
      loading.value = true;
      const opt = {
        margin: 0.5,
        filename: `${titleText}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        documentProperties: { title: `${titleText}.pdf` },
        pagebreak: { mode: ["avoid-all"] },
      };

      await html2pdf().set(opt).from(combinedContent).save();
      avo.createPdf({ patternType: patternType });
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      loading.value = false;
      showInformation.value = storedShowInformationValue;
    }
  });
}

const startEditing = () => {
  if (!projectStateStore.projectId) {
    return;
  }

  previousTitle.value = projectStateStore.name || props.defaultTitle;
  editedTitle.value = previousTitle.value;
  isEditing.value = true;
  // Focus the input field after the next DOM update
  nextTick(() => {
    resizeInput();
    titleInputRef.value?.focus();
  });
};

const resizeInput = () => {
  const hiddenSpan = document.getElementById("hidden-title-span");
  if (hiddenSpan && titleInputRef.value) {
    const width = hiddenSpan.offsetWidth + 5; // Add slight padding buffer
    titleInputRef.value.style.width = `${width}px`;
    titleInputRef.value.scrollLeft = titleInputRef.value.scrollWidth;
  }
};

const cancelEdit = () => {
  isEditing.value = false;
  editedTitle.value = "";
};

const isSaveTitleDisabled = computed(() => {
  return editedTitle.value === "" || editedTitle.value === previousTitle.value;
});

const saveTitle = async () => {
  if (isSaveTitleDisabled.value) {
    return;
  }

  if (!projectStateStore.projectId) {
    return;
  }

  if (editedTitle.value.trim() && editedTitle.value !== previousTitle.value) {
    try {
      loading.value = true;
      await projectStateStore.performUpdateProjectName(editedTitle.value.trim());
      showSuccessMessage.value = true;
    } catch (error) {
      alert("Error updating project name:", error);
    } finally {
      loading.value = false;
    }
  }
  isEditing.value = false;
};

const goToSubscription = () => {
  router.push(composeRoute(locale.value, "subscription", route.name));
};
</script>

<style scoped lang="scss">
@import "./../../../scss/styles.scss";
@import "./../../../scss/modal.scss";

.title {
  color: var(--knittable-lav-darker);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 4px 8px;
  border-radius: 6px;
  transition: all 0.2s ease;
  display: inline-block;
  width: fit-content;
}

.app-sm-and-down {
  .title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
}

.saved-status-icon {
  display: inline-block;
  height: 100%;
  font-size: 1.2rem;
  vertical-align: middle;
  color: var(--knittable-lav-medium);
}

.app-sm-and-down .pattern-util-container {
  font-size: 1.3rem;
}

.title-container {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.pattern-utils-col {
  display: flex;
  justify-content: flex-end;
}

.pattern-utils-bar {
  border-top: 3px solid var(--knittable-lav-light);
  border-left: 3px solid var(--knittable-lav-light);
  border-bottom: 3px solid var(--knittable-lav-light);
  border-right: none;
  font-size: 1.5rem;
  vertical-align: bottom;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;

  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.app-sm-and-down .pattern-utils-bar {
  padding-right: 0.8rem;
  padding-left: 0.8rem;
}

.show-settings {
  font-size: 1.1rem;
}

.app-sm-and-down .show-settings {
  font-size: 0.9rem;
}

.title-wrapper {
  border: 2px solid var(--knittable-isa);
  border-radius: 6px;
  width: fit-content;
  display: flex;
  align-items: center;
  height: 78px;
  max-width: 700px;

  input {
    max-width: 700px;
  }
}

.app-sm-and-down .title-wrapper {
  height: 56px;
  max-width: 300px;
  input {
    max-width: 300px;
  }

  &.title-underlined h3 {
    border-bottom: 2px dotted var(--knittable-lav-darker);
    cursor: pointer;
  }
}

// Separate hover effects for devices that support hover
@media (hover: hover) {
  .title-wrapper {
    &.title-hover {
      background-color: var(--knittable-lav-lighter);
      border: 2px solid var(--knittable-lav-light);
    }
  }
}

.title-display {
  cursor: pointer;
}

.title-input {
  @include heading3;
  color: var(--knittable-lav-darker);
  background: transparent;
  border: 2px solid var(--knittable-lav);
  border-radius: 6px;
  padding: 4px 8px;
  text-align: center;

  &:focus {
    outline: none;
    border-color: var(--knittable-lav-darker);
    background-color: var(--knittable-lav-lighter);
  }
}

.app-sm-and-down .title-input {
  @include heading6;
}

.edit-actions {
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: center;
  justify-content: center;
  height: 56px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease;

  .character-counter {
    font-size: 12px;
    color: var(--knittable-grey-dark);
    margin-bottom: 4px;
    min-width: 40px;
    text-align: center;
  }

  &.is-editing {
    visibility: visible;
    opacity: 1;
  }
}

.app-sm-and-down {
  .edit-actions {
    position: relative;

    .character-counter {
      position: absolute;
      bottom: -20px;
      right: 40px;
      margin-bottom: 0;
    }
  }
}

.edit-action-icon {
  cursor: pointer;
  font-size: 20px;
  padding: 4px;
  border-radius: 4px;
  transition: all 0.2s ease;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.save-icon {
    color: var(--knittable-lav);
    &:hover {
      color: var(--knittable-lav-darker);
      background: var(--knittable-lav-lighter);
    }
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &.cancel-icon {
    color: var(--knittable-grey-dark);
    &:hover {
      color: var(--knittable-grey-darker);
      background: var(--knittable-grey-lighter);
    }
  }
}

/* Hidden span for accurate width calculation */
.hidden-span {
  @include heading3;
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
}

.app-sm-and-down .hidden-span {
  @include heading6;
}

.custom-snackbar {
  --v-snackbar-color: var(--knittable-lav-darker) !important;

  .v-icon {
    color: var(--knittable-lav);
  }
}

.subscription-lock-icon {
  color: var(--knittable-lav);
  margin-right: 0.5rem;
  font-size: 1.3rem;
}

.subscription-lock-icon:hover {
  color: var(--knittable-lav-darker);
}

.app-sm-and-down .subscription-lock-icon {
  font-size: 1.1rem;
}
</style>
